import { useCallback, useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import NavBar from '../../structure/NavBar'
import { NavBarTop } from '../../structure/NavBarTop'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { Button, Dropdown, Form, InputGroup } from 'react-bootstrap'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Swal from 'sweetalert2'
import Utils from '../../constants/Utils'
import urls from '../../constants/apiurl'
import { showDangerToast, showToast } from '../../constants/toaster'
import ApiCaller from '../../constants/ApiCaller'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { Box, Switch } from '@mui/material'
import NoDataImg from '../../../assets/img/no-data.gif'
import { AddState } from './AddState'
import Pagination from '@mui/material/Pagination'
import { ImportDistrict } from './ImportDistrict'
import InsufficientPermission from '../../custom/InSufficientPermission'

const label = { inputProps: { 'aria-label': 'Size switch demo' } }

export const StateList = props => {
  const { formatDate } = Utils();
  const [countryDropdownText, setCountryDropdownText] = useState({
    id: '',
    lable: 'Select Country'
  })
  const [countryCodeList, setCountryCodeList] = useState([
    {
      id: '',
      name: 'Select Country'
    }
  ])
  const dispatch = useDispatch()
  const userData = useSelector(state => state?.userData)
  const [stateFormData, setStateFormData] = useState({
    name: '',
    parentId: ''
  })
  const [showAddState, setShowAddState] = useState(false)
  const [showAddImportDistrict, setShowAddImportDistrict] = useState(false)
  const [selectedStateId, setSelectedStateId] = useState('')
  const [sheetIndex, setSheetIndex] = useState('')
  const [searchText, setSearchText] = useState('')
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [callAPi, setCallAPi] = useState('')
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const [datalist, setDatalist] = useState([])
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
    setCallAPi(uuidv4())
  }
  const [rolePermissionData, setRolePermissionData] = useState({})
  const [subAdminListByRole, setsubAdminListByRole] = useState({})
  const [viewPermissionControl, setViewPermissionControl] = useState(false)

  useEffect(() => {
    dispatch(updateTopHeader('State Manager'))
  }, [])

  useEffect(() => {
    if(viewPermission){
      getStateList()
      getCountryCodeList()
    }
  }, [callAPi])

  const handleAddState = () => {
    setStateFormData({
      id: '',
      name: '',
      parentId: ''
    })
    setShowAddState(true)
  }

  const handleFilterClick = useCallback(() => {
    setPage(1)
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  }, [searchText, startDate, endDate, statusDropdownText, countryDropdownText])

  const handleResetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setStatusDropdownText('Select Status')
    setSearchText('')
    setCountryDropdownText({
      id: '',
      lable: 'Select Country'
    })
    setPage(1)
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  }

  const handleFileChange = e => {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.onload = event => {
      const binaryStr = event.target.result
      const workbook = XLSX.read(binaryStr, { type: 'binary' })

      // Assuming you want to read the first sheet
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]

      // Convert to JSON
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      let allCountry = []
      jsonData?.map(data => {
        let singleCountry = {
          parentId: 'bbca05da-c63d-461d-8a78-06c57d9cfbda',
          name: data[0]
        }
        allCountry.push(singleCountry)
      })
      setData(allCountry)
    }

    reader.readAsBinaryString(file)
  }

  const handleImportState = async () => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        dataList: data
      }
      let path = urls.importState
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      console.log('res', response)
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getStateList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const handleImportDistrict = async districtData => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        dataList: data
      }
      let path = urls.importState
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      console.log('res', response)
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getStateList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const getGetRolePermssions = async id => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.fetchPermission + '/' + id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setRolePermissionData(response?.data)
        setViewPermissionControl(true)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const getCountryCodeList = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.countryCodeList
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempArray = []
        tempArray.push(...response?.data)
        setCountryCodeList(tempArray)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeStateStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getStateList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleOnSubmitStateModel = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.addState
      let res = await ApiCaller.post(
        stateFormData,
        path,
        userData?.access_token
      )
      const response = await res.response.json()
      console.log('res', response)
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getStateList()
        setShowAddState(false)
        setStateFormData({
          name: '',
          parentId: ''
        })
        return true
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
        return false
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
      return false
    }
  }

  const getStateList = async () => {
    let startDateObject
    let endDateObject
    if (startDate?.toDate()) {
      let newDate = new Date(startDate?.toDate())
      // newDate.setDate(newDate.getDate() + 1)
      startDateObject = newDate
    }
    if (endDate?.toDate()) {
      let newDate = new Date(endDate?.toDate())
      // newDate.setDate(newDate.getDate() + 1)
      endDateObject = newDate
    }
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchText?.trim(),
        pageNumber: pagerecord?.pageNumber - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        countryId: countryDropdownText?.id,
        active:
          statusDropdownText === 'Active'
            ? true
            : statusDropdownText === 'In Active'
            ? false
            : null,
        startDate: startDateObject !== null ? startDateObject : '',
        endDate: endDateObject !== null ? endDateObject : ''
      }
      let path = urls.stateList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.items)
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pagerecord?.pageNumber,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleEditState = item => {
    setStateFormData({
      id: item?.id,
      name: item?.name,
      parentId: item?.countryId
    })
    setShowAddState(true)
  }

  const handleDeleteState = item => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then(async result => {
      if (result.isConfirmed) {
        deleteApiCall(item?.id)
      }
    })
  }

  const userPermisiions = useSelector(state => state?.permission)
  let currentModulePermission;
  let viewPermission = true;
  let editPermission = true;
  let deletePermissionVal = true;
  let activePermission = true;

  if (userPermisiions?.modules.length > 0) {
    currentModulePermission = userPermisiions.modules.find((element) => {
      if (element.moduleId === '16') {
        return element
      }
    })?.permissions
    viewPermission = currentModulePermission.find((element) => {
      if (element.name === 'VIEW') {
        return element
      }
    }).isEnable
    editPermission = currentModulePermission.find((element) => {
      if (element.name === 'EDIT') {
        return element
      }
    }).isEnable
    deletePermissionVal = currentModulePermission.find((element) => {
      if (element.name === 'DELETE') {
        return element
      }
    }).isEnable
    activePermission = currentModulePermission.find((element) => {
      if (element.name === 'ACTIVE') {
        return element
      }
    }).isEnable
  }

  const deleteApiCall = async id => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.deleteState + '/' + id
      let res = await ApiCaller.delete({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        Swal.fire('Deleted!', response?.message, 'success')
        getStateList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div className='header'>
          <div className='logo'>
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
          </div>
        </div>
      {
        viewPermission ? (
          <>
            <div className='container-fluid mt--7'>
          <div className='row'>
            <div className='col text-left'>
              <div className='card shadow'>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col'>
                      <h3 className='mb-0'>State List</h3>
                    </div>
                    <div className='col text-right'>
                      {userData.admin ? (
                        <a
                          onClick={handleAddState}
                          className='btn btn-sm btn-primary text-white'
                        >
                          <AddIcon />
                          Add New
                        </a>
                      ) : editPermission ? (
                        <a
                          onClick={handleAddState}
                          className='btn btn-sm btn-primary text-white'
                        >
                          <AddIcon />
                          Add New
                        </a>
                      ) : (
                        <></>
                      )}

                      {/* <a href='#!' className='btn btn-sm btn-primary'>
                        See all
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col-md-3'>
                      <InputGroup
                        className='mb-2'
                        style={{ borderRadius: '10px' }}
                      >
                        <Form.Control
                          placeholder='Search by State Name'
                          value={searchText}
                          onChange={e => setSearchText(e.target.value)}
                          aria-label='Amount (to the nearest dollar)'
                        />
                      </InputGroup>
                    </div>
                    <div className='col-md-2'>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='success'
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            border: '1px solid #cad1d7',
                            boxShadow: 'none',
                            transform: 'none'
                          }}
                          id='dropdown-basic'
                        >
                          {countryDropdownText?.lable}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {countryCodeList?.map((item, i) => {
                            return (
                              <>
                                <Dropdown.Item
                                  eventKey={item?.id}
                                  onClick={() => {
                                    setCountryDropdownText({
                                      id: item?.id,
                                      lable: item?.name
                                    })
                                  }}
                                >
                                  {item?.name}
                                </Dropdown.Item>
                              </>
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className='col-md-2'>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='success'
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            border: '1px solid #cad1d7',
                            boxShadow: 'none',
                            transform: 'none'
                          }}
                          id='dropdown-basic'
                        >
                          {statusDropdownText}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              setStatusDropdownText('Select Status')
                            }
                          >
                            Select Status
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setStatusDropdownText('Active')}
                          >
                            Active
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setStatusDropdownText('In Active')}
                          >
                            In Active
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className='col-md-6 mb-3'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={['DatePicker', 'DatePicker']}
                        >
                          <DatePicker
                            disableFuture
                            className='custom-date'
                            label='From Date'
                            value={startDate}
                            onChange={newValue => setStartDate(newValue)}
                            maxDate={endDate}
                          />
                          <DatePicker
                            disableFuture
                            label='To Date'
                            value={endDate}
                            onChange={newValue => setEndDate(newValue)}
                            minDate={startDate}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className='col-md-3 mb-2'>
                      <Button
                        variant='contained'
                        className='custom-filter-btn'
                        onClick={handleFilterClick}
                      >
                        Filter
                      </Button>
                      <Button
                        variant='contained'
                        className='ml-2 custom-filter-btn'
                        onClick={handleResetFilter}
                      >
                        Reset
                      </Button>
                      {/* <input type='file' onChange={handleFileChange} /> */}
                      {/* <Button
                        variant='contained'
                        className='ml-2 custom-filter-btn'
                        onClick={handleImportState}
                      >
                        Import Excel
                      </Button> */}
                    </div>
                  </div>
                </div>
                <div className='table-responsive'>
                  {datalist && datalist?.length > 0 ? (
                    <>
                      <table className='table align-items-center table-flush'>
                        <thead className='thead-light'>
                          <tr>
                            <th scope='col'>S/No.</th>
                            <th scope='col'>Country Name</th>
                            <th scope='col'>State</th>
                            <th scope='col'>Created At</th>
                            <th scope='col'>Status</th>
                         {
                          (editPermission || deletePermissionVal) && 
                          <th scope='col'>Action</th>
                         }
                          </tr>
                        </thead>
                        <tbody>
                          {datalist &&
                            datalist?.map((data, i) => {
                              const startIndex =
                                (pagerecord.pageNumber - 1) *
                                pagerecord.pageSize
                              return (
                                <>
                                  <tr>
                                    <td>{startIndex + i + 1}</td>
                                    <td>{data?.countryName}</td>
                                    <td>{data?.name}</td>
                                    <td>{formatDate(data?.createdAt)}</td>
                                    <td>
                                      {userData.admin ? (
                                        <Switch
                                          {...label}
                                          checked={data?.active}
                                          onChange={e =>
                                            handleStatusChange(e, data?.id)
                                          }
                                          size='small'
                                        />
                                      ) : viewPermission ? (
                                        <Switch
                                          {...label}
                                          checked={data?.active}
                                          disabled={!activePermission}
                                          onChange={e =>
                                            handleStatusChange(e, data?.id)
                                          }
                                          size='small'
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    {
                                      (editPermission || deletePermissionVal) && 
                                      <td className='text-center'>
                                      <div className='dropdown'>
                                        <a
                                          className='btn btn-sm btn-icon-only text-light'
                                          href='#'
                                          role='button'
                                          data-toggle='dropdown'
                                          aria-haspopup='true'
                                          aria-expanded='false'
                                        >
                                          <i className='fas fa-ellipsis-v' />
                                        </a>
                                        <div
                                          className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                          x-placement='bottom-end'
                                          style={{
                                            position: 'absolute',
                                            willChange: 'transform',
                                            top: '0px',
                                            left: '0px',
                                            transform:
                                              'translate3d(32px, 32px, 0px)'
                                          }}
                                        >
                                          {/* {userData.admin ? (
                                            <a
                                              className='dropdown-item cursor'
                                              onClick={() => {
                                                setShowAddImportDistrict(
                                                  !showAddImportDistrict
                                                )
                                                setSelectedStateId(data?.id)
                                                setSheetIndex(i)
                                              }}
                                            >
                                              Import Districts
                                            </a>
                                          ) : active ? (
                                            <a
                                              className='dropdown-item cursor'
                                              onClick={() => {
                                                setShowAddImportDistrict(
                                                  !showAddImportDistrict
                                                )
                                                setSelectedStateId(data?.id)
                                                setSheetIndex(i)
                                              }}
                                            >
                                              Import Districts
                                            </a>
                                          ) : (
                                            <></>
                                          )} */}
                                          {userData.admin ? (
                                            <a
                                              className='dropdown-item cursor'
                                              onClick={() =>
                                                handleEditState(data)
                                              }
                                            >
                                              Edit
                                            </a>
                                          ) : editPermission ? (
                                            <a
                                              className='dropdown-item cursor'
                                              onClick={() =>
                                                handleEditState(data)
                                              }
                                            >
                                              Edit
                                            </a>
                                          ) : (
                                            <></>
                                          )}
                                          {userData.admin ? (
                                            <a
                                              className='dropdown-item cursor'
                                              onClick={() =>
                                                handleDeleteState(data)
                                              }
                                            >
                                              Delete
                                            </a>
                                          ) : deletePermissionVal ? (
                                            <a
                                              className='dropdown-item cursor'
                                              onClick={() =>
                                                handleDeleteState(data)
                                              }
                                            >
                                              Delete
                                            </a>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    }
                                  </tr>
                                </>
                              )
                            })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <>
                      <div className='text-center'>
                        <img width={'25%'} src={NoDataImg}></img>
                        <br />
                        <label style={{ fontWeight: 'bold' }}>
                          {LOADING_ENABLE ? (
                            <>Loading Data.....</>
                          ) : (
                            <>No Data Found</>
                          )}
                        </label>
                      </div>
                    </>
                  )}
                </div>
                {datalist && datalist.length > 0 ? (
                  <div className='card-footer py-4'>
                    <Box
                      display='flex'
                      justifyContent='right'
                      alignItems='center'
                    >
                      <span>Total record: {pagerecord?.totalItems}</span>
                      <Pagination
                        className='custom-pagination-class'
                        count={pagerecord?.totalPages}
                        page={page}
                        onChange={handleChange}
                        color='secondary'
                      />
                    </Box>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <AddState
          show={showAddState}
          countryCodeList={countryCodeList}
          stateFormData={stateFormData}
          rolePermissionData={rolePermissionData}
          subAdminListByRole={subAdminListByRole}
          setStateFormData={setStateFormData}
          onSubmit={() => handleOnSubmitStateModel()}
          onHide={() => setShowAddState(false)}
        />
        <ImportDistrict
          show={showAddImportDistrict}
          sheetIndex={sheetIndex}
          stateId={selectedStateId}
          onHide={() => setShowAddImportDistrict(false)}
        />
          </>
        ) : (<InsufficientPermission/>)
      }
      </div>
    </>
  )
}
