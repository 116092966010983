import NavBar from "../../structure/NavBar";
import { NavBarTop } from "../../structure/NavBarTop";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import NoDataImg from "../../../assets/img/no-data.gif";
import AddIcon from "@mui/icons-material/Add";
import {
  updateTopHeader,
  uplodateLoading,
} from "../../../redux/features/common/commonSlice";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { v4 as uuidv4 } from 'uuid';
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import SearchIcon from "@mui/icons-material/Search";
import InputGroup from "react-bootstrap/InputGroup";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Switch from "@mui/material/Switch";
import { AddSubAdmin } from "./AddSubAdmin";
import { SubAdminLogs } from "./SubAdminLogs";
import urls from "../../constants/apiurl";
import ApiCaller from "../../constants/ApiCaller";
import { showDangerToast, showToast } from "../../constants/toaster";
import Utils from "../../constants/Utils";
import InsufficientPermission from "../../custom/InSufficientPermission";

const label = { inputProps: { "aria-label": "Size switch demo" } };

const initialState = {
  id: "",
  email: "",
  contactNumber: "",
  firstName: "",
  lastName: "",
  roleId: "",
  countryId: ""
};

function subAdminFormDataReducer(state, action) {
  switch (action.type) {
    case "RESET":
      return initialState;
    default:
      return { ...state, ...action.payload };
  }
}

export const SubAdminList = (props) => {
  const { formatDate } = Utils();
  const [subAdminFormData, setSubAdminFormData] = useReducer(
    subAdminFormDataReducer,
    initialState
  );
  const [edit, setEdit] = useState(false);
  const [active, setActive] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [callAPi, setCallAPi] = useState('')
  const [view, setView] = useState(false);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const userData = useSelector((state) => state?.userData);
  const [defaultRoleOption, setDefaultRoleOption] = useState(null);
  const [roleKeyValueData, setRoleKeyValueData] = useState([]);
  const [countryId, setCountryId] = useState('');
  const [datalist, setDatalist] = useState([]);
  const [showAddSubAdmin, setShowSubAdmin] = useState(false);
  const [showSubAdminLog, setShowSubAdminLog] = useState(false);
  const [viewSubAdminLogId, setViewSubAdminLogId] = useState('');
  const [sort_type, setSortType] = useState("createdAt");
  const [searchText, setSearchText] = useState("");
  const [sort_order, setSortOrder] = useState(false);
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
  });
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10,
    });
    setCallAPi(uuidv4())
  };


  const userPermisiions = useSelector(state => state?.permission)
  let currentModulePermission;
  let viewPermission = true;
  let editPermission = true;
  let deletePermissionVal = true;
  let activePermission = true;

  if (userPermisiions?.modules.length > 0) {
    currentModulePermission = userPermisiions.modules.find((element) => {
      if (element.moduleId === '6') {
        return element
      }
    })?.permissions
    viewPermission = currentModulePermission.find((element) => {
      if (element.name === 'VIEW') {
        return element
      }
    }).isEnable
    editPermission = currentModulePermission.find((element) => {
      if (element.name === 'EDIT') {
        return element
      }
    }).isEnable
    deletePermissionVal = currentModulePermission.find((element) => {
      if (element.name === 'DELETE') {
        return element
      }
    }).isEnable
    activePermission = currentModulePermission.find((element) => {
      if (element.name === 'ACTIVE') {
        return element
      }
    }).isEnable
  }
  //  console.log(currentModulePermission,'userPermisiions,rolelist,user',editPermission)

  const resetFormData = () => {
    setSubAdminFormData({ type: "RESET" });
    setDefaultRoleOption(null);
  };


  const handleFilterClick = useCallback(() => {
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  }, [searchText, startDate, endDate]);

  const handleResetFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchText("");
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  };

  useEffect(() => {
    dispatch(updateTopHeader("Sub Admin Manager"));
  }, []);

  useEffect(() => {
    if (viewPermission) {
      getSubAdminList();
    }
  }, [callAPi]);

  const getSubAdminList = async () => {
    try {
      dispatch(uplodateLoading(true));
      let startDateObject;
      let endDateObject;
      if (startDate?.toDate()) {
        let newDate = new Date(startDate?.toDate());
        newDate.setDate(newDate.getDate() + 1);
        startDateObject = newDate;
      }
      if (endDate?.toDate()) {
        let newDate = new Date(endDate?.toDate());
        newDate.setDate(newDate.getDate() + 1);
        endDateObject = newDate;
      }
      let postJson = {
        searchText: searchText?.trim(),
        pageNumber: pagerecord?.page - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        startDate:
          startDate !== null
            ? startDate?.toDate()
            : '',
        endDate:
          endDate !== null
            ? endDate?.toDate()
            : '',
      };
      let path = urls.subAdminList;
      let res = await ApiCaller.post(postJson, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        setDatalist(response?.data?.response?.items);
        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === "EDIT") {
            setEdit(module.isEnable);
          } else if (module.permissionName === "ACTIVE") {
            setActive(module.isEnable);
          } else if (module.permissionName === "DELETE") {
            setDeletePermission(module.isEnable);
          } else if (module.permissionName === "VIEW") {
            setView(module.isEnable);
          }
        });

        setPagerecord({
          totalItems: response?.data?.response?.totalItems,
          totalPages: response?.data?.response?.totalPages,
          pageNumber: pagerecord?.pageNumber,
          pageSize: 10,
        });

      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const getRoleKeyValuePair = async (roleId) => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.roleKeyValuePair;
      let res = await ApiCaller.get({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        let tempRoleKeyValueList = [{ value: "", label: "Select Role" }];
        response?.data?.map((data, i) => {
          tempRoleKeyValueList.push({ value: data?.key, label: data?.value });
          if (data?.key === roleId) {
            setDefaultRoleOption({ value: data?.key, label: data?.value });
          }
        });
        setRoleKeyValueData(tempRoleKeyValueList);
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.subAdminChangeStatus + "/" + id;
      let res = await ApiCaller.put({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        showToast(response?.message);
        getSubAdminList("", startDate, endDate);
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const handleOnSubmitSubAdminModel = async () => {
    console.log(countryId)
    try {
      dispatch(uplodateLoading(true));
      let postJson = {
        id: subAdminFormData?.id,
        email: subAdminFormData?.email,
        contactNumber: subAdminFormData?.contactNumber,
        firstName: subAdminFormData?.firstName,
        lastName: subAdminFormData?.lastName,
        roleId: subAdminFormData?.roleId,
        countryId: countryId
      };
      let path = urls.subAdminAdd;
      let res = await ApiCaller.post(postJson, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        showToast(response?.message);
        getSubAdminList("", startDate, endDate);
        setShowSubAdmin(false);
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const handleAddSubAdmin = () => {
    resetFormData();
    getRoleKeyValuePair();
    setShowSubAdmin(true);
  };
  const handleViewLog = (id) => {
    setShowSubAdminLog(true);
    setViewSubAdminLogId(id)
  };

  const handleEditSubAdmin = (item) => {
    setSubAdminFormData({ type: "UPDATE", payload: item });
    getRoleKeyValuePair(item?.roleId);
    setShowSubAdmin(true);
    console.log(defaultRoleOption);
  };

  const handleDeleteSubAdmin = async (data) => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.subAdminDelete + data.id;
      let res = await ApiCaller.delete({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        showToast(response?.message);
        getSubAdminList("", startDate, endDate);
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  return (
    <>
      <NavBar />
      <div className="main-content">
        <NavBarTop />
        <div className="header">
          <div className="logo">
            <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8"></div>
          </div>
        </div>
        {
          viewPermission ? (
            <>
              <div className="container-fluid mt--7">
                <div className="row">
                  <div className="col text-left">
                    <div className="card shadow">
                      <div className="card-header border-0">
                        <div className="row align-items-center text-left">
                          <div className="col">
                            <h3 className="mb-0">Sub Admin List</h3>
                          </div>
                          <div className="col text-right">
                            {userData.admin ? (
                              <a
                                onClick={handleAddSubAdmin}
                                className="btn btn-sm btn-primary text-white"
                              >
                                <AddIcon />
                                Add New
                              </a>
                            ) : editPermission ? (
                              <a
                                onClick={handleAddSubAdmin}
                                className="btn btn-sm btn-primary text-white"
                              >
                                <AddIcon />
                                Add New
                              </a>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card-header border-0">
                        <div className="row align-items-center text-left">
                          <div className="col-md-3">
                            <InputGroup
                              className="mb-2"
                              style={{ borderRadius: "10px" }}
                            >
                              <Form.Control
                                placeholder="Search by name of Sub-Admin"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                              />
                            </InputGroup>
                          </div>
                          <div
                            className="col-md-6 mb-3"
                            style={{ marginLeft: "10px" }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker", "DatePicker"]}
                              >
                                <DatePicker
                                  disableFuture
                                  className="custom-date"
                                  label="From Date"
                                  value={startDate}
                                  onChange={(newValue) => setStartDate(newValue)}
                                  maxDate={endDate}
                                />
                                <DatePicker
                                  disableFuture
                                  label="To Date"
                                  value={endDate}
                                  onChange={(newValue) => setEndDate(newValue)}
                                  minDate={startDate}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                          <div className="col-md-3 mb-2">
                            <Button
                              variant="contained"
                              className="custom-filter-btn"
                              onClick={handleFilterClick}
                            >
                              Filter
                            </Button>
                            <Button
                              variant="contained"
                              className="ml-2 custom-filter-btn"
                              onClick={handleResetFilter}
                            >
                              Reset
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        {datalist && datalist.length > 0 ? (
                          <>
                            <table className="table align-items-center table-flush" style={datalist?.length === 1 ? { minHeight: '140px' } : {}}>
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">S/No.</th>
                                  <th scope="col">Name</th>
                                  <th scope="col">Email</th>
                                  <th scope="col">Mobile No.</th>
                                  <th scope="col">Created At</th>
                                  <th scope="col">Status</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {datalist &&
                                  datalist?.map((data, i) => {
                                    const startIndex = (pagerecord.pageNumber - 1) * pagerecord.pageSize;
                                    return (
                                      <tr key={i}>
                                        <td>{startIndex + i + 1}</td>
                                        <td>
                                          {data?.firstName + " " + data?.lastName}
                                        </td>
                                        <td>{data?.email}</td>
                                        <td>{data?.extensionNumber} {data?.contactNumber}</td>
                                        <td>{formatDate(data?.createdAt)}</td>
                                        <td>
                                          {userData.admin ? (
                                            <Switch
                                              id={"test"}
                                              {...label}
                                              checked={data?.active}
                                              onChange={(e) =>
                                                handleStatusChange(e, data?.id)
                                              }
                                              size="small"
                                            />
                                          ) : viewPermission ? (
                                            <Switch
                                              id={"test"}
                                              {...label}
                                              checked={data?.active}
                                              disabled={!activePermission}
                                              onChange={(e) => {
                                                if (activePermission) {
                                                  handleStatusChange(e, data?.id)
                                                }
                                              }
                                              }
                                              size="small"
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                        <td className="text-right">
                                          <div className="dropdown">
                                            <a
                                              className="btn btn-sm btn-icon-only text-light"
                                              href="#"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <i className="fas fa-ellipsis-v" />
                                            </a>
                                            <div
                                              className="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                                              x-placement="bottom-end"
                                              style={{
                                                position: "absolute",
                                                willChange: "transform",
                                                top: "0px",
                                                left: "0px",
                                                transform:
                                                  "translate3d(32px, 32px, 0px)",
                                              }}
                                            >
                                              {userData.admin ? (
                                                <a
                                                  className="dropdown-item cursor"
                                                  onClick={() =>
                                                    handleEditSubAdmin(data)
                                                  }
                                                >
                                                  Edit
                                                </a>
                                              ) : editPermission ? (
                                                <a
                                                  className="dropdown-item cursor"
                                                  onClick={() =>
                                                    handleEditSubAdmin(data)
                                                  }
                                                >
                                                  Edit
                                                </a>
                                              ) : (
                                                <></>
                                              )}

                                              <a
                                                className="dropdown-item cursor"
                                                onClick={() => handleViewLog(data?.id)}
                                              >
                                                View Log
                                              </a>
                                              {
                                                deletePermissionVal &&
                                                <a
                                                  className="dropdown-item cursor"
                                                  onClick={() =>
                                                    handleDeleteSubAdmin(data)
                                                  }
                                                >
                                                  Delete
                                                </a>
                                              }
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </>
                        ) : (
                          <>
                            <div className="text-center">
                              <img width={"25%"} src={NoDataImg}></img>
                              <br />
                              <label style={{ fontWeight: "bold" }}>
                                No Data Found
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                      {datalist && datalist.length > 0 ? (
                        <div className="card-footer py-4">
                          <Box display="flex" justifyContent="right" alignItems='center'>
                            <span>Total record: {pagerecord?.totalItems}</span>
                            <Pagination
                              className="custom-pagination-class"
                              count={pagerecord?.totalPages}
                              page={page}
                              onChange={handleChange}
                              color="secondary"
                            />
                          </Box>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <AddSubAdmin
                show={showAddSubAdmin}
                subAdminFormData={subAdminFormData}
                roleKeyValueData={roleKeyValueData}
                countryId={countryId}
                setCountryId={setCountryId}
                defaultRoleOption={defaultRoleOption}
                setSubAdminFormData={setSubAdminFormData}
                onSubmit={() => handleOnSubmitSubAdminModel()}
                onHide={() => {
                  setShowSubAdmin(false);
                }}
              />
              {showSubAdminLog ? <SubAdminLogs
                show={showSubAdminLog}
                id={viewSubAdminLogId}
                onSubmit={() => handleOnSubmitSubAdminModel()}
                onHide={() => setShowSubAdminLog(false)}
              /> : <></>}
            </>
          ) : (<InsufficientPermission />)
        }

      </div>
    </>
  );
};
