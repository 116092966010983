import Switch from '@mui/material/Switch'
import dayjs, { Dayjs } from 'dayjs'
import NoDataImg from '../../../assets/img/no-data.gif'
import { useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { updateTopHeader, uplodateLoading } from '../../../redux/features/common/commonSlice'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import { Link } from '@mui/material'
import Utils from '../../constants/Utils'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const VideoAbuseTable = ({ datalist, setShowAbuseReportDetails, pagerecord, setCallAPi, viewPermission = true, editPermission = true, activePermission = true, deletePermissionVal = true }) => {
  const dispatch = useDispatch()
  const userData = useSelector((state) => state?.userData);
  const { formatDate } = Utils();
  useEffect(() => {
    console.log(datalist)
  }, [])

  const handleStatusChange = async val => {
    try {
      // dispatch(uplodateLoading(true))
      let path = urls.activeInActiveReport + '?video=' + true + "&id=" + val?.typeId
      console.log(path)
      let res = await ApiCaller.get(
        {},
        path,
        userData?.access_token
      )
      const response = await res.response.json()
      if (response.status == 200) {
        // dispatch(uplodateLoading(false))
        showToast(response?.message)
        setCallAPi(uuidv4())
      } else {
        // dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      // dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  useEffect(() => {
    dispatch(updateTopHeader('Report & Abuse Manager'))
  })

  return (
    <div className='table-responsive'>
      {datalist && datalist?.length > 0 ? <>
        <table className='table align-items-center table-flush'>
          <thead className='thead-light'>
            <tr>
              <th scope='col'>S/No.</th>
              <th scope='col'>UserID</th>
              <th scope='col'>User Name</th>
              <th scope='col'>Video Link</th>
              <th scope='col'>Number of Reports</th>
              <th scope='col'>Report At</th>
              <th scope='col'>Status</th>
              <th scope='col'>Action</th>
            </tr>
          </thead>
          <tbody>
            {datalist.map((val, ind) => {
              const startIndex = (pagerecord.pageNumber - 1) * pagerecord.pageSize;
              return (<tr>
                <td>{startIndex + ind + 1}</td>
                <td>{val?.userId}</td>
                {/* <td>{val?.userId ? val?.userId.toString().padStart(8, '0') : ''}</td> */}
                <td>{val?.userName}</td>
                <td><Link href={val?.link} target="_blank">View Video</Link></td>
                <td>{val?.reportCount}</td>
                <td>{formatDate(val?.updatedAt)}</td>
                <td>
                  <Switch
                    id={"test"}
                    {...label}
                    checked={val?.active}
                    disabled={!activePermission}
                    onChange={(e) => {
                      if (activePermission) {
                        handleStatusChange(val)
                      }
                    }
                    }
                    size="small"
                  />
                </td>
                <td>
                  <div className='dropdown'>
                    <a
                      className='btn btn-sm btn-icon-only text-light'
                      href='#'
                      role='button'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <i className='fas fa-ellipsis-v' />
                    </a>
                    <div
                      className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                      x-placement='bottom-end'
                      style={{
                        position: 'absolute',
                        willChange: 'transform',
                        top: '0px',
                        left: '0px',
                        transform: 'translate3d(32px, 32px, 0px)'
                      }}
                    >
                      <a
                        className='dropdown-item cursor'
                        onClick={() => setShowAbuseReportDetails(val)}
                      >
                        View Reports
                      </a>
                    </div>
                  </div>
                </td>
              </tr>)
            })
            }
          </tbody>
        </table >
      </> : <>
        <div className='text-center'>
          <img width={'25%'} src={NoDataImg}></img>
          <br />
          <label style={{ fontWeight: 'bold' }}>

            <>No Data Found</>
          </label>
        </div>
      </>}
    </div >
  )
}
