import { useCallback, useEffect, useReducer, useState } from 'react'
import * as XLSX from 'xlsx'
import Dropdown from 'react-bootstrap/Dropdown'
import NoDataImg from '../../../assets/img/no-data.gif'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import { v4 as uuidv4 } from 'uuid'
import Box from '@mui/material/Box'
import dayjs, { Dayjs } from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import InputGroup from 'react-bootstrap/InputGroup'
import Pagination from '@mui/material/Pagination'
import NavBar from '../../structure/NavBar'
import { NavBarTop } from '../../structure/NavBarTop'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import AddIcon from '@mui/icons-material/Add'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import Switch from '@mui/material/Switch'
import { AddCountry } from './AddCountry'
import { data } from 'jquery'
import Utils from '../../constants/Utils'
import { CountryAdsConfiguration } from './CountryAdsConfiguration'
import InsufficientPermission from '../../custom/InSufficientPermission'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const CountryList = props => {
  const { formatDate } = Utils();
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [value, setValue] = useState(dayjs())
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const userData = useSelector(state => state?.userData)
  const [searchText, setSearchText] = useState('')
  const [showAddCountry, setShowAddCountry] = useState(false)
  const [showGlobalConfigModal, setShowGlobalConfigModal] = useState(false)
  const [countryId, setCountryId] = useState('')
  const [datalist, setDatalist] = useState([])
  const [viewPermissionControl, setViewPermissionControl] = useState(false)
  const [viewSubAdminList, setViewSubAdminList] = useState(false)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [countryFormData, setCountryFormData] = useState({
    id: '',
    name: '',
    code: '',
    mobileLength: '',
    isnCode: ''
  })
  const [callAPi, setCallAPi] = useState('')
  const [data, setData] = useState([])
  const [permissionSubmitData, setPermissionSubmitData] = useState([])
  const [rolePermissionData, setRolePermissionData] = useState({})
  const [subAdminListByRole, setSubAdminListByRole] = useState([])
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
    setCallAPi(uuidv4())
  }

  const handleFilterClick = useCallback(() => {
    setPage(1)
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  }, [searchText, statusDropdownText, startDate, endDate])

  const handleResetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setStatusDropdownText('Select Status')
    setSearchText('')
    setPage(1)
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  }
  useEffect(() => {
    dispatch(updateTopHeader('Country Manager'))
  }, [])

  useEffect(() => {
    if(viewPermission){
      getCountryList()
    }
  }, [callAPi])

  const getGetRolePermssions = async id => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.fetchPermission + '/' + id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setRolePermissionData(response?.data)
        setViewPermissionControl(true)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeCountryStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getCountryList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const getCountryList = async () => {
    let startDateObject
    let endDateObject
    if (startDate?.toDate()) {
      let newDate = new Date(startDate?.toDate())
      newDate.setDate(newDate.getDate() + 1)
      startDateObject = newDate
    }
    if (endDate?.toDate()) {
      let newDate = new Date(endDate?.toDate())
      newDate.setDate(newDate.getDate() + 1)
      endDateObject = newDate
    }
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchText?.trim(),
        pageNumber: pagerecord?.pageNumber - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        active:
          statusDropdownText === 'Active'
            ? true
            : statusDropdownText === 'In Active'
              ? false
              : null,
        startDate: startDateObject !== null ? startDateObject : '',
        endDate: endDateObject !== null ? endDateObject : ''
      }
      let path = urls.countryList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.items)
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pagerecord?.pageNumber,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleEditCountry = item => {
    setCountryFormData(item)
    setShowAddCountry(true)
  }

  const handleAddCountry = () => {
    setCountryFormData({
      name: '',
      code: ''
    })
    setShowAddCountry(true)
  }

  const handleOnSubmitCountryModel = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.addCountry
      let res = await ApiCaller.post(
        countryFormData,
        path,
        userData?.access_token
      )
      const response = await res.response.json()
      console.log('res', response)
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getCountryList()
        setShowAddCountry(false)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const submitPermissionControl = async () => {
    try {
      dispatch(uplodateLoading(true))
      let postData = {
        permissions: permissionSubmitData
      }
      let path = urls.rolePermissionUpdate
      let res = await ApiCaller.post(postData, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        setViewPermissionControl(false)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleImportCountries = async () => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        dataList: data
      }
      let path = urls.importCountry
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      console.log('res', response)
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getCountryList()
        setShowAddCountry(false)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const userPermisiions = useSelector(state => state?.permission)
  let currentModulePermission;
  let viewPermission = true;
  let editPermission = true;
  let deletePermissionVal = true;
  let activePermission = true;

  if (userPermisiions?.modules.length > 0) {
    currentModulePermission = userPermisiions.modules.find((element) => {
      if (element.moduleId === '15') {
        return element
      }
    })?.permissions
    viewPermission = currentModulePermission.find((element) => {
      if (element.name === 'VIEW') {
        return element
      }
    }).isEnable
    editPermission = currentModulePermission.find((element) => {
      if (element.name === 'EDIT') {
        return element
      }
    }).isEnable
    deletePermissionVal = currentModulePermission.find((element) => {
      if (element.name === 'DELETE') {
        return element
      }
    }).isEnable
    activePermission = currentModulePermission.find((element) => {
      if (element.name === 'ACTIVE') {
        return element
      }
    }).isEnable
  }

  const handleFileChange = e => {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.onload = event => {
      const binaryStr = event.target.result
      const workbook = XLSX.read(binaryStr, { type: 'binary' })

      // Assuming you want to read the first sheet
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]

      // Convert to JSON
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      let allCountry = []
      jsonData?.map(data => {
        let singleCountry = {
          id: '',
          name: data[0],
          code: '+' + data[2],
          mobileLength: '10',
          isnCode: data[1]
        }
        allCountry.push(singleCountry)
      })
      setData(allCountry)
    }

    reader.readAsBinaryString(file)
  }

  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div className='header'>
          <div className='logo'>
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
          </div>
        </div>
     {
      viewPermission ? (
        <>
           <div className='container-fluid mt--7'>
          <div className='row'>
            <div className='col text-left'>
              <div className='card shadow'>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col'>
                      <h3 className='mb-0'>Country List</h3>
                    </div>
                    <div className='col text-right'>
                      {userData.admin ? (
                        <a
                          onClick={handleAddCountry}
                          className='btn btn-sm btn-primary text-white'
                        >
                          <AddIcon />
                          Add New
                        </a>
                      ) : editPermission ? (
                        <a
                          onClick={handleAddCountry}
                          className='btn btn-sm btn-primary text-white'
                        >
                          <AddIcon />
                          Add New
                        </a>
                      ) : (
                        <></>
                      )}

                      {/* <a href='#!' className='btn btn-sm btn-primary'>
                        See all
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col-md-3'>
                      <InputGroup
                        className='mb-2'
                        style={{ borderRadius: '10px' }}
                      >
                        <Form.Control
                          placeholder='Search by Country Name'
                          value={searchText}
                          onChange={e => setSearchText(e.target.value)}
                          aria-label='Amount (to the nearest dollar)'
                        />
                      </InputGroup>
                    </div>
                    <div className='col-md-2'>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='success'
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            border: '1px solid #cad1d7',
                            boxShadow: 'none',
                            transform: 'none'
                          }}
                          id='dropdown-basic'
                        >
                          {statusDropdownText}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              setStatusDropdownText('Select Status')
                            }
                          >
                            Select Status
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setStatusDropdownText('Active')}
                          >
                            Active
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setStatusDropdownText('In Active')}
                          >
                            In Active
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className='col-md-6 mb-3'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={['DatePicker', 'DatePicker']}
                        >
                          <DatePicker
                            disableFuture
                            className='custom-date'
                            label='From Date'
                            value={startDate}
                            onChange={newValue => setStartDate(newValue)}
                            maxDate={endDate}
                          />
                          <DatePicker
                            disableFuture
                            label='To Date'
                            value={endDate}
                            onChange={newValue => setEndDate(newValue)}
                            minDate={startDate}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className='col-md-3 mb-2'>
                      <Button
                        variant='contained'
                        className='custom-filter-btn'
                        onClick={handleFilterClick}
                      >
                        Filter
                      </Button>
                      <Button
                        variant='contained'
                        className='ml-2 custom-filter-btn'
                        onClick={handleResetFilter}
                      >
                        Reset
                      </Button>
                      {/* <input type='file' onChange={handleFileChange} />
                      <Button
                        variant='contained'
                        className='ml-2 custom-filter-btn'
                        onClick={handleImportCountries}
                      >
                        Import Excel
                      </Button> */}
                    </div>
                  </div>
                </div>
                <div className='table-responsive'>
                  {datalist && datalist?.length > 0 ? (
                    <>
                      <table className='table align-items-center table-flush'>
                        <thead className='thead-light'>
                          <tr>
                            <th scope='col'>S/No.</th>
                            <th scope='col'>Country Code</th>
                            <th scope='col'>Country</th>
                            <th scope='col'>Created At</th>
                            <th scope='col'>Status</th>
                         {
                          (userData.admin || editPermission) &&
                          <th scope='col' >Action</th>
                         }
                          </tr>
                        </thead>
                        <tbody>
                          {datalist &&
                            datalist?.map((data, i) => {
                              const startIndex =
                                (pagerecord.pageNumber - 1) *
                                pagerecord.pageSize
                              return (
                                <>
                                  <tr key={i}>
                                    <td>{startIndex + i + 1}</td>
                                    <td>{data?.code}</td>
                                    <td>{data?.name}</td>
                                    <td>{formatDate(data?.createdAt)}</td>
                                    <td>
                                      {userData.admin ? (
                                        <Switch
                                          {...label}
                                          checked={data?.active}
                                          onChange={e =>
                                            handleStatusChange(e, data?.id)
                                          }
                                          size='small'
                                        />
                                      ) : viewPermission ? (
                                        <Switch
                                          {...label}
                                          checked={data?.active}
                                          disabled={!activePermission}
                                          onChange={e =>
                                            handleStatusChange(e, data?.id)
                                          }
                                          size='small'
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  {
                                    (userData.admin || editPermission) && 
                                    <td className='text-right'>
                                    <div className='dropdown'>
                                      <a
                                        className='btn btn-sm btn-icon-only text-light'
                                        href='#'
                                        role='button'
                                        data-toggle='dropdown'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                      >
                                        <i className='fas fa-ellipsis-v' />
                                      </a>
                                      <div
                                        className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                        x-placement='bottom-end'
                                        style={{
                                          position: 'absolute',
                                          willChange: 'transform',
                                          top: '0px',
                                          left: '0px',
                                          transform:
                                            'translate3d(32px, 32px, 0px)'
                                        }}
                                      >
                                        {userData.admin ? (
                                          <a
                                            className='dropdown-item cursor'
                                            onClick={() =>
                                              handleEditCountry(data)
                                            }
                                          >
                                            Edit
                                          </a>
                                        ) : editPermission ? (
                                          <a
                                            className='dropdown-item cursor'
                                            onClick={() =>
                                              handleEditCountry(data)
                                            }
                                          >
                                            Edit
                                          </a>
                                        ) : (
                                          <></>
                                        )}
                                        {userData.admin && <a
                                          className='dropdown-item cursor'
                                          onClick={() => {
                                            setShowGlobalConfigModal(
                                              !showGlobalConfigModal
                                            )
                                            setCountryId(data?.id)
                                          }}
                                        >
                                          Configure Ads Matrix
                                        </a> }
                                      </div>
                                    </div>
                                  </td>
                                  }
                                  </tr>
                                </>
                              )
                            })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <>
                      <div className='text-center'>
                        <img width={'25%'} src={NoDataImg}></img>
                        <br />
                        <label style={{ fontWeight: 'bold' }}>
                          {LOADING_ENABLE ? (
                            <>Loading Data.....</>
                          ) : (
                            <>No Data Found</>
                          )}
                        </label>
                      </div>
                    </>
                  )}
                </div>
                {datalist && datalist.length > 0 ? (
                  <div className='card-footer py-4'>
                    <Box
                      display='flex'
                      justifyContent='right'
                      alignItems='center'
                    >
                      <span>Total record: {pagerecord?.totalItems}</span>
                      <Pagination
                        className='custom-pagination-class'
                        count={pagerecord?.totalPages}
                        page={page}
                        onChange={handleChange}
                        color='secondary'
                      />
                    </Box>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <AddCountry
          show={showAddCountry}
          countryFormData={countryFormData}
          rolePermissionData={rolePermissionData}
          subAdminListByRole={subAdminListByRole}
          setCountryFormData={setCountryFormData}
          onSubmit={() => handleOnSubmitCountryModel()}
          onHide={() => setShowAddCountry(false)}
        />
        <CountryAdsConfiguration
          show={showGlobalConfigModal}
          countryId={countryId}
          onSubmit={() => alert()}
          onHide={() => setShowGlobalConfigModal(false)}
        />
        </>
      ) : (<InsufficientPermission/>)
     }
      </div>
    </>
  )
}
