import { useEffect, useState } from 'react'

import Dropdown from 'react-bootstrap/Dropdown'
const transactionType = [
  {
    value: 'All',
    key: 'ALL'
  }, {
    value: 'Post Boost',
    key: 'POST_BOOST'
  }, {
    value: 'Badge Purchase',
    key: 'BADGE_PURCHASE'
  }, {
    value: 'Tip',
    key: 'TIP'
  }
]


export const BeadsTransactionFilter = props => {

  const [typeDropdownText, setTypeDropdownText] = useState({
    value: 'All',
    key: 'ALL'
  })
  const [badgeType, setBadgeTypeDropdownText] = useState('All')


  useEffect(() => {
    if (props?.transactionType) {
      setTypeDropdownText(props?.transactionType)
    }
  }, [props?.transactionType])


  return (
    <>
      <div className='col-md-1 mb-2 mr-5 '>
        <Dropdown>
          <Dropdown.Toggle
            variant='success'
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #cad1d7',
              boxShadow: 'none',
              transform: 'none'
            }}
            id='dropdown-basic'
          >
            {typeDropdownText?.value}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {transactionType?.map((item, i) => {
              return <><Dropdown.Item eventKey={item?.key}
                onClick={() => {
                  setTypeDropdownText(item)
                  props?.setTransactionType(item)
                }
                }
              >
                {item?.value}
              </Dropdown.Item></>
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className='col-md-1 mb-2 ml-5 d-none'>
        <Dropdown>
          <Dropdown.Toggle
            variant='success'
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #cad1d7',
              boxShadow: 'none',
              transform: 'none'
            }}
            id='dropdown-basic'
          >
            {badgeType}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setBadgeTypeDropdownText('All')}>
              All
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setBadgeTypeDropdownText('Badge')}>
              Badge
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  )
}
