import { useCallback, useEffect, useReducer, useState } from "react";
import NoDataImg from "../../../assets/img/no-data.gif";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dropdown from 'react-bootstrap/Dropdown'
import { v4 as uuidv4 } from 'uuid';
import SearchIcon from "@mui/icons-material/Search";
import InputGroup from "react-bootstrap/InputGroup";
import Pagination from "@mui/material/Pagination";
import NavBar from "../../structure/NavBar";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Form from "react-bootstrap/Form";
import { NavBarTop } from "../../structure/NavBarTop";
import { useDispatch, useSelector } from "react-redux";
import {
  updateTopHeader,
  uplodateLoading,
} from "../../../redux/features/common/commonSlice";
import { AddPromoVideo } from "./AddPromoVideo";
import AddIcon from "@mui/icons-material/Add";
import urls from "../../constants/apiurl";
import ApiCaller from "../../constants/ApiCaller";
import { showDangerToast, showToast } from "../../constants/toaster";
import Switch from "@mui/material/Switch";
import ShowLongText from "../../custom/ShowLongText";
import Image from "../../custom/Image";
import Utils from "../../constants/Utils";
import InsufficientPermission from "../../custom/InSufficientPermission";
const label = { inputProps: { "aria-label": "Size switch demo" } };
export const PromoVideoList = (props) => {
  const [enable, setEnable] = useState(false)
  const { isViewPermissionEnabled } = Utils()
  const [categortFilterData, setCategortFilterData] = useState([])
  const [languageFilterData, setLanguageFilterData] = useState([])
  const [categoryDropdownText, setCategoryDropdownText] = useState({ label: 'Select Category', value: '' })
  const [languageDropdownText, setLanguageDropdownText] = useState({ label: 'Select Language', value: '' })
  const [callAPi, setCallAPi] = useState('')
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [edit, setEdit] = useState(false);
  const [active, setActive] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [view, setView] = useState(false);
  const userData = useSelector((state) => state?.userData);
  const [showAddRole, setShowAddPromoVideo] = useState(false);
  const [datalist, setDatalist] = useState([]);
  const [viewPermissionControl, setViewPermissionControl] = useState(false);
  const [viewSubAdminList, setViewSubAdminList] = useState(false);
  const [sort_type, setSortType] = useState("createdAt");
  const [sort_order, setSortOrder] = useState(false);
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
  });
  const [promoVideoFormData, setPromoVideoFormData] = useReducer(
    (promoVideoFormData, newItem) => {
      return { ...promoVideoFormData, ...newItem };
    },
    { link: "", thumbnail: "", id: "", description: "", active: false, categoryId: '', categoryName: '', languageId: '', languageName: '' }
  );
  const [permissionSubmitData, setPermissionSubmitData] = useState([]);
  const [rolePermissionData, setRolePermissionData] = useState({});
  const [subAdminListByRole, setSubAdminListByRole] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10,
    });
    setCallAPi(uuidv4())
  };


  const userPermisiions = useSelector(state => state?.permission)
  let currentModulePermission;
  let viewPermission = true;
  let editPermission = true;
  let deletePermissionVal = true;
  let activePermission = true;

  if(userPermisiions?.modules.length>0){
    currentModulePermission = userPermisiions.modules.find((element)=>{
      if(element.moduleId==='3'){
         return element
      }
    })?.permissions
     viewPermission = currentModulePermission.find((element)=>{
      if(element.name==='VIEW'){
        return element
      }
    }).isEnable
     editPermission = currentModulePermission.find((element)=>{
      if(element.name==='EDIT'){
        return element
      }
    }).isEnable
    deletePermissionVal = currentModulePermission.find((element)=>{
      if(element.name==='DELETE'){
        return element
      }
    }).isEnable
    activePermission = currentModulePermission.find((element)=>{
      if(element.name==='ACTIVE'){
        return element
      }
    }).isEnable
   }
  //  console.log(currentModulePermission,'userPermisiions,rolelist',editPermission)

  const handleFilterClick = useCallback(() => {
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  }, [categoryDropdownText, languageDropdownText, startDate, endDate]);

  const handleResetFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCategoryDropdownText({ label: 'Select Category', value: '' })
    setLanguageDropdownText({ label: 'Select Language', value: '' })
    setCallAPi(uuidv4())
  };

  useEffect(() => {
    dispatch(updateTopHeader("Promo Video Manager"));
    if (userData.admin) {
      setEnable(userData.admin)
    } else {
      setEnable(isViewPermissionEnabled("3"))
    }
    getCategoryFilterData();
    getLanguageFilterData();
  }, []);

  useEffect(() => {
    if (userData.admin || enable) {
     if(viewPermission){
      getPromoVideoList()
     }
    }
  }, [callAPi,enable])


  const getCategoryFilterData = async () => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.tagInterestListKeyValuePair + "?keyword=";
      let res = await ApiCaller.get({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        let tempArray = []
        response?.data?.map((data) => {
          tempArray.push({
            label: data?.key,
            value: data?.value
          })
        })
        setCategortFilterData(tempArray)
        console.log(response?.data)
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  }
  const getLanguageFilterData = async () => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.languageListKeyValuePair + "?keyword=";
      let res = await ApiCaller.get({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        let tempArray = []
        response?.data?.map((data) => {
          tempArray.push({
            label: data?.value,
            value: data?.key
          })
        })
        setLanguageFilterData(tempArray)
        console.log(response?.data)
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  }

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.changePromoVideoStatus + "/" + id;
      let res = await ApiCaller.put({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        showToast(response?.message);
        getPromoVideoList();
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const getPromoVideoList = async () => {
    let startDateObject;
    let endDateObject;
    if (startDate?.toDate()) {
      let newDate = new Date(startDate?.toDate());
      newDate.setDate(newDate.getDate() + 1);
      startDateObject = newDate;
    }
    if (endDate?.toDate()) {
      // Create a new Date object based on startDate
      let newDate = new Date(endDate?.toDate());
      // Add one day
      newDate.setDate(newDate.getDate() + 1);
      endDateObject = newDate;
    }
    try {
      dispatch(uplodateLoading(true));
      let postJson = {
        pageNumber: pagerecord?.pageNumber - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        startDate:
          startDateObject !== null
            ? startDateObject
            : '',
        endDate:
          endDateObject !== null
            ? endDateObject
            : '',
        categoryId: categoryDropdownText?.value,
        languageId: languageDropdownText?.value
      };
      let path = urls.listPromoVideo;
      let res = await ApiCaller.post(postJson, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        setDatalist(response?.data?.response?.items);
        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === "EDIT") {
            setEdit(module.isEnable);
          } else if (module.permissionName === "ACTIVE") {
            setActive(module.isEnable);
          } else if (module.permissionName === "DELETE") {
            setDeletePermission(module.isEnable);
          } else if (module.permissionName === "VIEW") {
            setView(module.isEnable);
          }
        });
        setPagerecord({
          totalItems: response?.data?.response?.totalItems,
          totalPages: response?.data?.response?.totalPages,
          pageNumber: pagerecord?.pageNumber,
          pageSize: 10,
        });
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const handleEditPromoVideo = (item) => {
    setPromoVideoFormData(item);
    setShowAddPromoVideo(true);
  };

  const handleAddPromoVideo = () => {
    setPromoVideoFormData({ link: "", thumbnail: "", id: "", description: "", active: false, categoryId: '', categoryName: '', languageId: '', languageName: '' });
    setShowAddPromoVideo(true);
  };

  const handleOnSubmit = async () => {
    setShowAddPromoVideo(false)
    setCallAPi(uuidv4())
  };

  const submitPermissionControl = async () => {
    try {
      dispatch(uplodateLoading(true));
      let postData = {
        permissions: permissionSubmitData,
      };
      let path = urls.rolePermissionUpdate;
      let res = await ApiCaller.post(postData, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        showToast(response?.message);
        setViewPermissionControl(false);
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  return (
    <>
      <NavBar />
      <div className="main-content">
        <NavBarTop />
        <div className="header">
          <div className="logo">
            <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8"></div>
          </div>
        </div>
     {
      viewPermission ? (
        <>
           <div className="container-fluid mt--7">
          <div className="row">
            <div className="col text-left">
              <div className="card shadow">
                <div className="card-header border-0">
                  <div className="row align-items-center text-left">
                    <div className="col">
                      <h3 className="mb-0">Promo Video List</h3>
                    </div>
                    <div className="col text-right">
                      {userData.admin ? (
                        <a
                          onClick={handleAddPromoVideo}
                          className="btn btn-sm btn-primary text-white"
                        >
                          <AddIcon />
                          Add New
                        </a>
                      ) : editPermission ? (
                        <a
                          onClick={handleAddPromoVideo}
                          className="btn btn-sm btn-primary text-white"
                        >
                          <AddIcon />
                          Add New
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-header border-0">
                  <div className="row align-items-center text-left">
                    <div className="col-md-3">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='success'
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            border: '1px solid #cad1d7',
                            boxShadow: 'none',
                            transform: 'none'
                          }}
                          id='dropdown-basic'
                        >
                          {categoryDropdownText?.label}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {categortFilterData?.map((data) => {
                            return <Dropdown.Item
                              onClick={() =>
                                setCategoryDropdownText(data)
                              }
                            >
                              {data?.label}
                            </Dropdown.Item>
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="col-md-3">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='success'
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            border: '1px solid #cad1d7',
                            boxShadow: 'none',
                            transform: 'none'
                          }}
                          id='dropdown-basic'
                        >
                          {languageDropdownText?.label}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {languageFilterData?.map((data) => {
                            return <Dropdown.Item
                              onClick={() =>
                                setLanguageDropdownText(data)
                              }
                            >
                              {data?.label}
                            </Dropdown.Item>
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {/* <div className="col-md-6 mb-3">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DatePicker", "DatePicker"]}
                        >
                          <DatePicker
                            disableFuture
                            className="custom-date"
                            label="From Date"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                          />
                          <DatePicker
                            disableFuture
                            label="To Date"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            minDate={startDate}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div> */}
                    <div className="col-md-3 mb-2">
                      <Button
                        variant="contained"
                        className="custom-filter-btn"
                        onClick={handleFilterClick}
                      >
                        Filter
                      </Button>
                      <Button
                        variant="contained"
                        className="ml-2 custom-filter-btn"
                        onClick={handleResetFilter}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  {datalist?.length ? (
                    <>
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Thumbail</th>
                            <th scope="col">Category</th>
                            <th scope="col">Language</th>
                            <th scope="col">Status</th>
                           {
                            editPermission && 
                            <th scope="col">Action</th>
                           }
                          </tr>
                        </thead>
                        <tbody>
                          {datalist?.map((data, i) => {
                            return (
                              <tr>
                                <td>
                                  <Image
                                    image={{
                                      id: 1,
                                      src: data?.thumbnail
                                    }}
                                    width='80px'
                                    height='auto
'
                                  />
                                </td>
                                <td>{data?.categoryName ? data?.categoryName : "-"}</td>
                                <td>{data?.languageName ? data?.languageName : "-"}</td>
                                <td>
                                  {userData.admin ? (
                                    <Switch
                                      {...label}
                                      checked={data?.active}
                                      onChange={(e) =>
                                        handleStatusChange(e, data?.id)
                                      }
                                      size="small"
                                    />
                                  ) : viewPermission ? (
                                    <Switch
                                      {...label}
                                      disabled={!activePermission}
                                      checked={data?.active}
                                      onChange={(e) =>{
                                        if(activePermission){
                                          handleStatusChange(e, data?.id)
                                        }
                                      }
                                      }
                                      size="small"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </td>
                               {
                                editPermission && 
                                <td className="text-left">
                                <div className="dropdown">
                                  <a
                                    className="btn btn-sm btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </a>
                                  <div
                                    className="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                                    x-placement="bottom-end"
                                    style={{
                                      position: "absolute",
                                      willChange: "transform",
                                      top: "0px",
                                      left: "0px",
                                      transform:
                                        "translate3d(32px, 32px, 0px)",
                                    }}
                                  >
                                    {userData.admin ? (
                                      <a
                                        className="dropdown-item cursor"
                                        onClick={() => handleEditPromoVideo(data)}
                                      >
                                        Edit
                                      </a>
                                    ) : editPermission ? (
                                      <a
                                        className="dropdown-item cursor"
                                        onClick={() => handleEditPromoVideo(data)}
                                      >
                                        Edit
                                      </a>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </td>
                               }
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <>
                      <div className="text-center">
                        <img width={"25%"} src={NoDataImg}></img>
                        <br />
                        <label style={{ fontWeight: 'bold' }}>
                          {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
                        </label>
                      </div>
                    </>
                  )}
                </div>
                {datalist && datalist.length > 0 ? (
                  <div className="card-footer py-4">
                    <Box display="flex" justifyContent="right" alignItems='center'>
                      <span>Total Record: {pagerecord?.totalItems}</span>
                      <Pagination
                        className="custom-pagination-class"
                        count={pagerecord?.totalPages}
                        page={page}
                        onChange={handleChange}
                        color="secondary"
                      />
                    </Box>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <AddPromoVideo
          show={showAddRole}
          promoVideoFormData={promoVideoFormData}
          setPromoVideoFormData={setPromoVideoFormData}
          onSubmit={() => handleOnSubmit()}
          onHide={() => setShowAddPromoVideo(false)}
        />
        </>
      ) : (<InsufficientPermission/>)
     }
      </div>
    </>
  );
};
