import $ from 'jquery'
import { useEffect, useState } from 'react'
import NavBar from '../structure/NavBar'
import { useDispatch, useSelector } from 'react-redux'
import { NavBarTop } from '../structure/NavBarTop'
import { Footer } from '../structure/Footer'
import { RenderHeader } from '../structure/Header'
import {
  updatePermission,
  updateTopHeader,
  uplodateLoading
} from '../../redux/features/common/commonSlice'
import Utils from '../constants/Utils'
import InsufficientPermission from '../custom/InSufficientPermission'
import urls from '../constants/apiurl'
import ApiCaller from '../constants/ApiCaller'
export const Dashboard = () => {
  const { isViewPermissionEnabled } = Utils()
  const [enable, setEnable] = useState(false)
  const dispatch = useDispatch()
  const userData = useSelector(state => state?.userData)
  const userPermisiions = useSelector(state => state?.permission)
  useEffect(() => {
    $('body').removeClass('bg-default')
    dispatch(updateTopHeader('Dashboard'))
    dispatch(uplodateLoading(false))
  })


  useEffect(() => {
    if (userData.admin) {
      setEnable(userData.admin)
    } else {
      if (localStorage.getItem("permissionId")) {
        fetchPermissions(localStorage.getItem("permissionId"))
      } else {
        setEnable(false)
      }
    }
  }, [userData])

  const fetchPermissions = async (id) => {
    let path = urls.fetchPermission + '/' + id
    let res = await ApiCaller.get({}, path, userData?.access_token)
    const response = await res.response.json()
    if (response.status == 200) {
      dispatch(updatePermission(response.data))
      response?.data?.modules?.map((data) => {
        if (data?.moduleId === "1") {
          data?.permissions?.map((pData) => {
            if (pData?.name === 'VIEW' && pData?.isEnable) {
              setEnable(true)
              console.log("============================")
            }
          })
        }
      })
    }
  }

  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        {enable ? <RenderHeader /> : <>
          <div className='header'>
            <div className='logo'>
              <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'>
                <div className='container-fluid'>
                  <div className='header-body'>
                    <div className='row' style={{ justifyContent: 'center' }}>
                      <InsufficientPermission />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>}
      </div>
    </>
  )
}
