import React from "react";
import { Button, Modal } from "react-bootstrap";
import Utils from "../../constants/Utils";

const ViewFaq = (props) => {
  const { formatDate } = Utils();
  console.log(props.faqFormData, ">>>>>>>>>");
  return (
    <Modal show={props.show} onHide={props.onHide} centered>
      <Modal.Header>
        <Modal.Title className="text-dark">FAQ Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="row mt-1">
              <div className="col-md-12">
                <h5>Question:</h5>
                {props.faqFormData.question}
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-md-12">
                <h4>Description:</h4>
                <p dangerouslySetInnerHTML={{
                  __html: props.faqFormData.description,
                }}></p>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-md-4">
                <h4>Sequence:</h4>
                <p>{props.faqFormData.sequence}</p>
              </div>
              <div className="col-md-4">
                <h4>Active:</h4>
                <p>{props.faqFormData.active ? "Yes" : "No"}</p>
              </div>
              <div className="col-md-4">
                <h4>Created At:</h4>
                <p>{formatDate(props?.faqFormData?.createdAt)}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewFaq;
