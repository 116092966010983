import Dropdown from 'react-dropdown'
import AWS from 'aws-sdk'
import 'react-dropdown/style.css'
import Form from 'react-bootstrap/Form'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import ReplyIcon from '@mui/icons-material/Reply'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { useCallback, useRef, useState } from 'react'

import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from './loadOptions'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { useDispatch, useSelector } from 'react-redux'
import { showDangerToast, showToast } from '../../constants/toaster'
import { TextAreaCustom } from '../../custom/TextAreaCustom'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'



const options = [
  { value: '', label: 'Select User' },
  { value: '1', label: 'Ganesh' }
]
const defaultOption = options[0]

export const AddNotification = props => {
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  });
  const dispatch = useDispatch()
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const pageNumberRef = useRef(pagerecord.pageNumber);
  const [notificationTitle, setNotificationTitle] = useState('')
  const [notificationBody, setNotificationBody] = useState('')
  const userData = useSelector((state) => state?.userData);
  const [value, setValue] = useState('all')
  const [valueDropdown, setValueDropdown] = useState(null);
  const [showUserDropdown, setShowUserDropdown] = useState(false)
  const [errors, setErrors] = useState({
    titleError: '',
    bodyError: '',
    userError: ''
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);


  const uploadFile = async assetFile => {
    // S3 Bucket Name
    const S3_BUCKET = 'supa-dev-bucket'

    // S3 Region
    const REGION = 'ap-south-1'

    // S3 Credentials
    AWS.config.update({
      accessKeyId: 'AKIAQLSIVLYXUCEHSXOK',
      secretAccessKey: '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'
    })
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION
    })
    // Files Parameters
    const fileName = 'notification-images/' + assetFile?.name
    console.log(fileName)
    const params = {
      Bucket: S3_BUCKET,
      Key: fileName,
      Body: assetFile
    }

    // Uploading file to s3

    var upload = await s3
      .putObject(params)
      .on('httpUploadProgress', evt => {
        console.log(
          'Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%'
        )
        setUploadPercentage(parseInt((evt.loaded * 100) / evt.total))
      })
      .promise()
    return 'https://dhwdwg726vpm7.cloudfront.net/' + fileName
  }



  // Handle the image selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const handleChange = event => {
    setValue(event.target.value)
    if (event.target.value === 'toUser') {
      setShowUserDropdown(true)
    } else {
      setShowUserDropdown(false)
    }
  }


  const validateFields = () => {
    let isValid = true;
    let tempErrors = { titleError: '', bodyError: '', userError: '' };

    if (!notificationTitle.trim()) {
      tempErrors.titleError = 'Notification title is required.';
      isValid = false;
    }
    console.log(tempErrors, value, notificationBody)

    if (!notificationBody.trim()) {
      tempErrors.bodyError = 'Notification body is required.';
      isValid = false;
    }

    if (showUserDropdown && !valueDropdown) {
      tempErrors.userError = 'Please select a user.';
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined);
      }, ms);
    });

  const wrappedLoadOptions = useCallback(function (...args) {
    return loadOptionsTest(...args);
  }, []);



  const sendPushNotification = async () => {
    try {
      const validValue = validateFields();
      if (!validValue) {
        return; // If validation fails, do not proceed.
      }
      let notificationImage = "";
      if (imageFile) {
        dispatch(uplodateLoading(true))
        notificationImage = await uploadFile(imageFile)
      }
      let postJson = {
        all: !showUserDropdown,
        title: notificationTitle?.trim(),
        // body: "Testinggggggg",
        imageUrl: notificationImage ? notificationImage : "",
        body: notificationBody?.trim(),
        users: [valueDropdown?.value],
      }
      let path = urls.sendBatchPushNotification
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        showToast(response?.message)
        props?.setAddNewNotification(false)
      } else {
        showDangerToast(response.message)
      }
      dispatch(uplodateLoading(false))
    } catch (error) {
      console.error(error)
      showDangerToast(error.message)
      dispatch(uplodateLoading(false))
    }
  }



  const loadOptionsTest = async (
    search,
    prevOptions
  ) => {
    console.log("1")
    await sleep(1000);
    try {
      let postJson = {
        searchText: search?.trim(),
        pageNumber: search?.trim() ? 0 : pageNumberRef.current - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: "createdAt",
        asc: false,
      }
      let path = urls.userSearchDropdownList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        console.log(response?.data?.items)
        let tempArray = []
        response?.data?.items.forEach(element => {
          tempArray.push({
            value: element?.key,
            label: element?.value
          })
        });
        setPagerecord({
          ...pagerecord,
          pageNumber: pagerecord?.pageNumber + 1
        });
        pageNumberRef.current = pageNumberRef.current + 1;
        const hasMore = response?.data?.totalPages > 0 && response?.data?.totalPages > pagerecord?.pageNumber;
        return {
          options: tempArray,
          hasMore
        };

      } else {
        showDangerToast(response.message)
      }
    } catch (error) {
      console.error(error)
      showDangerToast(error.message)
    }

  };


  return (
    <>
      <div className='container-fluid mt--7'>
        <div className='row'>
          <div className='col text-left'>
            <div className='card shadow'>
              <div className='card-header border-0'>
                <div className='row align-items-center '>
                  <div className='col text-left'>
                    <a
                      onClick={() => props.setAddNewNotification(false)}
                      className='btn btn-sm btn-primary text-white'
                    >
                      <ReplyIcon />
                      Cancel
                    </a>
                  </div>
                  <div className='col text-center'>
                    <h3 className='mb-0'>Add Notification</h3>
                  </div>
                  <div className='col text-right'>
                    <a
                      onClick={() => sendPushNotification()}
                      // onClick={props.sendNotification}
                      className='btn btn-sm btn-primary text-white'
                    >
                      Send
                    </a>
                  </div>
                </div>
              </div>

              <div className='card-header border-0'>
                <div className='row align-items-center justify-content-center '>
                  <div className='col-md-6'>
                    <Form>
                      <Form.Group
                        className='mb-3'
                        controlId='exampleForm.ControlInput1'
                      >
                        <Form.Label>Notification Title</Form.Label>
                        <Form.Control type='text' placeholder='Title' value={notificationTitle} onChange={(e) => setNotificationTitle(e.target.value)} />
                        {errors.titleError && (
                          <small className="text-danger">{errors.titleError}</small>
                        )}
                      </Form.Group>
                    </Form>
                  </div>
                </div>
                <div className='row align-items-center justify-content-center '>
                  <div className='col-md-6'>
                    <FormControl>
                      <FormLabel id='demo-row-radio-buttons-group-label'>
                        Users
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='row-radio-buttons-group'
                        value={value}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value='all'
                          control={<Radio />}
                          label='All'
                        />
                        <FormControlLabel
                          value='toUser'
                          control={<Radio />}
                          label='Any Particular User'
                        />
                        {/* <FormControlLabel
                          value='allSubAdmins'
                          control={<Radio />}
                          label='All Sub Admins'
                        /> */}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>

                {showUserDropdown ? (
                  <>
                    <div className='row align-items-center justify-content-center '>
                      <div className='col-md-6'>
                        <AsyncPaginate
                          debounceTimeout={300}
                          value={valueDropdown}
                          // loadOptions={loadOptions}
                          loadOptions={wrappedLoadOptions}
                          onChange={(e) => setValueDropdown(e)}
                        />
                        {errors.userError && (
                          <small className="text-danger">{errors.userError}</small>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className='row align-items-center justify-content-center mt-3'>
                  <div className='col-md-6'>
                    {/* <TextAreaCustom value={notificationBody} onChange={(e) => setNotificationBody(e.target.value)} /> */}
                    <textarea
                      rows={3}
                      placeholder='Body'
                      value={notificationBody}
                      onChange={(e) => setNotificationBody(e.target.value)}
                      style={{
                        width: '100%',
                        padding: '8px 12px',
                        borderRadius: '8px',
                        border: '1px solid #ccc',
                        boxShadow: '0px 2px 2px #f0f0f0',
                        fontFamily: 'Arial, sans-serif',
                        fontSize: '14px',
                      }}
                    />
                    {errors.bodyError && (
                      <small className="text-danger">{errors.bodyError}</small>
                    )}
                    {/* <CKEditor
                      editor={ClassicEditor}
                      data='<p>Hello from CKEditor&nbsp;5!</p>'
                      onReady={editor => {
                        console.log('Editor is ready to use!', editor)
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        console.log({ event, editor, data })
                      }}
                      onBlur={(event, editor) => {
                        console.log('Blur.', editor)
                      }}
                      onFocus={(event, editor) => {
                        console.log('Focus.', editor)
                      }}
                    /> */}
                  </div>
                </div>
                <div className='row align-items-center justify-content-center mt-3'>
                  <div className='col-md-6'>
                    <input
                      type="file"
                      accept="image/png"
                      onChange={handleImageChange}
                      style={{ marginBottom: '20px' }}
                    />
                    {selectedImage && (
                      <div>
                        <h4>Image Preview:</h4>
                        <img
                          src={selectedImage}
                          alt="Selected"
                          style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                        />
                      </div>
                    )}
                    {uploadPercentage > 0 && (
                      <div style={{ marginTop: "20px" }}>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#ddd",
                            height: "30px",
                            borderRadius: "5px",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            style={{
                              width: `${uploadPercentage}%`,
                              backgroundColor: "#4caf50",
                              height: "100%",
                              color: "white",
                              textAlign: "center",
                              lineHeight: "30px",
                            }}
                          >
                            {uploadPercentage}%
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
