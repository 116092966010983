import Switch from '@mui/material/Switch'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@mui/material/Button'
import dayjs, { Dayjs } from 'dayjs'
import { v4 as uuidv4 } from 'uuid';

import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import Pagination from '@mui/material/Pagination'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import NoDataImg from '../../../assets/img/no-data.gif'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useCallback, useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { ViewDetails } from './ViewDetails'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import Utils from '../../constants/Utils'
import { FullImageView } from '../../custom/FullImageView'
import { Box } from '@mui/material';
import { GiveRewardOnVideo } from '../../custom/GiveRewardOnVideo';
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const VideoList = props => {
  const [page, setPage] = useState(1)
  const { formatDate } = Utils();
  const [callAPi, setCallAPi] = useState('')
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [fullSizePreview, setFullSizePreview] = useState(false)
  const [imageUrl, setImageUrl] = useState(false)
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [sort_type, setSortType] = useState('createdAt')
  const [searchText, setSearchText] = useState("")
  const [sort_order, setSortOrder] = useState(false)
  const [showViewDetails, setShowViewDetails] = useState(false)
  const [showGiveReward, setShowGiveReward] = useState(false)
  const [selectedVideoDetails, setSelectedVideoDetails] = useState({})
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [mediaDropDownText, setMediaDropDownText] = useState('VIDEO')
  const [mediaUIChange, setMediaUIChange] = useState(true)
  const [activeInActiveStatus, setActiveInActiveStatus] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const dispatch = useDispatch()
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const [datalist, setDatalist] = useState([])
  const userData = useSelector(state => state?.userData)
  const [showAddFontStyle, setShowAddFontStyle] = useState(false)
  const [fontStyleFormData, setFontStyleFormData] = useReducer(
    (fontStyleFormData, newItem) => {
      return { ...fontStyleFormData, ...newItem }
    },
    {
      id: '',
      assetName: '',
      type: '',
      description: '',
      coverImageUrl: '',
      remotePackageUrl: ''
    }
  )

  const userPermisiions = useSelector(state => state?.permission)
  let currentModulePermission;
  let viewPermission = true;
  let activePermission = true;

  if (userPermisiions?.modules.length > 0) {
    currentModulePermission = userPermisiions.modules.find((element) => {
      if (element.moduleId === '9') {
        return element
      }
    })?.permissions
    viewPermission = currentModulePermission.find((element) => {
      if (element.name === 'VIEW') {
        return element
      }
    }).isEnable
    activePermission = currentModulePermission.find((element) => {
      if (element.name === 'ACTIVE') {
        return element
      }
    }).isEnable
  }


  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
    setCallAPi(uuidv4())
  }

  useEffect(() => {
    dispatch(updateTopHeader('Video Manager'))
  })

  useEffect(() => {
    if (viewPermission) {
      getVideoList()
    }
  }, [callAPi])


  // Handle filter click
  const handleFilterClick = useCallback(() => {
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
    setMediaUIChange(mediaDropDownText === 'VIDEO')
  }, [searchText, mediaDropDownText, activeInActiveStatus, startDate, endDate]);

  const getVideoList = async () => {
    let startDateObject;
    let endDateObject;
    if (startDate?.toDate()) {
      let newDate = new Date(startDate?.toDate());
      newDate.setDate(newDate.getDate() + 1);
      startDateObject = newDate;
    }
    if (endDate?.toDate()) {
      let newDate = new Date(endDate?.toDate());
      newDate.setDate(newDate.getDate() + 1);
      endDateObject = newDate;
    }
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        page: pagerecord?.pageNumber - 1 || 0,
        limit: pagerecord.pageSize || 10,
        sort: sort_type,
        order: sort_order,
        searchTxt: searchText?.trim(),
        videoType: mediaDropDownText === 'VIDEO' ? true : false,
        active: activeInActiveStatus,
        startDate:
          startDateObject !== null
            ? startDateObject
            : '',
        endDate:
          endDateObject !== null
            ? endDateObject
            : '',
      }
      let path = urls.videoList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.items)
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pagerecord?.pageNumber,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.activeInActiveVideo + '?videoId=' + id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getVideoList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleResetFilter = () => {
    setSearchText('')
    setStartDate(null)
    setEndDate(null)
    setStatusDropdownText("Select Status")
    setMediaDropDownText("VIDEO")
    setActiveInActiveStatus(null)
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setMediaUIChange(true)
    setCallAPi(uuidv4())
  }

  return (
    <>
      <>
        <NavBar />
        <div className='main-content'>
          <NavBarTop />
          <div className='header'>
            <div className='logo'>
              <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
            </div>
          </div>
          <div className='container-fluid mt--7'>
            <div className='row'>
              <div className='col text-left'>
                <div className='card shadow'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col'>
                        <h3 className='mb-0'>Video Manager</h3>
                      </div>
                    </div>
                  </div>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col-md-3'>
                        <InputGroup
                          className='mb-2'
                          style={{ borderRadius: '10px' }}
                        >
                          <Form.Control
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            placeholder='Search by User Name or ID'
                            aria-label='Amount (to the nearest dollar)'
                          />
                        </InputGroup>
                      </div>
                      <div className='col-md-2 mb-2 '>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              border: '1px solid #cad1d7',
                              boxShadow: 'none',
                              transform: 'none'
                            }}
                            id='dropdown-basic'
                          >
                            {statusDropdownText}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setStatusDropdownText('Select Status')
                                setActiveInActiveStatus(null)
                              }
                              }
                            >
                              Select Status
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setStatusDropdownText('Active')
                                setActiveInActiveStatus(true)
                              }}
                            >
                              Active
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setStatusDropdownText('In Active')
                                setActiveInActiveStatus(false)
                              }}
                            >
                              In Active
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className='col-md-2 mb-2 '>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              border: '1px solid #cad1d7',
                              boxShadow: 'none',
                              transform: 'none'
                            }}
                            id='dropdown-basic'
                          >
                            {mediaDropDownText}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setMediaDropDownText('VIDEO')
                              }}
                            >
                              VIDEO
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setMediaDropDownText('IMAGE')
                              }}
                            >
                              IMAGE
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div
                        className='col-md-6 mb-3'
                        style={{ marginLeft: '20px' }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker', 'DatePicker']}
                          >
                            <DatePicker
                              disableFuture
                              className='custom-date'
                              label='From Date'
                              value={startDate}
                              onChange={newValue => setStartDate(newValue)}
                              maxDate={endDate}
                            />
                            <DatePicker
                              disableFuture
                              label='To Date'
                              value={endDate}
                              minDate={startDate}
                              onChange={newValue => setEndDate(newValue)}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='col-md-3 mb-2'>
                        <Button
                          variant='contained'
                          className='custom-filter-btn'
                          onClick={handleFilterClick}
                        >
                          Filter
                        </Button>
                        <Button
                          variant='contained'
                          className='ml-2 custom-filter-btn'
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {datalist?.length ? (
                      <>
                        <table className='table align-items-center table-flush'>
                          <thead className='thead-light'>
                            <tr>
                              <th scope='col'>S/No.</th>
                              <th scope='col'>User ID</th>
                              <th scope='col'>User Name</th>
                              <th scope='col'>Mobile No.</th>
                              <th scope='col'>{mediaUIChange ? 'Video Link' : 'Posts'}</th>
                              {mediaUIChange ?
                                <>
                                  <th scope='col'>No. Of Likes</th>
                                  <th scope='col'>No. Of Comments</th>
                                  <th scope='col'>Reports</th>
                                  <th scope='col'>Date</th>
                                  <th scope='col'>Status</th>
                                  <th scope='col'>Action</th>
                                </>
                                : <>
                                  <th scope='col'>Date</th>
                                  <th scope='col'>Status</th>
                                  <th scope='col'>Action</th>
                                </>}

                            </tr>
                          </thead>
                          <tbody>
                            {datalist?.map((data, i) => {
                              const startIndex = (pagerecord.pageNumber - 1) * pagerecord.pageSize;
                              return (
                                <>
                                  <tr key={i}>
                                    <td>{startIndex + i + 1}</td>
                                    <td>
                                      {data?.profileDetails?.userRecordId}
                                    </td>
                                    <td>{data?.profileDetails?.userName}</td>
                                    <td>{data?.profileDetails?.mobile}</td>
                                    <td className='truncate'>
                                      {mediaUIChange ? <>
                                        <a target='_blank' href={data?.link} rel="noreferrer">
                                          View
                                        </a>
                                      </> : <>

                                        {data?.images &&
                                          data?.images?.map((shopImg, i) => {
                                            return (
                                              <>
                                                <img
                                                  src={shopImg}
                                                  style={{
                                                    width: '15%',
                                                    marginRight: '15px',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer'
                                                  }}
                                                  onClick={() => {
                                                    setFullSizePreview(!fullSizePreview)
                                                    setImageUrl(shopImg)
                                                  }}
                                                ></img>
                                              </>
                                            )
                                          })}
                                      </>}
                                    </td>
                                    {mediaUIChange ?
                                      <>
                                        <td>{data?.totalLikes}</td>
                                        <td>{data?.totalComments}</td>
                                        <td>{data?.totalReports}</td>
                                        <td>{formatDate(data?.createdAt)}</td>
                                        <td>
                                          <Switch
                                            {...label}
                                            checked={data?.active}
                                            disabled={!activePermission}
                                            onChange={(e) => {
                                              if (activePermission) {
                                                handleStatusChange(e, data?.id)
                                              }
                                            }
                                            }
                                            size='small'
                                          />
                                        </td>
                                        <td className='text-right'>
                                          <div className='dropdown'>
                                            <a
                                              className='btn btn-sm btn-icon-only text-light'
                                              href='#'
                                              role='button'
                                              data-toggle='dropdown'
                                              aria-haspopup='true'
                                              aria-expanded='false'
                                            >
                                              <i className='fas fa-ellipsis-v' />
                                            </a>
                                            <div
                                              className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                              x-placement='bottom-end'
                                              style={{
                                                position: 'absolute',
                                                willChange: 'transform',
                                                top: '0px',
                                                left: '0px',
                                                transform:
                                                  'translate3d(32px, 32px, 0px)'
                                              }}
                                            >
                                              <a
                                                className='dropdown-item cursor'
                                                onClick={() => {
                                                  setShowViewDetails(true)
                                                  setSelectedVideoDetails(data)
                                                }
                                                }
                                              >
                                                View Details
                                              </a>
                                              <a
                                                className='dropdown-item cursor'
                                                onClick={() => {
                                                  setShowGiveReward(true)
                                                  setSelectedVideoDetails(data)
                                                }
                                                }
                                              >
                                                Give Reward
                                              </a>
                                            </div>
                                          </div>
                                        </td>
                                      </>
                                      : <>
                                        <td>{formatDate(data?.createdAt)}</td>
                                        <td>
                                          <Switch
                                            {...label}
                                            checked={data?.active}
                                            onChange={(e) =>
                                              handleStatusChange(e, data?.id)
                                            }
                                            size='small'
                                          />
                                        </td>
                                        <td className='text-right'>
                                          <div className='dropdown'>
                                            <a
                                              className='btn btn-sm btn-icon-only text-light'
                                              href='#'
                                              role='button'
                                              data-toggle='dropdown'
                                              aria-haspopup='true'
                                              aria-expanded='false'
                                            >
                                              <i className='fas fa-ellipsis-v' />
                                            </a>
                                            <div
                                              className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                              x-placement='bottom-end'
                                              style={{
                                                position: 'absolute',
                                                willChange: 'transform',
                                                top: '0px',
                                                left: '0px',
                                                transform:
                                                  'translate3d(32px, 32px, 0px)'
                                              }}
                                            >
                                              <a
                                                className='dropdown-item cursor'
                                                onClick={() => {
                                                  setShowViewDetails(true)
                                                  setSelectedVideoDetails(data)
                                                }
                                                }
                                              >
                                                View Details
                                              </a>
                                            </div>
                                          </div>
                                        </td>

                                      </>}


                                  </tr>
                                </>
                              )
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <>
                          <div className='text-center'>
                            <img width={'25%'} src={NoDataImg}></img>
                            <br />
                            <label style={{ fontWeight: 'bold' }}>
                              {LOADING_ENABLE ? (
                                <>Loading Data.....</>
                              ) : (
                                <>No Data Found</>
                              )}
                            </label>
                          </div>
                        </>
                      </>
                    )}
                  </div>
                  {datalist && datalist.length > 0 ? (
                    <div className='card-footer py-4'>
                      <Box display="flex" justifyContent="right" alignItems='center'>
                        <span>Total record: {pagerecord?.totalItems}</span>
                        <Pagination
                          className='custom-pagination-class'
                          count={pagerecord?.totalPages}
                          page={page}
                          onChange={handleChange}
                          color='secondary'
                        />
                      </Box>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ViewDetails
          show={showViewDetails}
          selectedVideoDetails={selectedVideoDetails}
          onHide={() => setShowViewDetails(false)}
        />
        <GiveRewardOnVideo
          show={showGiveReward}
          selectedVideoDetails={selectedVideoDetails}
          onHide={() => setShowGiveReward(false)}
        />
        <FullImageView
          src={imageUrl}
          show={fullSizePreview}
          onHide={() => setFullSizePreview(false)}
        />
      </>
    </>
  )
}
