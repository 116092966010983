import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Utils from '../../constants/Utils'
export const ViewAds = props => {
  const { formatDate } = Utils();

  const displayName = (label) => {
    return <>
      {label ? label + ',' : ''}
    </>
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          View Advertisement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body-child">
          <div className="left-side">
            <table>
              <tbody>
                <tr>
                  <th>Created By:</th>
                  <td>{props?.data?.adminAds ? 'Admin' : 'User'}</td>
                </tr>
                {props?.data?.userName && <tr>
                  <th>UserName</th>
                  <td>{props?.data?.userName}</td>
                </tr>}

                <tr>
                  <th>Type</th>
                  <td>{props?.data?.type}</td>
                </tr>
                <tr>
                  <th>Target Type</th>
                  <td>{props?.data?.targetType}</td>
                </tr>
                {props?.data?.cityName || props?.data?.districtName || props?.data?.stateName || props?.data?.countryName && <>
                  <tr>
                    <th>Location</th>
                    <td>{displayName(props?.data?.cityName)}  {displayName(props?.data?.districtName)} {displayName(props?.data?.stateName)} {props?.data?.countryName}</td>
                  </tr>
                </>}
                {props?.data?.websiteLink && <tr>
                  <th>Website</th>
                  <td>{props?.data?.websiteLink}</td>
                </tr>}
                {props?.data?.description && <tr>
                  <th>Description</th>
                  <td>{props?.data?.description ? props?.data?.description : '-'}</td>
                </tr>}

                <tr>
                  <th>Content Type</th>
                  <td>{props?.data?.contentType}</td>
                </tr>
                <tr>
                  <th>Total Target</th>
                  <td>{props?.data?.targets}</td>
                </tr>
                <tr>
                  <th>Pending Target</th>
                  <td>{props?.data?.pendingTargets}</td>
                </tr>
                {props?.data?.startDate && <tr>
                  <th>Start Date</th>
                  <td>{formatDate(props?.data?.startDate)}</td>
                </tr>}
                {props?.data?.endDate && <tr>
                  <th>End Date</th>
                  <td>{formatDate(props?.data?.endDate)}</td>
                </tr>}

                <tr>
                  <th>Admin Approval</th>
                  <td>{props?.data?.adminApproval ? 'Approved' : 'Pending'}</td>
                </tr>
                {!props?.data?.adminAds && <tr>
                  <th>Total Amount</th>
                  <td>{props?.data?.chipsAmount} Chips</td>
                </tr>}

              </tbody></table>
          </div>
          <div className="right-side">
            {/* Uncomment the image or video element as needed */}
            {props?.data?.contentType && props?.data?.contentType === 'IMAGE' ? <img src={props?.data?.contentUrl} alt="Placeholder Image" /> : props?.data?.contentType === 'VIDEO' ?
              <video controls>
                <source src={props?.data?.contentUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video> : <></>}

            {/* <video controls>
              <source src="video.mp4" type="video/mp4">
              Your browser does not support the video tag.
          </video> */}
          </div>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
