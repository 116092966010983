import $ from "jquery";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useCallback, useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DropBox from "../../custom/DropBox";
import ShowImage from "../../custom/ShowImage";
import { showDangerToast } from "../../constants/toaster";
const imageFileTypes = ["image/png", "image/gif", "image/jpeg"];
const otherFileTypes = ['image/png', 'image/gif', 'image/jpeg']
// const otherFileTypes = ["font/ttf"];

export const AddEffect = (props) => {
  const handleInputChangeTag = (event) => {
    props.setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" && props.inputValue.trim() !== "") {
      props?.setTags([...props?.tags, props.inputValue.trim()]);
      props.setInputValue("");
    }
  };

  const handleChipDelete = (tagToDelete) => () => {
    props?.setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };
  useEffect(() => {}, []);
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);

  const handleInputChange = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, "");
    props.setEffectFormData({
      ...props?.effectFormData,
      duration: newValue,
    });
  };

  const handleSubmit = () => {
    if (props?.effectFormData?.id) {
      if (
        props?.effectFormData?.name &&
        props?.effectFormData?.duration &&
        props?.effectFormData?.widgetId &&
        props?.effectFormData?.description
      ) {
        props.onSubmit();
      } else {
        showDangerToast("Please fill all required Fields.");
      }
    } else {
      if (
        props?.effectFormData?.name &&
        props?.effectFormData?.duration &&
        props?.effectFormData?.widgetId &&
        props?.effectFormData?.description &&
        props?.thumbnailFile &&
        props?.effectFile
      ) {
        props.onSubmit();
      } else {
        showDangerToast("Please fill all required Fields.");
      }
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      if (imageFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = function (e) {
          setImages((prevState) => [
            ...prevState,
            { id: index, src: e.target.result },
          ]);
          props?.setThumbnailFile(file);
          props.setThumbanailControl({
            showError: false,
            errorMessage: "",
            showImage: true,
            imageData: { id: index, src: e.target.result },
          });
        };
        reader.readAsDataURL(file);

        return file;
      } else {
        props.setThumbanailControl({
          showError: true,
          errorMessage: "Only Image file is accept. ",
          showImage: false,
          imageData: [],
        });
      }
    });
  }, []);

  const onDropFile = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      console.log("file.type", file.type);
      if (otherFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = function (e) {
          setFiles((prevState) => [
            ...prevState,
            { id: index, src: e.target.result },
          ]);
          props?.setEffectFile(file);
          props.setFilterFileControl({
            showError: false,
            errorMessage: "",
            showImage: true,
            imageData: { id: index, src: e.target.result },
          });
        };
        reader.readAsDataURL(file);
        return file;
      } else {
        props.setFilterFileControl({
          showError: true,
          errorMessage: "Only Image file is accept.",
          // errorMessage: "Only .arscene .videofx file is accept.",
          showImage: false,
          imageData: {},
        });
      }
    });
  }, []);

  useEffect(() => {
    props.setThumbanailControl({
      showError: false,
      errorMessage: "",
      showImage: true,
      imageData: { src: props?.effectFormData?.thumbnailUrl },
    });
    props.setFilterFileControl({
      showError: false,
      errorMessage: "",
      showImage: true,
      imageData: { src: props?.effectFormData?.filterUrl },
    });
}, [props?.show=== true]);

  const clickToRemove = () => {
    props?.setThumbnailFile(null)
    props.setThumbanailControl({
      showError: true,
      errorMessage: "",
      showImage: false,
      imageData: null,
    });
  }


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.effectFormData?.id ? "Update Effect" : "Add Effect"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <Form.Control
              value={props.effectFormData?.name}
              onChange={(e) =>
                props.setEffectFormData({
                  ...props?.effectFormData,
                  name: e.target.value,
                })
              }
              placeholder="Enter Display Name"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
            {props?.effectFormData?.name ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">Name is required*</label>
              </>
            )}
          </div>
          <div className="col-md-6">
            <Form.Control
              maxLength={4}
              value={props.effectFormData?.duration}
              onChange={handleInputChange}
              placeholder="Duration(Min) *"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
            {props?.effectFormData?.duration ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">
                  Duration is required*
                </label>
              </>
            )}
          </div>
          <div className="col-md-6 mt-4 custom-select-height">
            <Form.Group controlId="exampleForm.ControlInput1">
              <h4>Widget.*</h4>
              <Dropdown
                options={props?.widgetKeyValueData}
                onChange={(e) =>
                  props.setEffectFormData({
                    ...props?.effectFormData,
                    widgetId: e.value,
                  })
                }
                value={props?.defaultWidgetOption}
                placeholder="Select Widget"
              />
              {props?.effectFormData?.widgetId === "" ? (
                <label className="custom-error-label">
                  Widget is required*
                </label>
              ) : (
                <></>
              )}
            </Form.Group>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-12">
            <Form.Control
              value={props.effectFormData?.description}
              onChange={(e) =>
                props.setEffectFormData({
                  ...props?.effectFormData,
                  description: e.target.value,
                })
              }
              as="textarea"
              className="mt-3"
              placeholder="Discription"
            />
            {props?.effectFormData?.description ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">
                  Description is required*
                </label>
              </>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <TextField
              className="custom-tag-input"
              label="Tags"
              variant="outlined"
              value={props.inputValue}
              onChange={handleInputChangeTag}
              onKeyDown={handleInputKeyDown}
            />
            <div className="custom-tag-chip-overflow">
              {props?.tags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={handleChipDelete(tag)}
                  style={{ margin: "4px" }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
          Upload Thumbnail
            <DropBox
              onDrop={onDrop}
              acceptType="image/*"
              isImage={true}
              thumbanailControl={props.thumbanailControl}
              setThumbanailControl={props.setThumbanailControl}
              clickToRemove={clickToRemove}
            />
            {props.thumbanailControl?.showError ? (
              props.thumbanailControl?.errorMessage
            ) : (
              <></>
            )}
            {props?.effectFormData?.id ? (
              <></>
            ) : props?.thumbnailFile ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">
                Thumbnail is required*
                </label>
              </>
            )}
          </div>
          <div className="col-md-6">
            Upload Effect
            <DropBox
              onDrop={onDropFile}
              acceptType="image/*"
              // acceptType="font/ttf"
              isImage={false}
              filterFileControl={props.filterFileControl}
              setFilterFileControl={props.setFilterFileControl}
            />
            {props.filterFileControl?.showError ? (
              <label className="custom-error-label" style={{ color: "red" }}>
                {props.filterFileControl?.errorMessage}
              </label>
            ) : (
              <></>
            )}
            {props?.effectFormData?.id ? (
              <></>
            ) : props?.effectFile ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">
                  EffectFile is required*
                </label>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};
