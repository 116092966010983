import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import * as XLSX from 'xlsx'
import Form from 'react-bootstrap/Form'
import { showDangerToast, showToast } from '../../constants/toaster'
import { useDispatch, useSelector } from 'react-redux'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { Dropdown, InputGroup } from 'react-bootstrap'

export const ImportDistrict = props => {
  const [data, setData] = useState([])
  const dispatch = useDispatch()
  const userData = useSelector(state => state?.userData)

  useEffect(() => {}, [])

  const handleFileChange = e => {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.onload = event => {
      const binaryStr = event.target.result
      const workbook = XLSX.read(binaryStr, { type: 'binary' })

      // Assuming you want to read the first sheet
      const sheetName = workbook.SheetNames[props?.sheetIndex]
      const worksheet = workbook.Sheets[sheetName]

      // Convert to JSON
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      console.log(jsonData)
      let allCountry = []
      jsonData?.map(data => {
        allCountry.push(data[0])
      })
      setData(allCountry)
    }

    reader.readAsBinaryString(file)
  }

  const handleImportDistrict = async () => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        stateId: props?.stateId,
        dataList: data
      }
      let path = urls.importDistrict
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      console.log('res', response)
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        props.onHide()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          Import District
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input type='file' onChange={handleFileChange} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={props.onHide}>
          Cancel
        </Button>
        <Button variant='primary' onClick={handleImportDistrict}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
