import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const AddPackage = props => {
  const [error, setError] = useState({
    productId: false,
    storeType: false,
    amount: false,
    chips: false,
    description: false,
  });

  const handleChange = (event, newAlignment) => {
    if (newAlignment) {
      props.setPackageFormData({ storeType: newAlignment });
    }
  };

  const handleSubmit = () => {
    const { productId, storeType, amount, chips, description } = props.packageFormData;

    let hasError = false;
    const newErrorState = {
      productId: false,
      storeType: false,
      amount: false,
      chips: false,
      description: false,
    };

    if (!productId) {
      newErrorState.productId = true;
      hasError = true;
    }

    if (!storeType) {
      newErrorState.storeType = true;
      hasError = true;
    }

    if (!amount) {
      newErrorState.amount = true;
      hasError = true;
    }

    if (!chips) {
      newErrorState.chips = true;
      hasError = true;
    }

    if (!description) {
      newErrorState.description = true;
      hasError = true;
    }

    if (hasError) {
      setError(newErrorState);
    } else {
      setError(newErrorState); // Clear errors
      props.onSubmit();
    }
  };

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.packageFormData?.id ? 'Update Package' : 'Add Package'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-4'>
            <Form.Group controlId='productId'>
              <h4>Package ID*</h4>
              <Form.Control
                disabled={props?.packageFormData?.id}
                type='text'
                maxLength={20}
                placeholder='Package ID'
                value={props.packageFormData.productId}
                onChange={e => props.setPackageFormData({ productId: e.target.value })}
              />
              {error.productId && (
                <label className='custom-error-label'>
                  Package ID is required*
                </label>
              )}
            </Form.Group>
          </div>
          <div className='col-md-2'>
            <Form.Group controlId='amount'>
              <h4>Amount*</h4>
              <Form.Control
                type='text'
                maxLength={8}
                placeholder='Amount'
                value={props.packageFormData.amount}
                onChange={e => {
                  const numericRegex = /^[0-9\b]+$/;
                  if (numericRegex.test(e.target.value) || e.target.value === '') {
                    props.setPackageFormData({ amount: e.target.value });
                  }
                }}
              />
              {error.amount && (
                <label className='custom-error-label'>
                  Amount is required*
                </label>
              )}
            </Form.Group>
          </div>
          <div className='col-md-2'>
            <Form.Group controlId='chips'>
              <h4>Chips*</h4>
              <Form.Control
                type='text'
                maxLength={8}
                placeholder='Chips'
                value={props.packageFormData.chips}
                onChange={e => {
                  const numericRegex = /^[0-9\b]+$/;
                  if (numericRegex.test(e.target.value) || e.target.value === '') {
                    props.setPackageFormData({ chips: e.target.value });
                  }
                }}
              />
              {error.chips && (
                <label className='custom-error-label'>
                  Chips are required*
                </label>
              )}
            </Form.Group>
          </div>
          <div className='col-md-4'>
            <Form.Group controlId='storeType'>
              <h4>Store*</h4>
              <ToggleButtonGroup
                color="primary"
                value={props?.packageFormData?.storeType}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="android" disabled={props?.packageFormData?.id}>Android</ToggleButton>
                <ToggleButton value="ios" disabled={props?.packageFormData?.id}>iOS</ToggleButton>
              </ToggleButtonGroup>
              {error.storeType && (
                <label className='custom-error-label'>
                  Store type is required*
                </label>
              )}
            </Form.Group>
          </div>
          <div className='col-md-12'>
            <Form.Group controlId='description'>
              <h4>Description*</h4>
              <Form.Control
                type='text'
                maxLength={120}
                placeholder='Description'
                value={props.packageFormData.description}
                onChange={e => props.setPackageFormData({ description: e.target.value })}
              />
              {error.description && (
                <label className='custom-error-label'>
                  Description is required*
                </label>
              )}
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};
