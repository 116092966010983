import $ from "jquery";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useCallback, useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DropBox from "../../custom/DropBox";
import ShowImage from "../../custom/ShowImage";
import { showDangerToast } from "../../constants/toaster";
import Dropdown from "react-bootstrap/Dropdown";
const imageFileTypes = ["image/png", "image/gif", "image/jpeg"];
const otherFileTypes = ["image/png", "image/gif", "image/jpeg"];
// const otherFileTypes = ['.arscene', '.videofx']

export const AddFontStyle = (props) => {
  const clickToRemove = () => {
    props?.setCoverImage(null)
    props.setThumbanailControl({
      showError: true,
      errorMessage: "",
      showImage: false,
      imageData: null,
    });
  }

  const [inputValue, setInputValue] = useState("");

  const handleInputChangeTag = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      props?.setTags([...props?.tags, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleChipDelete = (tagToDelete) => () => {
    props?.setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  useEffect(() => { }, []);
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);


  useEffect(() => {
    if (props?.show) {
      if (props?.fontStyleFormData?.id && props?.coverImage) {
        props.setThumbanailControl({
          showError: false,
          errorMessage: "",
          showImage: true,
          imageData: { src: props?.fontStyleFormData?.image },
        });
        props.setFilterFileControl({
          showError: false,
          errorMessage: "",
          showImage: true,
          imageData: { src: props?.fontStyleFormData?.remotePackage },
        });
      }
    }

  }, [props]);

  const handleSubmit = () => {
    if (props?.fontStyleFormData?.id) {
      if (
        props?.fontStyleFormData?.name &&
        props?.fontStyleFormData?.description
      ) {
        props.setFontStyleFormData({
          ...props?.fontStyleFormData,
          type: props?.statusDropdownText,
        });
        props.onSubmit();
      } else {
        showDangerToast("Please fill all required Fields.");
      }
    } else {
      if (
        props?.fontStyleFormData?.name &&
        props?.fontStyleFormData?.description &&
        props?.coverImage &&
        props?.remotePackageFile
      ) {
        props.setFontStyleFormData({
          ...props?.fontStyleFormData,
          type: props?.statusDropdownText,
        });
        props.onSubmit();
      } else {
        showDangerToast("Please fill all required Fields.");
      }
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      if (imageFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = function (e) {
          setImages((prevState) => [
            ...prevState,
            { id: index, src: e.target.result },
          ]);
          props?.setCoverImage(file);
          props.setThumbanailControl({
            showError: false,
            errorMessage: "",
            showImage: true,
            imageData: { id: index, src: e.target.result },
          });
        };
        reader.readAsDataURL(file);

        return file;
      } else {
        props.setThumbanailControl({
          showError: true,
          errorMessage: "Only Image file is accept. ",
          showImage: false,
          imageData: [],
        });
      }
    });
  }, []);

  const onDropFile = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      if (otherFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = function (e) {
          setFiles((prevState) => [
            ...prevState,
            { id: index, src: e.target.result },
          ]);
          props?.setRemotePackageFile(file);
          props.setFilterFileControl({
            showError: false,
            errorMessage: "",
            showImage: true,
            imageData: { id: index, src: e.target.result },
          });
        };
        reader.readAsDataURL(file);
        return file;
      } else {
        props.setFilterFileControl({
          showError: true,
          errorMessage: "Only .arscene .videofx file is accept.",
          showImage: false,
          imageData: {},
        });
      }
    });
  }, []);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.fontStyleFormData?.id
            ? "Update " + props?.statusDropdownText
            : "Add " + props?.statusDropdownText}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <Form.Control
              value={props.fontStyleFormData?.name}
              onChange={(e) =>
                props.setFontStyleFormData({
                  ...props?.fontStyleFormData,
                  name: e.target.value,
                })
              }
              placeholder="Enter Asset Name"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
            {props?.fontStyleFormData?.name ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">Name is required*</label>
              </>
            )}
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-md-12">
            <Form.Control
              value={props.fontStyleFormData?.description}
              onChange={(e) =>
                props.setFontStyleFormData({
                  ...props?.fontStyleFormData,
                  description: e.target.value,
                })
              }
              as="textarea"
              className="mt-3"
              placeholder="Discription"
              maxLength={150}
            />
            {props?.fontStyleFormData?.description ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">
                  Description is required*
                </label>
              </>
            )}
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-12">
            <TextField
              className="custom-tag-input"
              label="Tags"
              variant="outlined"
              value={inputValue}
              onChange={handleInputChangeTag}
              onKeyDown={handleInputKeyDown}
            />
            <div className="custom-tag-chip-overflow">
              {props?.tags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={handleChipDelete(tag)}
                  style={{ margin: "4px" }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            Cover Image
            <DropBox
              onDrop={onDrop}
              acceptType="image/*"
              isImage={true}
              thumbanailControl={props.thumbanailControl}
              setThumbanailControl={props.setThumbanailControl}
              clickToRemove={clickToRemove}
            />
            {props.thumbanailControl?.showError ? (
              props.thumbanailControl?.errorMessage
            ) : (
              <></>
            )}
            {props?.fontStyleFormData?.id ? (
              <></>
            ) : props?.coverImage ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">
                  Cover Image is required*
                </label>
              </>
            )}
          </div>
          <div className="col-md-6">
            Remote Package{" "}
            {props?.statusDropdownText === "Font"
              ? " (.ttf)"
              : " (.captionstyle)"}{" "}
            *
            <DropBox
              onDrop={onDropFile}
              acceptType=".ttf"
              isImage={false}
              filterFileControl={props.filterFileControl}
              setFilterFileControl={props.setFilterFileControl}

            />
            {props.filterFileControl?.showError ? (
              <label className="custom-error-label" style={{ color: "red" }}>
                {props.filterFileControl?.errorMessage}
              </label>
            ) : (
              <></>
            )}
            {props?.fontStyleFormData?.id ? (
              <></>
            ) : props?.remotePackageFile ? (
              <></>
            ) : (
              <>
                <label className="custom-error-label">
                  Remote Package is required*
                </label>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};
