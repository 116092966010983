import NoDataImg from "../../../assets/img/no-data.gif";
import Pagination from "@mui/material/Pagination";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from 'uuid'
import Dropdown from "react-bootstrap/Dropdown";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import dayjs, { Dayjs } from "dayjs";
import NavBar from "../../structure/NavBar";
import Form from "react-bootstrap/Form";
import SearchIcon from "@mui/icons-material/Search";
import InputGroup from "react-bootstrap/InputGroup";
import AddIcon from "@mui/icons-material/Add";
import { NavBarTop } from "../../structure/NavBarTop";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useCallback, useEffect, useReducer, useState } from "react";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import Image from "../../custom/Image";
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import { AddQuestion } from './AddQuestion'
import Utils from '../../constants/Utils'
import ViewFaq from "./ViewFaq";
import SequenceEdit from "./SequenceEdit";
import InsufficientPermission from "../../custom/InSufficientPermission";
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const FaqList = props => {
  const { formatDate } = Utils();
  const [callAPi, setCallAPi] = useState('')
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [addNewQuestion, setAddNewQuestion] = useState(false)
  const [value, setValue] = useState(dayjs())
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const dispatch = useDispatch()
  const [datalist, setDatalist] = useState([])
  const [defaultWidgetOption, setDefaultWidgetOption] = useState(null)
  const [widgetKeyValueData, setWidgetKeyValueData] = useState([])
  const [showAddFilter, setShowAddFilter] = useState(false)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const userData = useSelector(state => state?.userData)
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
  });
  const [showViewFaq, setShowViewFaq] = useState(false);
  const [faqFormData, setFaqFormData] = useState({
    id: "",
    question: "",
    description: "",
    sequence: 0,
  });
  const [sequenceUpdate, setSequenceUpdate] = useState({
    id: "",
    seq: 0,
  });

  const [enableSequenceEdit, setEnableSequenceEdit] = useState(false)

  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10,
    });
    setCallAPi(uuidv4())
  };
  const handleFaqView = id => {
    getFaqWithId(id)
  }

  const getFaqWithId = async id => {
    try {
      dispatch(uplodateLoading(true))

      let path = urls.faqById + '/' + id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setFaqFormData(prevState => {
          return {
            ...prevState,
            ...response.data
          }
        })
        setShowViewFaq(true)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }


  const handleEditFaq = (item) => {
    setAddNewQuestion(true);
    setFaqFormData(item);
  };

  useEffect(() => {
    dispatch(updateTopHeader("FAQ Manager"));
  }, []);

  useEffect(() => {
    if(viewPermission){
      getFaqList();
    }
  }, [callAPi]);

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.changeFaqStatus + "/" + id;
      let res = await ApiCaller.put({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        showToast(response?.message);
        getFaqList();
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const getFaqList = async () => {
    let startDateObject
    let endDateObject
    if (startDate?.toDate()) {
      let newDate = new Date(startDate?.toDate())
      newDate.setDate(newDate.getDate() + 1)
      startDateObject = newDate
    }
    if (endDate?.toDate()) {
      let newDate = new Date(endDate?.toDate())
      newDate.setDate(newDate.getDate() + 1)
      endDateObject = newDate
    }
    try {
      dispatch(uplodateLoading(true));
      let postJson = {
        searchText: searchText?.trim(),
        pageNumber: pagerecord?.pageNumber - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        active:
          statusDropdownText === "Active"
            ? true
            : statusDropdownText === "In Active"
              ? false
              : null,
        startDate: startDateObject !== null ? startDateObject : '',
        endDate: endDateObject !== null ? endDateObject : ''
      };
      let path = urls.faqList;
      let res = await ApiCaller.post(postJson, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        setDatalist(response?.data?.response?.items);
        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === "EDIT") {
            setEdit(module.isEnable);
          } else if (module.permissionName === "ACTIVE") {
            setActive(module.isEnable);
          } else if (module.permissionName === "DELETE") {
            setDeletePermission(module.isEnable);
          } else if (module.permissionName === "VIEW") {
            setView(module.isEnable);
          }
        });
        setPagerecord({
          totalItems: response?.data?.response?.totalItems,
          totalPages: response?.data?.response?.totalPages,
          pageNumber: pagerecord?.pageNumber,
          pageSize: 10,
        });
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const handleFilterClick = useCallback(() => {
    setPage(1)
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  }, [searchText, statusDropdownText, startDate, endDate])

  const handleResetFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setStatusDropdownText("Select Status");
    setSearchText("");
    setPage(1)
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  };

  const handleAddQuestion = async () => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.addFaq;
      let res = await ApiCaller.post(faqFormData, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        showToast(response?.message);
        setAddNewQuestion(false);
        getFaqList();
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const handleAddQuestionClick = () => {
    setAddNewQuestion(true);
    setFaqFormData({
      id: "",
      question: "",
      description: "",
      sequence: 0,
    })
  }

  const userPermisiions = useSelector(state => state?.permission)
  let currentModulePermission;
  let viewPermission = true;
  let editPermission = true;
  let deletePermissionVal = true;
  let activePermission = true;

  if(userPermisiions?.modules.length>0){
    currentModulePermission = userPermisiions.modules.find((element)=>{
      if(element.moduleId==='27'){
         return element
      }
    })?.permissions
     viewPermission = currentModulePermission.find((element)=>{
      if(element.name==='VIEW'){
        return element
      }
    }).isEnable
     editPermission = currentModulePermission.find((element)=>{
      if(element.name==='EDIT'){
        return element
      }
    }).isEnable
    deletePermissionVal = currentModulePermission.find((element)=>{
      if(element.name==='DELETE'){
        return element
      }
    }).isEnable
    activePermission = currentModulePermission.find((element)=>{
      if(element.name==='ACTIVE'){
        return element
      }
    }).isEnable
   }


  const handleDoneSq = async () => {
    try {
      dispatch(uplodateLoading(true));
      let path =
        urls.updateSequence +
        "/" +
        sequenceUpdate?.id +
        "/" +
        sequenceUpdate?.seq;
      let res = await ApiCaller.get({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        getFaqList();
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  return (
    <>
      <>
        <NavBar />
        <div className="main-content">
          <NavBarTop />
          <div className="header">
            <div className="logo">
              <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8"></div>
            </div>
          </div>
    {
      viewPermission ? (
        <>
                  {addNewQuestion ? (
            <AddQuestion
              faqFormData={faqFormData}
              setFaqFormData={setFaqFormData}
              handleAddQuestion={handleAddQuestion}
              setAddNewQuestion={setAddNewQuestion}
            />
          ) : (
            <>
              <div className="container-fluid mt--7">
                <div className="row">
                  <div className="col text-left">
                    <div className="card shadow">
                      <div className="card-header border-0">
                        <div className="row align-items-center text-left">
                          <div className="col">
                            <h3 className="mb-0">FAQ Manager</h3>
                          </div>
                          <div className="col text-right">
                            {userData.admin ? (
                              <a
                                className="btn btn-sm btn-primary text-white"
                                onClick={() => handleAddQuestionClick()}
                              >
                                <AddIcon />
                                Add Question
                              </a>
                            ) : editPermission ? (
                              <a
                                className="btn btn-sm btn-primary text-white"
                                onClick={() => handleAddQuestionClick()}
                              >
                                <AddIcon />
                                Add Question
                              </a>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card-header border-0">
                        <div className="row align-items-center text-left">
                          <div className="col-md-3">
                            <InputGroup
                              className="mb-2"
                              style={{ borderRadius: "10px" }}
                            >
                              <Form.Control
                                placeholder="Search by FAQ QUESTION"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                aria-label="Amount (to the nearest dollar)"
                              />
                            </InputGroup>
                          </div>
                          <div className="col-md-2">
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                style={{
                                  backgroundColor: "white",
                                  color: "black",
                                  border: "1px solid #cad1d7",
                                  boxShadow: "none",
                                  transform: "none",
                                }}
                                id="dropdown-basic"
                              >
                                {statusDropdownText}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>

                                    setStatusDropdownText("Select Status")
                                  }
                                >
                                  Select Status
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    setStatusDropdownText("Active")
                                  }
                                >
                                  Active
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    setStatusDropdownText("In Active")
                                  }
                                >
                                  In Active
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="col-md-6 mb-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker", "DatePicker"]}
                              >
                                <DatePicker
                                  disableFuture
                                  className="custom-date"
                                  label="From Date"
                                  value={startDate}
                                  onChange={(newValue) =>
                                    setStartDate(newValue)
                                  }
                                  maxDate={endDate}
                                />
                                <DatePicker
                                  disableFuture
                                  label="To Date"
                                  value={endDate}
                                  onChange={(newValue) => setEndDate(newValue)}
                                  minDate={startDate}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                          <div className="col-md-3 mb-2">
                            <Button
                              variant="contained"
                              className="custom-filter-btn"
                              onClick={handleFilterClick}
                            >
                              Filter
                            </Button>
                            <Button
                              variant="contained"
                              className="ml-2 custom-filter-btn"
                              onClick={handleResetFilter}
                            >
                              Reset
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        {datalist?.length > 0 ? (
                          <>
                            <table className="table align-items-center table-flush">
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">S/No.</th>
                                  <th scope="col">FAQ Question</th>
                                  <th scope="col">Description</th>
                                  <th scope="col">Date of Creation</th>
                                  <th scope="col">Sequence</th>
                                  <th scope="col">Status</th>
                                  <th scope="col" >Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {datalist?.map((data, i) => {
                                  const startIndex = (pagerecord.pageNumber - 1) * pagerecord.pageSize;
                                  return (
                                    <>
                                      <tr key={i}>
                                        <td>{startIndex + i + 1}</td>
                                        <td
                                          className="custom-td-width"
                                          dangerouslySetInnerHTML={{
                                            __html: data?.question,
                                          }}
                                        ></td>
                                        <td
                                          className="custom-td-width"
                                          dangerouslySetInnerHTML={{
                                            __html: data?.description,
                                          }}
                                        ></td>
                                        <td>
                                          {formatDate(data?.createdAt)}
                                        </td>
                                        <SequenceEdit data={data} handleDoneSq={handleDoneSq} setSequenceUpdate={setSequenceUpdate} enableSequenceEdit={enableSequenceEdit}
                                          setEnableSequenceEdit={(t) => setEnableSequenceEdit(t)}
                                        />
                                        <td>
                                          {userData.admin ? (
                                            <Switch
                                              {...label}
                                              checked={data?.active}
                                              onChange={(e) =>
                                                handleStatusChange(e, data?.id)
                                              }
                                              size="small"
                                            />
                                          ) : viewPermission ? (
                                            <Switch
                                              {...label}
                                              disabled={!activePermission}
                                              checked={data?.active}
                                              onChange={(e) =>
                                                handleStatusChange(e, data?.id)
                                              }
                                              size="small"
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                        <td className="text-right">
                                          <div className="dropdown">
                                            <a
                                              className="btn btn-sm btn-icon-only text-light"
                                              href="#"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <i className="fas fa-ellipsis-v" />
                                            </a>
                                            <div
                                              className="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                                              x-placement="bottom-end"
                                              style={{
                                                position: "absolute",
                                                willChange: "transform",
                                                top: "0px",
                                                left: "0px",
                                                transform:
                                                  "translate3d(32px, 32px, 0px)",
                                              }}
                                            >
                                              {userData.admin ? (
                                                <a
                                                  className="dropdown-item cursor"
                                                  onClick={() =>
                                                    handleEditFaq(data)
                                                  }
                                                >
                                                  Edit
                                                </a>
                                              ) : editPermission ? (
                                                <a
                                                  className="dropdown-item cursor"
                                                  onClick={() =>
                                                    handleEditFaq(data)
                                                  }
                                                >
                                                  Edit
                                                </a>
                                              ) : (
                                                <></>
                                              )}

                                              <a className="dropdown-item cursor"
                                                onClick={() =>
                                                  handleFaqView(data.id)
                                                }
                                              >
                                                View
                                              </a>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          </>
                        ) : (
                          <>
                            <div className="text-center">
                              <img width={"25%"} src={NoDataImg}></img>
                              <br />
                              <label style={{ fontWeight: 'bold' }}>
                                {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                      {datalist.length ? (
                        <>
                          <div className="card-footer py-4">
                            <Box display="flex" justifyContent="right" alignItems='center'>
                              <span>Total record: {pagerecord?.totalItems}</span>
                              <Pagination
                                className="custom-pagination-class"
                                count={pagerecord?.totalPages}
                                page={page}
                                onChange={handleChange}
                                color="secondary"
                              />
                            </Box>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                {/* <Footer /> */}
              </div>
            </>
          )}

          <ViewFaq
            show={showViewFaq}
            faqFormData={faqFormData}
            onHide={() => setShowViewFaq(false)}
          />
        </>
      ) : (<InsufficientPermission/>)
    }
        </div>
      </>
    </>
  );
};
