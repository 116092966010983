import { useEffect, useState } from "react"
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { showDangerToast } from '../../constants/toaster'
import { useDispatch, useSelector } from "react-redux"
import { uplodateLoading } from "../../../redux/features/common/commonSlice"
import urls from "../../constants/apiurl"
import ApiCaller from "../../constants/ApiCaller"
import { Dropdown, InputGroup } from "react-bootstrap"

export const AddState = (props) => {
    const [countryDropdownText, setCountryDropdownText] = useState({
        id: "",
        lable: "Select Country"
    });
    const [isValidName, setIsValidName] = useState(true);
    const [isValidParentId, setIsValidParentId] = useState(true);
    const dispatch = useDispatch()
    const handleSubmit = () => {
        if (props?.stateFormData?.name === '' || props?.stateFormData?.parentId === '') {
            showDangerToast('Please fill all required fields');
        } else {
            const isSuccess = props?.onSubmit()
          if(isSuccess){
            setCountryDropdownText({
                id: "",
                lable: "Select Country"
            })
            props.setStateFormData({
                name: '',
                parentId: ''
            })
          }
        }
    };

    useEffect(() => {
        if (props?.stateFormData?.parentId) {
            for (const data of props?.countryCodeList) {
                if (data?.id === props?.stateFormData?.parentId) {
                    setCountryDropdownText({
                        id: data?.id,
                        lable: data?.name
                    })
                }
            }
        }
    }, [props])

    const handleInputChange = (e, inputType) => {
        const value = e.target.value;
        // Simple validation: check if the input is not empty
        const isValidInput = value.trim() !== '';
        // Update the state based on validation result
        if (inputType === 'name') {
            setIsValidName(isValidInput);
        } else if (inputType === 'parentId') {
            setIsValidParentId(isValidInput);
        }
        props.setStateFormData({
            ...props?.stateFormData,
            name: value
        })
    };

    return (
        <Modal
            {...props}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter'>
                    {props?.stateFormData?.id ? 'Update State' : 'Add State'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-md-6'>
                        <Form.Group controlId='stateNameInput'>
                            <h4>Name*</h4>
                            <Form.Control
                                type='text'
                                placeholder='Enter Name'
                                value={props.stateFormData.name}
                                onChange={e => handleInputChange(e, 'name')}
                            />
                            {props?.stateFormData?.name === '' ? (
                                <label className='custom-error-label'>Name is required*</label>
                            ) : (
                                !isValidName && (
                                    <label className='custom-error-label'>
                                        State name is required.
                                    </label>
                                )
                            )}
                        </Form.Group>
                    </div>
                    <div className='col-md-6'>
                        <Form.Group controlId='parentIdInput'>
                            <h4>Country*</h4>
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant='success'
                                    style={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        border: '1px solid #cad1d7',
                                        boxShadow: 'none',
                                        transform: 'none'
                                    }}
                                    id='dropdown-basic'
                                >
                                    {countryDropdownText?.lable}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {props?.countryCodeList?.map((item, i) => {
                                        return <><Dropdown.Item eventKey={item?.id}
                                            onClick={() => {
                                                setCountryDropdownText({
                                                    id: item?.id,
                                                    lable: item?.name
                                                })
                                                props.setStateFormData({
                                                    ...props?.stateFormData,
                                                    'parentId': item?.id
                                                })
                                            }
                                            }
                                        >
                                            {item?.name}
                                        </Dropdown.Item></>
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='outline-secondary' onClick={()=>{
                    setCountryDropdownText({
                        id: "",
                        lable: "Select Country"
                    })
                    props.onHide();
                    }}>Cancel</Button>
                <Button variant='primary' onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </Modal>
    );


}