import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import NoDataImg from '../../../assets/img/no-data.gif'
import { useState } from 'react'
import { showDangerToast, showToast } from '../../constants/toaster'
import { useDispatch, useSelector } from 'react-redux'
import Utils from '../../constants/Utils'
import { FullImageView } from '../../custom/FullImageView'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'

const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const InfluencerAdsTable = props => {
  const { formatDate } = Utils();

  const [imageUrl, setImageUrl] = useState(false)
  const userData = useSelector(state => state?.userData)
  const [fullSizePreview, setFullSizePreview] = useState(false)
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [showEditBead, setShowEditBead] = useState(false)
  const [userId, setUserId] = useState()
  const dispatch = useDispatch()
  const [showViewUserDetails, setShowViewUserDetails] = useState(false)
  const handleEditBeads = item => {
    setShowEditBead(true)
  }

  const submitEditBeads = () => {
    setShowEditBead(false)
    showToast('Beads Update Successfully.')
  }

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeAdsStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        props?.getAdsList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const serialNumber = i => {
    return i + 1 + (props?.page - 1) * props?.pagerecord.pageSize || 10
  }

  return (
    <>
      <div className='table-responsive'>
        {props?.datalist?.length ? (
          <>
            <table className='table align-items-center table-flush'>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>S/No.</th>
                  <th scope='col'>User Name</th>
                  <th scope='col'>Shop Link</th>
                  <th scope='col'>Created At</th>
                  <th scope='col'>Images</th>
                  <th scope='col'>Status</th>
                </tr>
              </thead>
              <tbody>
                {props?.datalist &&
                  props?.datalist?.map((data, i) => {
                    return (
                      <>
                        <tr>
                          <td>{serialNumber(i)}</td>
                          <td>{data?.userName}</td>
                          <td>
                            <a href={data?.shopLink} target='_blank' rel="noreferrer">
                              Link
                            </a>
                          </td>
                          <td>{formatDate(data?.createdAt)}</td>
                          <td>
                            <div className='row'>
                              <div className='col-md-2'>
                                {data?.shopImages &&
                                  data?.shopImages?.map((shopImg, i) => {
                                    return (
                                      <>
                                        <img
                                          src={shopImg}
                                          style={{
                                            width: '25%',
                                            marginRight: '15px',
                                            borderRadius: '5px',
                                            cursor: 'pointer'
                                          }}
                                          onClick={() => {
                                            setFullSizePreview(!fullSizePreview)
                                            setImageUrl(shopImg)
                                          }}
                                        ></img>
                                      </>
                                    )
                                  })}
                              </div>
                            </div>
                          </td>
                          <td>
                            <Switch
                              {...label}
                              checked={data?.active}
                              disabled={!props.activePermission}
                              onChange={e => handleStatusChange(e, data?.id)}
                              size='small'
                            />
                          </td>
                        </tr>
                      </>
                    )
                  })}
              </tbody>
            </table>
          </>
        ) : (
          <>
            <div className='text-center'>
              <img width={'25%'} src={NoDataImg}></img>
              <br />
              <label style={{ fontWeight: 'bold' }}>
                {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
              </label>
            </div>
          </>
        )}
      </div>
      {props?.datalist && props?.datalist.length > 0 ? (
        <div className='card-footer py-4'>
          <Box display='flex' justifyContent='right' alignItems='center'>
            <span>Total record: {props?.pagerecord?.totalItems}</span>
            <Pagination
              className='custom-pagination-class'
              count={props?.pagerecord?.totalPages}
              page={props?.page}
              onChange={props?.handleChange}
              color='secondary'
            />
          </Box>
        </div>
      ) : (
        <></>
      )}
      <FullImageView
        src={imageUrl}
        show={fullSizePreview}
        onHide={() => setFullSizePreview(false)}
      />
    </>
  )
}
