import Checkbox from '@mui/material/Checkbox'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast } from '../../constants/toaster'
import NoDataImg from '../../../assets/img/no-data.gif'
import { Box, Pagination } from '@mui/material'
import Utils from '../../constants/Utils'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } }
export const BeadsTransactionTable = props => {
  const { formatDate } = Utils();
  const [callAPi, setCallAPi] = useState('')
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [datalist, setDatalist] = useState([])
  const userData = useSelector(state => state?.userData)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const dispatch = useDispatch()
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
    setCallAPi(uuidv4())
  }

  useEffect(() => {
    setPage(1)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: 1,
      pageSize: 10
    })
    setCallAPi(uuidv4())
  }, [props?.callAPiCallBack])

  
  useEffect(() => {
    getBeadsTransactionList()
  }, [callAPi])

  const getBeadsTransactionList = async () => {
    try {
      dispatch(uplodateLoading(true))
      let startDateObject;
      let endDateObject;
      if (props?.startDate?.toDate()) {
        // Create a new Date object based on startDate
        let newDate = new Date(props?.startDate?.toDate());

        // Add one day
        newDate.setDate(newDate.getDate() + 1);
        startDateObject = newDate;
      }
      if (props?.endDate?.toDate()) {
        // Create a new Date object based on startDate
        let newDate = new Date(props?.endDate?.toDate());

        // Add one day
        newDate.setDate(newDate.getDate() + 1);
        endDateObject = newDate;
      }

      let postJson = {
        searchTxt: "searchTextRef",
        page: pagerecord?.pageNumber - 1 || 0,
        limit: pagerecord.pageSize || 10,
        transactionType: props?.transactionType?.key,
        sort: sort_type,
        order: sort_order,
        startDate:
          startDateObject,
        endDate: endDateObject
      }
      let path = urls.beadsTransactionHistory
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.items)
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pagerecord?.pageNumber,
          pageSize: 10
        })
        // setDatalist(response?.data?.response?.items)
        // response?.data?.permissionList.forEach((module, moduleIndex) => {
        //   if (module.permissionName === 'EDIT') {
        //     setEdit(module.isEnable)
        //   } else if (module.permissionName === 'ACTIVE') {
        //     setActive(module.isEnable)
        //   } else if (module.permissionName === 'DELETE') {
        //     setDeletePermission(module.isEnable)
        //   } else if (module.permissionName === 'VIEW') {
        //     setView(module.isEnable)
        //   }
        // })
        // setPagerecord({
        //   totalItems: response?.data?.response?.totalItems,
        //   totalPages: response?.data?.response?.totalPages,
        //   pageNumber: pageNo,
        //   pageSize: 10
        // })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      {datalist && datalist?.length > 0 ? (
        <>
          <div className='table-responsive'>
            <table className='table align-items-center table-flush'>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>S/No.</th>
                  <th scope='col'>Full Name</th>
                  <th scope='col'>User Name</th>
                  <th scope='col'>User ID</th>
                  <th scope='col'>Transaction Type</th>
                  <th scope='col'>Transaction ID</th>
                  <th scope='col'>Badge</th>
                  <th scope='col'>Total Chips</th>
                  <th scope='col'>Transaction Date</th>
                </tr>
              </thead>
              <tbody>
                {datalist &&
                  datalist?.map((data, i) => {
                    const startIndex = (pagerecord.pageNumber - 1) * pagerecord.pageSize;
                    return (
                      <>
                        <tr key={i}>
                          <td>{startIndex + i + 1}</td>
                          <td>{data?.fullName}</td>
                          <td>{data?.userName}</td>
                          <td>{data?.userId}</td>
                          <td>{data?.transactionType}</td>
                          <td>{data?.txnId}</td>
                          <td>
                            {' '}
                            <Checkbox
                              {...label}
                              checked={data?.badgeVerified}
                              disabled
                            />
                          </td>
                          <td>{data?.totalChips}</td>
                          <td>{formatDate(data?.createdAt)}</td>
                        </tr>
                      </>
                    )
                  })}
              </tbody>
            </table>
          </div>
          {datalist && datalist.length > 0 ? (
            <>
              <div className='card-footer py-4'>
                <Box display='flex' justifyContent='right' alignItems='center'>
                  <span>Total record: {pagerecord?.totalItems}</span>
                  <Pagination
                    className='custom-pagination-class'
                    count={pagerecord?.totalPages}
                    page={page}
                    onChange={handleChange}
                    color='secondary'
                  />
                </Box>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <>
            <div className='text-center'>
              <img width={'25%'} src={NoDataImg}></img>
              <br />
              <label style={{ fontWeight: 'bold' }}>
                {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
              </label>
            </div>
          </>
        </>
      )}
    </>
  )
}
