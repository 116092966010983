import { toast } from "react-toastify";
import swal from "sweetalert";
const toastId = "my-toast";

const formatMessage = (message) => {
  // Trim message to avoid leading/trailing spaces
  let formattedMessage = message.trim();

  // Check if the first character is a lowercase letter, and if so, convert it to uppercase
  if (/[a-zA-Z]/.test(formattedMessage[0])) {
    formattedMessage = formattedMessage.charAt(0).toUpperCase() + formattedMessage.slice(1);
  }

  // Check if the message ends with a full stop, and add one if missing
  if (formattedMessage.slice(-1) !== '.') {
    formattedMessage += '.';
  }

  return formattedMessage;
};


const showToast = (message, id = toastId) => {
  if (id) {
    toast.dismiss(id);
  }
  const formattedMessage = formatMessage(message);
  toast.success(formattedMessage, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: id,
    className: "custom-toast", // Apply custom class
  });
};

const showDangerToast = (message, id = toastId) => {
  toast.dismiss();
  const formattedMessage = formatMessage(message);
  toast.error(formattedMessage, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: id,
    className: "custom-toast", // Apply custom class
  });
};

const showInfoToast = (message, id = toastId) => {
  toast.dismiss();
  const formattedMessage = formatMessage(message);
  toast.info(formattedMessage, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: id,
    className: "custom-toast", // Apply custom class
  });
};

const showAlert = (message, label = "error") => {
  swal(label, message, label);
};

const confirmAlert = (message, title, type, dangerMode, successMessage) => {
  return swal({
    title: title,
    text: message,
    icon: type,
    dangerMode: dangerMode,
  });
};

const SwalConfig = (message = "You want Change Status") => {
  return {
    title: "Are you sure?",
    text: message,
    icon: "warning",
    buttons: true,
    dangerMode: true,
  };
};

export {
  showToast,
  showDangerToast,
  showInfoToast,
  showAlert,
  confirmAlert,
  SwalConfig,
};
