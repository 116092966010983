import Dropdown from 'react-bootstrap/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import InputGroup from 'react-bootstrap/InputGroup'
import { v4 as uuidv4 } from 'uuid';
import * as React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import NavBar from '../../structure/NavBar'
import { NavBarTop } from '../../structure/NavBarTop'
import AddIcon from '@mui/icons-material/Add'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AmountTransactionFilter } from './AmountTransactionFilter'
import { BeadsTransactionFilter } from './BeadsTransactionFilter'
import { AmountTransactionTable } from './AmountTransactionTable'
import { BeadsTransactionTable } from './BeadsTransactionTable'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast } from '../../constants/toaster'
import InsufficientPermission from '../../custom/InSufficientPermission';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

export const TransactionList = () => {
  const [totalWithdrawCommission, setTotalWithdrawCommission] = useState(0)
  const userData = useSelector(state => state?.userData)
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [tabValue, setTabValue] = React.useState(0)
  const [callAPiCallBack, setCallAPiCallBack] = useState('')
  // const [startDate, setStartDate] = useState(dayjs())
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [transactionType, setTransactionType] = useState({
    value: 'All',
    key: 'ALL'
  })
  const handleChange = (event, newValue) => {
    setTabValue(newValue)
    setTransactionType({
      value: 'All',
      key: 'ALL'
    })
    setStartDate(null)
    setEndDate(null)
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(updateTopHeader('Transaction Manager'))
    // getTotalWithdrawCommission()
  })

  // Handle filter click
  const handleFilterClick = React.useCallback(() => {
    setCallAPiCallBack(uuidv4())
  }, [startDate, endDate, transactionType]);

  const handleReset = () => {
    setTransactionType({
      value: 'All',
      key: 'ALL'
    })
    setStartDate(null)
    setEndDate(null)
    setCallAPiCallBack(uuidv4())
  }


  const userPermisiions = useSelector(state => state?.permission)
  let currentModulePermission;
  let viewPermission = true;

  if (userPermisiions?.modules.length > 0) {
    currentModulePermission = userPermisiions.modules.find((element) => {
      if (element.moduleId === '10') {
        return element
      }
    })?.permissions
    viewPermission = currentModulePermission.find((element) => {
      if (element.name === 'VIEW') {
        return element
      }
    }).isEnable
  }

  const getTotalWithdrawCommission = async () => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        startDate: null,
        endDate: null
      }
      let path = urls.totalWithdrawCommission
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setTotalWithdrawCommission(response?.data)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div className='header'>
          <div className='logo'>
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
          </div>
        </div>
       {
        viewPermission ? ( <div className='container-fluid mt--7'>
          <div className='row'>
            <div className='col text-left'>
              <div className='card shadow'>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col'>
                      <h3 className='mb-0'>Transaction List</h3>
                    </div>
                    <div className='col text-right'>
                      {tabValue === 0 ? (
                        <b>Total Earning: {totalWithdrawCommission} INR</b>
                      ) : (
                        <b>Total Chips Earning: -</b>
                      )}
                    </div>
                  </div>
                  <hr className='mb-0' />
                </div>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col-md-6 mb-3'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={['DatePicker', 'DatePicker']}
                        >
                          <DatePicker
                            disableFuture
                            className='custom-date'
                            label='From Date'
                            value={startDate}
                            onChange={newValue => setStartDate(newValue)}
                            maxDate={endDate}
                          />
                          <DatePicker
                            disableFuture
                            label='To Date'
                            value={endDate}
                            onChange={newValue => setEndDate(newValue)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    {tabValue === 0 ? (
                      <AmountTransactionFilter transactionType={transactionType} setTransactionType={setTransactionType} />
                    ) : (
                      <BeadsTransactionFilter transactionType={transactionType} setTransactionType={setTransactionType} />
                    )}
                    <div
                      className='col-md-3 mb-2'
                      style={{ marginLeft: '100px' }}
                    >
                      <Button variant='contained' className='custom-filter-btn' onClick={() => handleFilterClick()}>
                        Filter
                      </Button>
                      <Button
                        variant='contained'
                        className='ml-2 custom-filter-btn'
                        onClick={() => handleReset()}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </div>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                      <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        indicatorColor='secondary'
                        textColor='inherit'
                        variant='fullWidth'
                        aria-label='full width tabs example'
                      >
                        <Tab label='Amount Transaction' {...a11yProps(0)} />
                        <Tab label='Chips Transaction' {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                  </div>
                </div>
                {tabValue === 0 ? (
                  <AmountTransactionTable callAPiCallBack={callAPiCallBack} tabValue={tabValue} startDate={startDate} endDate={endDate} transactionType={transactionType} />
                ) : (
                  <BeadsTransactionTable callAPiCallBack={callAPiCallBack} tabValue={tabValue} startDate={startDate} endDate={endDate} transactionType={transactionType} />
                )}
              </div>
            </div>
          </div>
        </div>) : (<InsufficientPermission/>)
       }
      </div>
    </>
  )
}
