import Switch from '@mui/material/Switch'
import Swal from 'sweetalert2'
import Pagination from '@mui/material/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import NoDataImg from '../../../assets/img/no-data.gif'
import Button from '@mui/material/Button'
import Box from "@mui/material/Box";
import dayjs, { Dayjs } from 'dayjs'
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { AddFilter } from './AddFilter'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import { AuthData } from '../../../auth/AuthWrapper'
import Utils from '../../constants/Utils'
import { Modal } from 'react-bootstrap'
import DescriptionModal from './DescriptionModal'
import ShowLongText from '../../custom/ShowLongText'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const FilterList = (props) => {
  const { formatDate } = Utils();
  const LOADING_ENABLE = useSelector((state) => state?.loading)
  const [tags, setTags] = useState([])
  const { logout } = AuthData()
  const [thumbnailImage, setThumbnailImage] = useState(null)
  const [filterFile, setFilterFile] = useState(null)
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [value, setValue] = useState(dayjs())
  const dispatch = useDispatch()
  const [datalist, setDatalist] = useState([])
  const [defaultWidgetOption, setDefaultWidgetOption] = useState(null)
  const [widgetKeyValueData, setWidgetKeyValueData] = useState([])
  const [showAddFilter, setShowAddFilter] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const userData = useSelector((state) => state?.userData)
  const userPermisiions = useSelector((state) => state?.permission)
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const [showFullDescription, setShowFullDescription] = useState(false)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [filterFormData, setFilterFormData] = useState({
    id: '',
    filterName: '',
    duration: '',
    widgetId: '',
    description: '',
    thumbnailUrl: '',
    filterUrl: ''
  })
  const [thumbanailControl, setThumbanailControl] = useState({
    showError: false,
    errorMessage: '',
    showImage: false,
    imageData: null
  })
  const [filterFileControl, setFilterFileControl] = useState({
    showError: false,
    errorMessage: '',
    showImage: false,
    imageData: null
  })

  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
  }

  const [selectedDescription, setSelectedDescription] = useState('')

  const handleShow = (description) => {
    setSelectedDescription(description)
    setShowFullDescription(true)
  }

  const handleClose = () => {
    setShowFullDescription(false)
  }

  const [showModal, setShowModal] = useState(false)
  const handleFilterClick = () => {
    getFilterList(searchText, startDate, endDate)
    console.log('Handle Filter Click')
  }

  const handleResetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setStatusDropdownText('Select Status')
    setSearchText('')
    getFilterList('')
  }

  const handleOnSubmitFilterModel = async () => {
    try {
      let postJson = {
        filterName: filterFormData?.filterName,
        duration: filterFormData?.duration,
        widgetId: filterFormData?.widgetId,
        description: filterFormData?.description,
        tags: tags,
        id: filterFormData?.id
      }
      let form_data = new FormData()
      form_data.append(
        'filterRequest',
        new Blob([JSON.stringify(postJson)], {
          type: 'application/json'
        })
      )
      form_data.append('thumbnail', thumbnailImage)
      form_data.append('filter', filterFile)
      dispatch(uplodateLoading(true))
      let path = urls.addFilter
      var res = await ApiCaller.post_formdata(
        form_data,
        path,
        userData?.access_token
      )
      const response = await res.response.json()
      if (response.status == 200) {
        setShowAddFilter(false)
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getFilterList()
        setFilterFile(null)
        setThumbnailImage(null)
        handleHideModel()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const handleAddFilter = () => {
    getWidgetKeyValuePair()
    setFilterFormData({
      id: '',
      filterName: '',
      duration: '',
      widgetId: '',
      description: '',
      thumbnailUrl: '',
      filterUrl: ''
    })
    setTags([])
    setShowAddFilter(true)
    setThumbnailImage(null)
    setThumbanailControl({
      showError: false,
      errorMessage: '',
      showImage: false,
      imageData: null
    })

    setFilterFileControl({
      showError: false,
      errorMessage: '',
      showImage: false,
      imageData: null
    })
  }

  const getWidgetKeyValuePair = async (widgetId) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.widgetKeyValuePair
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempWidgetKeyValueList = [{ value: '', label: 'Select Widget' }]
        response?.data?.map((data, i) => {
          tempWidgetKeyValueList.push({ value: data?.value, label: data?.key })
          if (data?.value === widgetId) {
            setDefaultWidgetOption({ value: data?.value, label: data?.key })
          }
        })
        setWidgetKeyValueData(tempWidgetKeyValueList)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleDeleteFilter = (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteApiCall(data?.id)
      }
    })
  }

  const deleteApiCall = async (id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.deleteFilter + '/' + id
      let res = await ApiCaller.delete({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        Swal.fire('Deleted!', response?.message, 'success')
        getFilterList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleEditFilter = async (item) => {
    console.log('imageeeeeeeeeeeeeeeeeeeeeeeeeeeee', item)
    setThumbnailImage(item?.thumbnailUrl)
    getWidgetKeyValuePair(item?.widgetId)
    setFilterFormData(item)
    setTags(item?.tags)
    setShowAddFilter(true)
    setFilterFile(item.filterUrl)
  }

  const handleHideModel = () => {
    setShowAddFilter(false)
    setFilterFileControl({
      showError: true,
      errorMessage: '',
      showImage: false,
      imageData: null
    })

    setThumbanailControl({
      showError: true,
      errorMessage: '',
      showImage: false,
      imageData: null
    })
  }

  useEffect(() => {
    dispatch(updateTopHeader('Filter List'))
  }, [])

  useEffect(() => {
    // This code will execute whenever startDate changes.
    getFilterList('', startDate, endDate, statusDropdownText)
  }, [startDate, endDate, statusDropdownText])

  useEffect(() => {
    getFilterList('', startDate, endDate)
  }, [sort_order, sort_type, page, props])

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeFilterStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getFilterList('', startDate, endDate, statusDropdownText)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const getFilterList = async (
    searchTextRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchTextRef,
        pageNumber: pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        active:
          statusDropdownText === 'Active'
            ? true
            : statusDropdownText === 'In Active'
              ? false
              : null,
        asc: sort_order,
        startDate:
          startDateRef && startDateRef !== null
            ? startDateRef.toDate()
            : '',
        endDate:
          endDateRef && endDateRef !== null ? endDateRef.toDate() : ''
      }
      let path = urls.filterList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.response?.items)
        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === 'EDIT') {
            setEdit(module.isEnable)
          } else if (module.permissionName === 'ACTIVE') {
            setActive(module.isEnable)
          } else if (module.permissionName === 'DELETE') {
            setDeletePermission(module.isEnable)
          } else if (module.permissionName === 'VIEW') {
            setView(module.isEnable)
          }
        })
        setPagerecord({
          totalItems: response?.data?.response?.totalItems,
          totalPages: response?.data?.response?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
      await logout()
    }
  }

  return (
    <>
      <>
        <NavBar />
        <div className='main-content'>
          <NavBarTop />
          <div className='header'>
            <div className='logo'>
              <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
            </div>
          </div>
          <div className='container-fluid mt--7'>
            <div className='row'>
              <div className='col text-left'>
                <div className='card shadow'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col'>
                        <h3 className='mb-0'>Filters List</h3>
                      </div>
                      <div className='col text-right'>
                        {userData.admin ? (
                          <a
                            onClick={handleAddFilter}
                            className='btn btn-sm btn-primary text-white'
                          >
                            <AddIcon />
                            Add New
                          </a>
                        ) : edit ? (
                          <a
                            onClick={handleAddFilter}
                            className='btn btn-sm btn-primary text-white'
                          >
                            <AddIcon />
                            Add New
                          </a>
                        ) : (
                          <></>
                        )}
                        {/* <a href='#!' className='btn btn-sm btn-primary'>
                        See all
                      </a> */}
                      </div>
                    </div>
                  </div>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col-md-3'>
                        <InputGroup
                          className='mb-2'
                          style={{ borderRadius: '10px' }}
                        >
                          <Form.Control
                            placeholder='Search by Filter Name'
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            aria-label='Amount (to the nearest dollar)'
                          />
                        </InputGroup>
                      </div>
                      <div className='col-md-1 mb-2 '>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              border: '1px solid #cad1d7',
                              boxShadow: 'none',
                              transform: 'none'
                            }}
                            id='dropdown-basic'
                          >
                            {statusDropdownText}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                setStatusDropdownText('Select Status')
                              }
                            >
                              Select Status
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('Active')}
                            >
                              Active
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('In Active')}
                            >
                              In Active
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div
                        className='col-md-4 mb-3'
                        style={{ marginLeft: '40px' }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker', 'DatePicker']}
                          >
                            <DatePicker
                              disableFuture
                              className='custom-date'
                              label='From Date'
                              value={startDate}
                              onChange={(newValue) => setStartDate(newValue)}
                              maxDate={endDate}
                            />
                            <DatePicker
                              disableFuture
                              label='To Date'
                              value={endDate}
                              onChange={(newValue) => setEndDate(newValue)}
                              minDate={startDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='col-md-3 mb-2'>
                        <Button
                          variant='contained'
                          className='custom-filter-btn'
                          onClick={handleFilterClick}
                        >
                          Filter
                        </Button>
                        <Button
                          variant='contained'
                          className='ml-2 custom-filter-btn'
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {datalist && datalist?.length > 0 ? (
                      <>
                        <table className='table align-items-center table-flush'>
                          <thead className='thead-light'>
                            <tr>
                              <th scope='col'>S/No.</th>
                              <th scope='col'>Filter Name</th>
                              <th scope='col'>Description</th>
                              <th scope='col'>Duration</th>
                              <th scope='col'>Created At</th>
                              <th scope='col'>View Count</th>
                              <th scope='col'>Play Count</th>
                              <th scope='col'>Like Count</th>
                              <th scope='col'>Follower Count</th>
                              <th scope='col'>Tags</th>
                              <th scope='col'>Thumbnail</th>
                              <th scope='col'>Status</th>
                              <th scope='col' />
                            </tr>
                          </thead>
                          <tbody>
                            {datalist &&
                              datalist?.map((data, i) => {
                                const isDescriptionOpen = showFullDescription[i]
                                const displayedDescription = isDescriptionOpen
                                  ? data?.description
                                  : data?.description.slice(0, 50) // Adjust the character limit as needed
                                const toggleDescription = (index) => {
                                  setShowFullDescription({
                                    ...showFullDescription,
                                    [index]: !showFullDescription[index]
                                  })
                                }
                                return (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{data?.filterName}</td>
                                    <td>
                                      <ShowLongText text={data?.description} header='Description' />
                                      {/* {displayedDescription}
                                      {data?.description.length > 50 && (
                                        <>
                                          <button
                                            className='btn btn-link'
                                            onClick={() => {
                                              handleShow(data?.description)
                                              toggleDescription(i)
                                            }}
                                            style={{
                                              fontSize: '11.5px',
                                              padding: '7px'
                                            }}
                                          >
                                            viewMore....
                                          </button>
                                        </>
                                      )} */}
                                    </td>
                                    <td>{data?.duration} Min</td>
                                    <td>{formatDate(data?.createdAt)}</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>
                                      {data?.tags && data?.tags.length ? (
                                        <>{data?.tags.join(', ')}</>
                                      ) : (
                                        <>-</>
                                      )}
                                    </td>
                                    <td>
                                      <Image
                                        image={{
                                          id: 1,
                                          src: data?.thumbnailUrl
                                        }}
                                        width='80px'
                                        height='auto
'
                                      />
                                    </td>
                                    <td>
                                      {userData.admin ? (
                                        <Switch
                                          {...label}
                                          checked={data?.active}
                                          onChange={(e) =>
                                            handleStatusChange(e, data?.id)
                                          }
                                          size='small'
                                        />
                                      ) : active ? (
                                        <Switch
                                          {...label}
                                          checked={data?.active}
                                          onChange={(e) =>
                                            handleStatusChange(e, data?.id)
                                          }
                                          size='small'
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    <td className='text-right'>
                                      <div className='dropdown'>
                                        <a
                                          className='btn btn-sm btn-icon-only text-light'
                                          href='#'
                                          role='button'
                                          data-toggle='dropdown'
                                          aria-haspopup='true'
                                          aria-expanded='false'
                                        >
                                          <i className='fas fa-ellipsis-v' />
                                        </a>
                                        <div
                                          className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                          x-placement='bottom-end'
                                          style={{
                                            position: 'absolute',
                                            willChange: 'transform',
                                            top: '0px',
                                            left: '0px',
                                            transform:
                                              'translate3d(32px, 32px, 0px)'
                                          }}
                                        >
                                          <a
                                            className='dropdown-item'
                                            onClick={() =>
                                              handleDeleteFilter(data)
                                            }
                                          >
                                            Delete
                                          </a>
                                          <a
                                            className='dropdown-item cursor'
                                            onClick={() =>
                                              handleEditFilter(data)
                                            }
                                          >
                                            Edit
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <div className='text-center'>
                          <img width={'25%'} src={NoDataImg}></img>
                          <br />
                          <label style={{ fontWeight: 'bold' }}>
                            {LOADING_ENABLE ? (
                              <>Loading Data.....</>
                            ) : (
                              <>No Data Found</>
                            )}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  {datalist.length ? (
                    <>
                      <div className='card-footer py-4'>
                        <Box display="flex" justifyContent="right" alignItems='center'>
                          <span>Total record: {pagerecord?.totalItems}</span>
                          <Pagination
                            className='custom-pagination-class'
                            count={pagerecord?.totalPages}
                            page={page}
                            onChange={handleChange}
                            color='secondary'
                          />
                        </Box>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </div>
          <AddFilter
            tags={tags}
            setTags={setTags}
            show={showAddFilter}
            filterFormData={filterFormData}
            widgetKeyValueData={widgetKeyValueData}
            defaultWidgetOption={defaultWidgetOption}
            setFilterFormData={setFilterFormData}
            onSubmit={() => handleOnSubmitFilterModel()}
            onHide={() => handleHideModel()}
            setThumbnailImage={setThumbnailImage}
            setFilterFile={setFilterFile}
            thumbnailImage={thumbnailImage}
            filterFile={filterFile}
            thumbanailControl={thumbanailControl}
            setThumbanailControl={setThumbanailControl}
            filterFileControl={filterFileControl}
            setFilterFileControl={setFilterFileControl}
            showFullDescription={showFullDescription}
            handleClose={handleClose}
            description={selectedDescription}
          />

          <DescriptionModal
            show={showFullDescription}
            handleClose={() => setShowFullDescription(!showFullDescription)}
            description={selectedDescription}
          />
        </div>
      </>
    </>
  )
}
