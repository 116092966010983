import $ from 'jquery'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useCallback, useEffect, useState } from 'react'
import 'react-dropdown/style.css'
import { Chip, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'

export const EditBeads = props => {
  const dispatch = useDispatch()
  const userData = useSelector(state => state?.userData)
  const [beadsType, setBeadsType] = useState('CREDIT')
  const [beadsAmount, setBeadsAmount] = useState(0)
  useEffect(() => { 
    setBeadsAmount(0)
  }, [props?.show === true])

  const handleSubmit = async () => {
    // props?.onSubmit()
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        userId: props?.userId,
        beads: beadsAmount,
        debitType: (beadsType && beadsType === 'CREDIT') ? false : true
      }
      let path = urls.topUpUserWallet
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        props?.onSubmit()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  // Function to handle input change
  const handleChange = event => {
    const inputValue = event.target.value
    // Allow only numeric input
    if (/^\d*$/.test(inputValue)) {
      // When beadsType is 'DEBIT', value can't be less than balance
      if (beadsType === 'DEBIT' && parseInt(inputValue) > props?.balance) {
        showDangerToast("Amount can't be more than available balance for Debit")
      } else {
        setBeadsAmount(inputValue)
      }
    }
  }


  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          <h3>Update Beads</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-2'>
            <Dropdown>
              <Dropdown.Toggle
                variant='success'
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  border: '1px solid #cad1d7',
                  boxShadow: 'none',
                  transform: 'none'
                }}
                id='dropdown-basic'
              >
                {beadsType || 'Credit'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setBeadsType('CREDIT')}>
                  Credit
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setBeadsType('DEBIT')}>
                  Debit
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className='col-md-6'>
            <Form.Control
              value={beadsAmount}
              onChange={handleChange}
              placeholder='Amount'
              aria-label='Small'
              aria-describedby='inputGroup-sizing-sm'
            />
            {beadsAmount ? (
              <></>
            ) : (
              <>
                <label className='custom-error-label'>
                  Amount is required*
                </label>
              </>
            )}
          </div>
          <div className='col-md-4'>
            <span>Chips Balance: {props?.balance}</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
