import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
export const ViewBeadsDetails = props => {
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='row'>
          <h3 className='col-md-12 text-center'>View Chips Purchase Details</h3>
        </div>
        <hr />
        <div className='row mt-5'>
          <div className='col-md-6'>
            <b>UserName:</b> {props?.data?.userName}
          </div>
          <div className='col-md-6'>
            <b>No. of Chips:</b> {props?.data?.beads}
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <b>Mobile No:</b>{props?.data?.ext} {props?.data?.mobile}
          </div>
          <div className='col-md-6'>
            <b>Price:</b> {props?.data?.amount} INR
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <b>Email:</b> {props?.data?.email}
          </div>
          <div className='col-md-6'>
            <b>Description:</b> {props?.data?.txnNote}
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <b>Full Name:</b> {props?.data?.fullName}
          </div>
          <div className='col-md-6'>
            <b>USER ID:</b> {props?.data?.userId}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
