import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useEffect, useState } from 'react'
import { FormControl, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import urls from '../constants/apiurl'
import { showDangerToast, showToast } from '../constants/toaster'
import ApiCaller from '../constants/ApiCaller'
import { uplodateLoading } from '../../redux/features/common/commonSlice'
export const GiveRewardOnVideo = props => {

  const dispatch = useDispatch()
  const userData = useSelector(state => state?.userData)

  const [rewardChips, setRewardChips] = useState(0.0)

  useEffect(() => {
    if (props?.show) {
      setRewardChips(0.0)
    }
  }, [props?.show])

  const handleSubmitReward = async () => {
    // private String videoId;
    // private String ownerId;
    // private Double chips;
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        videoId: props?.selectedVideoDetails?.id,
        ownerId: props?.selectedVideoDetails?.profileDetails?.id,
        chips: rewardChips
      }
      let path = urls.rewardOnVideo
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        props?.onHide()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          Give Reward
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField label="Chips" id="outlined-size-normal" defaultValue="0.0"
            value={rewardChips}
            onChange={(e) => {
              // Use a regular expression to check if the input is numeric
              const numericRegex = /^[0-9\b]+$/
              // Check if the input value matches the numeric regex
              if (
                numericRegex.test(e.target.value) ||
                e.target.value === ''
              ) {
                if (e?.target?.value?.length <= 4) {
                  // Update the state only if the input is numeric or empty
                  setRewardChips(e.target.value)
                }

              }
            }}
          />
          {rewardChips && rewardChips > 0 ? (
            <></>
          ) : (
            <>
              <label className='custom-error-label'>
                Chips is required*
              </label>
            </>
          )}
        </FormControl>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmitReward} disabled={rewardChips && rewardChips > 0 ? false : true}>
          Submit
        </Button>
        <Button variant='outlined' onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
