import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import Form from 'react-bootstrap/Form'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import ReplyIcon from '@mui/icons-material/Reply'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { useState } from 'react'
import { showDangerToast } from '../../constants/toaster'
import { InputGroup } from 'react-bootstrap'

const options = [
  { value: '', label: 'Select User' },
  { value: '1', label: 'Ganesh' }
]
const defaultOption = options[0]

export const AddQuestion = props => {
  const [value, setValue] = useState('all')
  const [showUserDropdown, setShowUserDropdown] = useState(false)
  const handleChange = event => {
    setValue(event.target.value)
    if (event.target.value === 'toUser') {
      setShowUserDropdown(true)
    } else {
      setShowUserDropdown(false)
    }
  }

  const handleSubmit = () => {
    if (props?.faqFormData?.question && props?.faqFormData?.description) {
      props.handleAddQuestion()
    } else {
      showDangerToast('Please fill all required fields')
    }
  }

  return (
    <>
      <div className='container-fluid mt--7'>
        <div className='row'>
          <div className='col text-left'>
            <div className='card shadow'>
              <div className='card-header border-0'>
                <div className='row align-items-center '>
                  <div className='col text-left'>
                    <a
                      onClick={() => props.setAddNewQuestion(false)}
                      className='btn btn-sm btn-primary text-white'
                    >
                      <ReplyIcon />
                      Cancel
                    </a>
                  </div>
                  <div className='col text-center'>
                    <h3 className='mb-0'>
                      {props?.faqFormData?.id ? 'Update' : 'Add'} Question
                    </h3>
                  </div>
                  <div className='col text-right'>
                    <a
                      onClick={handleSubmit}
                      className='btn btn-sm btn-primary text-white'
                    >
                      {props?.faqFormData?.id ? 'Update' : 'Add'}
                    </a>
                  </div>
                </div>
              </div>

              <div className='card-header border-0'>
                <div className='row align-items-center  mt-3'>
                  <div className='col-md-12'>
                    <b className='mb-4'>Question</b>
                    <InputGroup
                      className='mb-2'
                      style={{ borderRadius: '10px' }}
                    >
                      <Form.Control
                        placeholder='Enter Question here'
                        value={props?.faqFormData?.question}
                        onChange={(e) =>
                          props?.setFaqFormData({
                            ...props?.faqFormData,
                            question: e.target.value
                          })

                        }
                        aria-label='Amount (to the nearest dollar)'
                      />
                    </InputGroup>
                    {/* <CKEditor
                      editor={ClassicEditor}
                      data={props?.faqFormData?.question}
                      onReady={editor => {
                        console.log('Editor is ready to use!', editor)
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        props?.setFaqFormData({
                          ...props?.faqFormData,
                          question: data
                        })
                      }}
                      onBlur={(event, editor) => {
                        console.log('Blur.', editor)
                      }}
                      onFocus={(event, editor) => {
                        console.log('Focus.', editor)
                      }}
                    /> */}
                    {props?.faqFormData?.question ? (
                      <></>
                    ) : (
                      <>
                        <label className='custom-error-label'>
                          Question is required*
                        </label>
                      </>
                    )}
                  </div>
                  <div className='col-md-12'>
                    <b>Answer Description</b>
                    <CKEditor
                      editor={ClassicEditor}
                      data={props?.faqFormData?.description}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor)
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        props?.setFaqFormData({
                          ...props?.faqFormData,
                          description: data
                        })
                      }}
                      onBlur={(event, editor) => {
                        console.log('Blur.', editor)
                      }}
                      onFocus={(event, editor) => {
                        console.log('Focus.', editor)
                      }}
                    />
                    {props?.faqFormData?.description ? (
                      <></>
                    ) : (
                      <>
                        <label className='custom-error-label'>
                          Answer is required*
                        </label>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
