import Switch from '@mui/material/Switch'
import Swal from 'sweetalert2'
import Pagination from '@mui/material/Pagination'
import NoDataImg from '../../../assets/img/no-data.gif'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@mui/material/Button'
import dayjs, { Dayjs } from 'dayjs'
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import { AddSticker } from './AddSticker'
import Utils from '../../constants/Utils'
import { Box } from '@mui/material'
import { EditMeisheAssets } from './EditMeisheAssets'
import InsufficientPermission from '../../custom/InSufficientPermission'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const StickerList = props => {
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [tags, setTags] = useState([])
  const [bannerImage, setBannerImage] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [value, setValue] = useState(dayjs())
  const dispatch = useDispatch()
  const [datalist, setDatalist] = useState([])
  const [defaultWidgetOption, setDefaultWidgetOption] = useState(null)
  const [widgetKeyValueData, setWidgetKeyValueData] = useState([])
  const [showAddBanner, setShowAddBanner] = useState(false)
  const [showEditAsset, setShowEditAsset] = useState(false)
  const [selectedEditData, setSelectedEditData] = useState({})
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const userData = useSelector(state => state?.userData)
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [bannerFormData, setBannerFormData] = useState({
    id: '',
    name: '',
    displayName: '',
    keywords: [],
    description: ''
  })
  const [page, setPage] = useState(1)
  const [thumbanailControl, setThumbanailControl] = useState({
    showError: false,
    errorMessage: '',
    showImage: false,
    imageData: null
  })
  const [filterFileControl, setFilterFileControl] = useState({
    showError: false,
    errorMessage: '',
    showImage: false,
    imageData: null
  })

  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
  }

  const handleHideModel = () => {
    setShowAddBanner(false)
    setFilterFileControl({
      showError: true,
      errorMessage: '',
      showImage: false,
      imageData: null
    })
  }
  const handleHideEditAssetModel = () => {
    setShowEditAsset(false)
    setSelectedEditData({})
  }
  const handleOnSubmitBannerModel = async () => {
    try {
      let postJson = {
        name: bannerFormData?.name,
        displayName: bannerFormData?.displayName,
        keywords: tags,
        // keywords: bannerFormData?.keywords,
        description: bannerFormData?.description,
        id: bannerFormData?.id
      }
      console.log(postJson)
      let form_data = new FormData()
      form_data.append(
        'stickerRequest',
        new Blob([JSON.stringify(postJson)], {
          type: 'application/json'
        })
      )
      form_data.append('image', bannerImage)
      dispatch(uplodateLoading(true))
      let path = urls.addSticker
      var res = await ApiCaller.post_formdata(
        form_data,
        path,
        userData?.access_token
      )
      const response = await res.response.json()
      if (response.status == 200) {
        setShowAddBanner(false)
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getStickerList()
        handleHideModel()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const handleAddFilter = () => {
    setBannerFormData({})
    getWidgetKeyValuePair()
    setBannerFormData({
      id: '',
      name: '',
      imageUrl: '',
      description: ''
    })
    setShowAddBanner(true)
  }

  const handleFilterClick = () => {
    getStickerList(searchText, startDate, endDate)
  }

  const handleResetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setStatusDropdownText('Select Status')
    setSearchText('')
    getStickerList('')
  }

  const getWidgetKeyValuePair = async roleId => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.widgetKeyValuePair
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempWidgetKeyValueList = [{ value: '', label: 'Select Widget' }]
        response?.data?.map((data, i) => {
          tempWidgetKeyValueList.push({ value: data?.value, label: data?.key })
          if (data?.key === roleId) {
            setDefaultWidgetOption({ value: data?.value, label: data?.key })
          }
        })
        setWidgetKeyValueData(tempWidgetKeyValueList)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleEditSticker = item => {
    setBannerFormData({
      ...item
    })
    if (item?.imageUrl) {
      setBannerImage(item?.imageUrl)
    }
    setTags(item?.keywords)
    setShowAddBanner(true)
  }

  const handleDeleteConfirm = data => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then(async result => {
      if (result.isConfirmed) {
        deleteApiCall(data?.id)
      }
    })
  }

  useEffect(() => {
    dispatch(updateTopHeader('Meishe Assets Manager'))
  }, [])

  useEffect(() => {
    if(viewPermission){
      getStickerList('', startDate, endDate)
    }
  }, [sort_order, sort_type, props])

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeMeisheAssetsStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getStickerList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const deleteApiCall = async id => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.deleteSticker + '/' + id
      let res = await ApiCaller.delete({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        Swal.fire('Deleted!', response?.message, 'success')
        getStickerList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const userPermisiions = useSelector(state => state?.permission)
  let currentModulePermission;
  let viewPermission = true;
  let editPermission = true;
  let deletePermissionVal = true;
  let activePermission = true;

  if (userPermisiions?.modules.length > 0) {
    currentModulePermission = userPermisiions.modules.find((element) => {
      if (element.moduleId === '12') {
        return element
      }
    })?.permissions
    viewPermission = currentModulePermission.find((element) => {
      if (element.name === 'VIEW') {
        return element
      }
    }).isEnable
    editPermission = currentModulePermission.find((element) => {
      if (element.name === 'EDIT') {
        return element
      }
    }).isEnable
    deletePermissionVal = currentModulePermission.find((element) => {
      if (element.name === 'DELETE') {
        return element
      }
    }).isEnable
    activePermission = currentModulePermission.find((element) => {
      if (element.name === 'ACTIVE') {
        return element
      }
    }).isEnable
  }

  const getStickerList = async (
    searchTextRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchTextRef?.trim(),
        pageNum: pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        type: 0,
        category: 0,
        kind: 0,
        active: null
      }
      let path = urls.meisheAssetsList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.items)
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
        // response?.data?.permissionList.forEach((module, moduleIndex) => {
        //   if (module.permissionName === 'EDIT') {
        //     setEdit(module.isEnable)
        //   } else if (module.permissionName === 'ACTIVE') {
        //     setActive(module.isEnable)
        //   } else if (module.permissionName === 'DELETE') {
        //     setDeletePermission(module.isEnable)
        //   } else if (module.permissionName === 'VIEW') {
        //     setView(module.isEnable)
        //   }
        // })
        // setPagerecord({
        //   totalItems: response?.data?.response?.totalItems,
        //   totalPages: response?.data?.response?.totalPages,
        //   pageNumber: pageNo,
        //   pageSize: 10
        // })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const meisheAssetCategoryName = data => {
    if (data?.type) {
      if (data?.category) {
        if (data?.type === 1) {
          if (data?.category === 1) return 'Track'
          else if (data?.category === 2) return 'Clip'
        } else if (data?.type === 2) {
          if (data?.category === 1) return 'Adjust'
          else if (data?.category === 2) return 'Effects'
          else if (data?.category === 2) return 'Animation'
        } else if (data?.type === 3) {
          if (data?.category === 1) return 'Standaed'
          else if (data?.category === 2) return 'Assembled'
        } else if (data?.type === 4) {
          if (data?.category === 1) return 'Normal'
          else if (data?.category === 2) return 'Sound'
          else if (data?.category === 20000) return 'Template'
          else if (data?.category === 3) return 'Sticker Animation'
        } else if (data?.type === 5) {
          if (data?.category === 1) return '2D'
          else if (data?.category === 2) return '3D'
        } else if (data?.type === 9) {
          if (data?.category === 1) return 'Filter'
          else if (data?.category === 2) return 'Paint'
          else if (data?.category === 3) return 'Trigger'
        } else if (data?.type === 14) {
          if (data?.category === 1) return '2D'
          else if (data?.category === 2) return '3D'
          else if (data?.category === 3) return 'Segmentation'
          else if (data?.category === 5) return 'Particle'
          else if (data?.category === 6) return 'Avatar'
          else if (data?.category === 7) return 'Gesture'
        } else if (data?.type === 15) {
          if (data?.category === 1) return 'Dynamic'
          else if (data?.category === 2) return 'Static'
        } else if (data?.type === 16) {
          return '-'
        } else if (data?.type === 17) {
          return '-'
        } else if (data?.type === 18) {
          return '-'
        } else if (data?.type === 19) {
          if (data?.category === 1) return 'Standard template'
          else if (data?.category === 2)
            return 'Self-adaption duration template'
          else if (data?.category === 3) return 'AE conversion template'
        } else if (data?.type === 20) {
          if (data?.category === 2) return 'Single makeup'
          else if (data?.category === 3) return 'Beautification'
          else if (data?.category === 4) return 'Beautification+'
          else if (data?.category === 5) return 'Beauty template'
        } else if (data?.type === 21) {
          if (data?.category === 1) return 'variable makeup(old)'
          else if (data?.category === 2) return 'variable makeup(new)'
        }
      } else {
        return '-'
      }
    } else {
      return '-'
    }
  }
  const meisheAssetTypeName = data => {
    if (data?.type) {
      if (data?.type === 1) return 'theme'
      else if (data?.type === 2) return 'videofx'
      else if (data?.type === 3) return 'capitonstyle'
      else if (data?.type === 4) return 'animatedsticker'
      else if (data?.type === 5) return 'videotransition'
      else if (data?.type === 9) return 'particle'
      else if (data?.type === 14) return 'arscene'
      else if (data?.type === 15) return 'compound caption'
      else if (data?.type === 16) return 'photo album'
      else if (data?.type === 17) return 'MIMO'
      else if (data?.type === 18) return 'vlog'
      else if (data?.type === 19) return 'template'
      else if (data?.type === 20) return 'make up'
      else if (data?.type === 21) return 'variable makeup'
    } else {
      return '-'
    }
  }

  return (
    <>
      <>
        <NavBar />
        <div className='main-content'>
          <NavBarTop />
          <div className='header'>
            <div className='logo'>
              <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
            </div>
          </div>
          {
            viewPermission ? (
              <>
 <div className='container-fluid mt--7'>
            <div className='row'>
              <div className='col text-left'>
                <div className='card shadow'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col'>
                        <h3 className='mb-0'>Meishe Assets List</h3>
                      </div>
                      <div className='col text-right'>
                        {userData.admin ? (
                          <a
                            onClick={handleAddFilter}
                            className='btn btn-sm btn-primary text-white'
                          >
                            <AddIcon />
                            Add New
                          </a>
                        ) : edit ? (
                          <a
                            onClick={handleAddFilter}
                            className='btn btn-sm btn-primary text-white'
                          >
                            <AddIcon />
                            Add New
                          </a>
                        ) : (
                          <></>
                        )}

                        {/* <a href='#!' className='btn btn-sm btn-primary'>
                        See all
                      </a> */}
                      </div>
                    </div>
                  </div>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col-md-3'>
                        <InputGroup
                          className='mb-2'
                          style={{ borderRadius: '10px' }}
                        >
                          <Form.Control
                            placeholder='Search by Name'
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            aria-label='Amount (to the nearest dollar)'
                          />
                        </InputGroup>
                      </div>
                      {/* <div className='col-md-1 mb-2 '>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              border: '1px solid #cad1d7',
                              boxShadow: 'none',
                              transform: 'none'
                            }}
                            id='dropdown-basic'
                          >
                            {statusDropdownText}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                setStatusDropdownText('Select Status')
                              }
                            >
                              Select Status
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('Active')}
                            >
                              Active
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('In Active')}
                            >
                              In Active
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div> */}
                      <div
                        className='col-md-6 mb-3'
                        style={{ marginLeft: '40px' }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker', 'DatePicker']}
                          >
                            <DatePicker
                              disableFuture
                              className='custom-date'
                              label='From Date'
                              value={startDate}
                              onChange={newValue => setStartDate(newValue)}
                              maxDate={endDate}
                            />
                            <DatePicker
                              disableFuture
                              label='To Date'
                              value={endDate}
                              onChange={newValue => setEndDate(newValue)}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='col-md-3 mb-2'>
                        <Button
                          variant='contained'
                          className='custom-filter-btn'
                          onClick={handleFilterClick}
                        >
                          Filter
                        </Button>
                        <Button
                          variant='contained'
                          className='ml-2 custom-filter-btn'
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {datalist && datalist?.length > 0 ? (
                      <>
                        <table className='table align-items-center table-flush'>
                          <thead className='thead-light'>
                            <tr>
                              <th scope='col'>S/No.</th>
                              <th scope='col'>Name</th>
                              <th scope='col'>Category</th>
                              <th scope='col'>Type</th>
                              {/* <th scope='col'>Kind</th> */}
                              <th scope='col'>Cover</th>
                              <th scope='col'>Status</th>
                            {
                              (editPermission || deletePermissionVal) &&   <th scope='col'>Action</th>
                            }
                            </tr>
                          </thead>
                          <tbody>
                            {datalist &&
                              datalist?.map((data, i) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{data?.displayName}</td>
                                      <td>{meisheAssetCategoryName(data)}</td>
                                      <td>{meisheAssetTypeName(data)}</td>
                                      {/* <td>{meisheAssetTypeName(data)}</td> */}
                                      <td>
                                        <Image
                                          image={{
                                            id: 1,
                                            src: data?.coverUrl
                                          }}
                                          width='80px'
                                          height='auto
'
                                        />
                                      </td>
                                      <td>
                                        <Switch
                                          {...label}
                                          checked={data?.authed}
                                          disabled={!activePermission}
                                          onChange={e =>
                                            handleStatusChange(e, data?.id)
                                          }
                                          size='small'
                                        />
                                      </td>
                                      {/* <td>
                                        <Image
                                          image={{
                                            id: 1,
                                            src: data?.imageUrl
                                          }}
                                          width='80px'
                                          height='auto
'
                                        />
                                      </td> */}
                                    {
                                      (editPermission || deletePermissionVal) && 
                                      <td className='text-right'>
                                      <div className='dropdown'>
                                        <a
                                          className='btn btn-sm btn-icon-only text-light'
                                          href='#'
                                          role='button'
                                          data-toggle='dropdown'
                                          aria-haspopup='true'
                                          aria-expanded='false'
                                        >
                                          <i className='fas fa-ellipsis-v' />
                                        </a>
                                        <div
                                          className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                          x-placement='bottom-end'
                                          style={{
                                            position: 'absolute',
                                            willChange: 'transform',
                                            top: '0px',
                                            left: '0px',
                                            transform:
                                              'translate3d(32px, 32px, 0px)'
                                          }}
                                        >
                                          {
                                            editPermission && 
                                            <a
                                            className='dropdown-item cursor'
                                            onClick={() => {
                                              setShowEditAsset(!showEditAsset)
                                              setSelectedEditData(data)
                                            }}
                                          >
                                            Edit
                                          </a>
                                          }
                                        {
                                          deletePermissionVal && 
                                          <a
                                          className='dropdown-item cursor'
                                          onClick={() =>
                                            handleDeleteConfirm(data)
                                          }
                                        >
                                          Delete
                                        </a>
                                        }
                                        </div>
                                      </div>
                                    </td>
                                    }
                                    </tr>
                                  </>
                                )
                              })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <div className='text-center'>
                          <img width={'25%'} src={NoDataImg}></img>
                          <br />
                          <label style={{ fontWeight: 'bold' }}>
                            {LOADING_ENABLE ? (
                              <>Loading Data.....</>
                            ) : (
                              <>No Data Found</>
                            )}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  {datalist && datalist.length > 0 ? (
                    <>
                      <div className='card-footer py-4'>
                        <Box
                          display='flex'
                          justifyContent='right'
                          alignItems='center'
                        >
                          <span>Total record: {pagerecord?.totalItems}</span>
                          <Pagination
                            className='custom-pagination-class'
                            count={pagerecord?.totalPages}
                            page={page}
                            onChange={handleChange}
                            color='secondary'
                          />
                        </Box>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </div>
          <AddSticker
            setTags={setTags}
            tags={tags}
            show={showAddBanner}
            bannerFormData={bannerFormData}
            widgetKeyValueData={widgetKeyValueData}
            defaultWidgetOption={defaultWidgetOption}
            setBannerFormData={setBannerFormData}
            setBannerImage={setBannerImage}
            bannerImage={bannerImage}
            onSubmit={() => handleOnSubmitBannerModel()}
            onHide={() => handleHideModel()}
            thumbanailControl={thumbanailControl}
            setThumbanailControl={setThumbanailControl}
            filterFileControl={filterFileControl}
            setFilterFileControl={setFilterFileControl}
          />
          <EditMeisheAssets
            data={selectedEditData}
            show={showEditAsset}
            onSubmit={() => {
              setShowEditAsset(false)
              setSelectedEditData({})
              getStickerList()
            }}
            onHide={() => handleHideEditAssetModel()}
          />
              </>
            ) : (<InsufficientPermission/>)
          }
        </div>
      </>
    </>
  )
}
