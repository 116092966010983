import Switch from '@mui/material/Switch'
import Pagination from '@mui/material/Pagination'
import NoDataImg from '../../../assets/img/no-data.gif'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@mui/material/Button'
import { v4 as uuidv4 } from 'uuid';
import Box from "@mui/material/Box";
import dayjs, { Dayjs } from 'dayjs'
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useCallback, useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import { EditEmail } from './EditEmail'
import Utils from '../../constants/Utils'
import ShowLongText from '../../custom/ShowLongText'
import InsufficientPermission from '../../custom/InSufficientPermission'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const EmailList = props => {
  const { formatDate,extractHoursAndMinutes  } = Utils();
  const [callAPi, setCallAPi] = useState('')
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
    setCallAPi(uuidv4())
  }
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [value, setValue] = useState(dayjs())
  const [editEmailEnable, setEditEmailEnable] = useState(false)
  const dispatch = useDispatch()
  const [datalist, setDatalist] = useState([])
  const [defaultWidgetOption, setDefaultWidgetOption] = useState(null)
  const [widgetKeyValueData, setWidgetKeyValueData] = useState([])
  const [showAddFilter, setShowAddFilter] = useState(false)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const userData = useSelector(state => state?.userData)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [editEmailFormData, setEditEmailFormData] = useState({
    id: '',
    title: '',
    subject: '',
    content: ''
  })
  const handleOnSubmitFilterModel = () => { }
  const handleAddFilter = () => {
    setEditEmailFormData({})
    getWidgetKeyValuePair()
    setShowAddFilter(true)
  }

  
  const handleFilterClick = useCallback(() => {
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  }, [searchText, startDate, endDate]);

  const handleResetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setSearchText('')
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  }

  const getWidgetKeyValuePair = async roleId => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.widgetKeyValuePair
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempWidgetKeyValueList = [{ value: '', label: 'Select Widget' }]
        response?.data?.map((data, i) => {
          tempWidgetKeyValueList.push({ value: data?.value, label: data?.key })
          if (data?.key === roleId) {
            setDefaultWidgetOption({ value: data?.value, label: data?.key })
          }
        })
        setWidgetKeyValueData(tempWidgetKeyValueList)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleEditEmail = item => {
    setEditEmailFormData(item)
    setEditEmailEnable(true)
  }

  useEffect(() => {
    dispatch(updateTopHeader('Email Templates'))
  }, [])

  useEffect(() => {
    if(viewPermission){
      getEmailList()
    }
  }, [callAPi])


  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeFilterStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const getEmailList = async () => {
    let startDateObject;
    let endDateObject;
    if (startDate?.toDate()) {
      let newDate = new Date(startDate?.toDate());
      newDate.setDate(newDate.getDate() + 1);
      startDateObject = newDate;
    }
    if (endDate?.toDate()) {
      // Create a new Date object based on startDate
      let newDate = new Date(endDate?.toDate());
      // Add one day
      newDate.setDate(newDate.getDate() + 1);
      endDateObject = newDate;
    }
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchText?.trim(),
        pageNumber: pagerecord?.pageNumber - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        startDate:
          startDateObject !== null
            ? startDateObject
            : '',
        endDate:
          endDateObject !== null
            ? endDateObject
            : '',
      }
      let path = urls.emailList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.items)
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pagerecord?.pageNumber,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const userPermisiions = useSelector(state => state?.permission)
  let currentModulePermission;
  let viewPermission = true;
  let editPermission = true;
  let deletePermissionVal = true;
  let activePermission = true;

  if (userPermisiions?.modules.length > 0) {
    currentModulePermission = userPermisiions.modules.find((element) => {
      if (element.moduleId === '20') {
        return element
      }
    })?.permissions
    viewPermission = currentModulePermission.find((element) => {
      if (element.name === 'VIEW') {
        return element
      }
    }).isEnable
    editPermission = currentModulePermission.find((element) => {
      if (element.name === 'EDIT') {
        return element
      }
    }).isEnable
    deletePermissionVal = currentModulePermission.find((element) => {
      if (element.name === 'DELETE') {
        return element
      }
    }).isEnable
    activePermission = currentModulePermission.find((element) => {
      if (element.name === 'ACTIVE') {
        return element
      }
    }).isEnable
  }

  const handleSubmitEmail = async () => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        id: editEmailFormData?.id,
        title: editEmailFormData?.title,
        subject: editEmailFormData?.subject,
        content: editEmailFormData?.content
      }
      let path = urls.updateEmail
      let res = await ApiCaller.put(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        setEditEmailEnable(false)
        getEmailList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div className='header'>
          <div className='logo'>
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
          </div>
        </div>
     {
      viewPermission ? (
        <>
           {editEmailEnable ? (
          <>
            <EditEmail
              setEditEmailEnable={setEditEmailEnable}
              handleSubmitEmail={handleSubmitEmail}
              editEmailFormData={editEmailFormData}
              setEditEmailFormData={setEditEmailFormData}
            />
          </>
        ) : (
          <>
            <div className='container-fluid mt--7'>
              <div className='row'>
                <div className='col text-left'>
                  <div className='card shadow'>
                    <div className='card-header border-0'>
                      <div className='row align-items-center text-left'>
                        <div className='col'>
                          <h3 className='mb-0'>Email Templates</h3>
                        </div>
                      </div>
                    </div>
                    <div className='card-header border-0'>
                      <div className='row align-items-center text-left'>
                        <div className='col-md-3'>
                          <InputGroup
                            className='mb-2'
                            style={{ borderRadius: '10px' }}
                          >
                            <Form.Control
                              placeholder='Search by Keyword'
                              value={searchText}
                              onChange={e => setSearchText(e.target.value)}
                              aria-label='Amount (to the nearest dollar)'
                            />
                          </InputGroup>
                        </div>

                        <div className='col-md-6 mb-3'>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={['DatePicker', 'DatePicker']}
                            >
                              <DatePicker
                                disableFuture
                                className='custom-date'
                                label='From Date'
                                value={startDate}
                                onChange={newValue => setStartDate(newValue)}
                                maxDate={endDate}
                              />
                              <DatePicker
                                disableFuture
                                label='To Date'
                                value={endDate}
                                onChange={newValue => setEndDate(newValue)}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                        <div className='col-md-3 mb-2'>
                          <Button
                            variant='contained'
                            className='custom-filter-btn'
                            onClick={handleFilterClick}
                          >
                            Filter
                          </Button>
                          <Button
                            variant='contained'
                            className='ml-2 custom-filter-btn'
                            onClick={handleResetFilter}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className='table-responsive'>
                      {datalist?.length ? (
                        <>
                          <table className='table align-items-center table-flush'>
                            <thead className='thead-light'>
                              <tr>
                                <th scope='col'>S/No.</th>
                                <th scope='col'>Email Template</th>
                                <th scope='col'>Subject</th>
                                <th scope='col'>Date Of creation</th>
                                <th scope='col'>Time Of creation</th>
                               {
                                editPermission &&  <th scope='col' >Action</th>
                               }
                              </tr>
                            </thead>
                            <tbody>
                              {datalist?.map((data, i) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{data?.title}</td>
                                      <td>
                                        <ShowLongText text={data?.subject} header='Subject' /></td>
                                      <td>
                                        {formatDate(data?.createdAt)}
                                      </td>
                                      <td>
                                        {extractHoursAndMinutes(
                                          data?.createdAt
                                        )}
                                      </td>
                                   {
                                    editPermission && 
                                    <td className='text-right'>
                                    <div className='dropdown'>
                                      <a
                                        className='btn btn-sm btn-icon-only text-light'
                                        href='#'
                                        role='button'
                                        data-toggle='dropdown'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                      >
                                        <i className='fas fa-ellipsis-v' />
                                      </a>
                                      <div
                                        className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                        x-placement='bottom-end'
                                        style={{
                                          position: 'absolute',
                                          willChange: 'transform',
                                          top: '0px',
                                          left: '0px',
                                          transform:
                                            'translate3d(32px, 32px, 0px)'
                                        }}
                                      >
                                        <a
                                          className='dropdown-item cursor'
                                          onClick={() =>
                                            handleEditEmail(data)
                                          }
                                        >
                                          Edit
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                   }
                                    </tr>
                                  </>
                                )
                              })}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <>
                          <div className='text-center'>
                            <img width={'25%'} src={NoDataImg}></img>
                            <br />
                            <label style={{ fontWeight: 'bold' }}>
                              {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
                            </label>
                          </div>
                        </>
                      )}
                    </div>
                    {datalist?.length ? (
                      <>
                        <div className='card-footer py-4'>
                          <Box display="flex" justifyContent="right" alignItems='center'>
                            <span>Total record: {pagerecord?.totalItems}</span>
                            <Pagination
                              className='custom-pagination-class'
                              count={pagerecord?.totalPages}
                              page={page}
                              onChange={handleChange}
                              color='secondary'
                            />
                          </Box>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              {/* <Footer /> */}
            </div>
          </>
        )}
        </>
      ) : (<InsufficientPermission/>)
     }
      </div>
    </>
  )
}
