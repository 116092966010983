import { useSelector } from 'react-redux'; // Import useSelector from react-redux

const Utils = () => {
  // This needs to be inside a function or component to use hooks
  const userPermissions = useSelector(state => state?.permission);

  // Define utility functions
  const formatDate = (inputDate) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(inputDate);
    return date.toLocaleDateString('en-US', options);
  };

  const extractHoursAndMinutes = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const isViewPermissionEnabled = (moduleId) => {
    // Find the module by moduleId
    const module = userPermissions?.modules?.find((mod) => mod.moduleId === moduleId);

    // If the module is found
    if (module) {
      // Find the VIEW permission
      const viewPermission = module.permissions.find(
        (perm) => perm.name === "VIEW"
      );

      // If the VIEW permission is found, return its isEnable value
      if (viewPermission) {
        return viewPermission.isEnable;
      }
    }

    // Return false if module or VIEW permission is not found
    return false;
  };

  // Return an object with the utilities
  return {
    isViewPermissionEnabled,
    formatDate,
    extractHoursAndMinutes
  };
};

export default Utils;
