import Button from 'react-bootstrap/Button'
import TextField from '@mui/material/TextField'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Chip from '@mui/material/Chip'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useState } from 'react'

export const EditStaticContent = props => {
  const [inputValue, setInputValue] = useState('')
  const [errors, setErrors] = useState({})

  const handleInputChangeTag = event => {
    setInputValue(event.target.value)
  }

  const handleInputKeyDown = event => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      props?.setTags([...props?.tags, inputValue.trim()])
      setInputValue('')
    }
  }

  const handleChipDelete = tagToDelete => () => {
    props?.setTags(prevTags => prevTags.filter(tag => tag !== tagToDelete))
  }

  const validateForm = () => {
    const newErrors = {}

    if (!props.staticContentFormData.title) {
      newErrors.title = 'Static Page Title is required.'
    }

    if (!props.staticContentFormData.metaTitle) {
      newErrors.metaTitle = 'Meta Title is required.'
    }

    if (props.tags.length === 0) {
      newErrors.metaKeywords = 'At least one Meta Keyword is required.'
    }

    if (!props.staticContentFormData.description) {
      newErrors.description = 'Static Page Description is required.'
    }

    if (!props.staticContentFormData.metaDescription) {
      newErrors.metaDescription = 'Meta Description is required.'
    }

    setErrors(newErrors)

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = () => {
    if (validateForm()) {
      props.onSubmit()
    }
  }

  return (
    <Modal
      enforceFocus={false}
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          Update Static Content
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-6'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Static Page Title*</Form.Label>
              <Form.Control
                type='text'
                placeholder=''
                value={props.staticContentFormData.title}
                onChange={e =>
                  props.setStaticContentFormData({
                    ...props?.staticContentFormData,
                    title: e.target.value
                  })
                }
              />
              {errors.title && <small className='text-danger'>{errors.title}</small>}
            </Form.Group>
          </div>
          <div className='col-md-6'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Meta Title*</Form.Label>
              <Form.Control
                type='text'
                placeholder=''
                value={props.staticContentFormData.metaTitle}
                onChange={e =>
                  props.setStaticContentFormData({
                    ...props?.staticContentFormData,
                    metaTitle: e.target.value
                  })
                }
              />
              {errors.metaTitle && <small className='text-danger'>{errors.metaTitle}</small>}
            </Form.Group>
          </div>
          <div className='col-md-12'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Meta Keywords*</Form.Label>
              <TextField
                className='custom-tag-input'
                label='Tags'
                variant='outlined'
                value={inputValue}
                onChange={handleInputChangeTag}
                onKeyDown={handleInputKeyDown}
              />
              <div className='custom-tag-chip-overflow'>
                {props?.tags.map(tag => (
                  <Chip
                    key={tag}
                    label={tag}
                    onDelete={handleChipDelete(tag)}
                    style={{ margin: '4px' }}
                  />
                ))}
              </div>
              {errors.metaKeywords && <small className='text-danger'>{errors.metaKeywords}</small>}
            </Form.Group>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Static Page Description*</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={props.staticContentFormData?.description}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  props.setStaticContentFormData({
                    ...props?.staticContentFormData,
                    description: data
                  })
                }}
              />
              {errors.description && <small className='text-danger'>{errors.description}</small>}
            </Form.Group>
          </div>
          <div className='col-md-6'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Meta Description*</Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={props.staticContentFormData?.metaDescription}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  props.setStaticContentFormData({
                    ...props?.staticContentFormData,
                    metaDescription: data
                  })
                }}
              />
              {errors.metaDescription && (
                <small className='text-danger'>{errors.metaDescription}</small>
              )}
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
