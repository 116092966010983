import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';

export const AddRole = props => {
  const [error, setError] = useState({
    title: '',
    description: ''
  });

  const handleSubmit = () => {
    const name = props.roleFormData.name?.trim();
    const description = props.roleFormData.description?.trim();

    let hasError = false;
    let titleError = '';
    let descriptionError = '';

    if (name === '') {
      titleError = 'Role Name is required*';
      hasError = true;
    } else if (name.length > 30) {
      titleError = 'Role Name cannot exceed 30 characters*';
      hasError = true;
    }

    if (description === '') {
      descriptionError = 'Role Description is required*';
      hasError = true;
    } else if (description.length > 150) {
      descriptionError = 'Role Description cannot exceed 150 characters*';
      hasError = true;
    }

    setError({
      title: titleError,
      description: descriptionError
    });

    if (!hasError) {
      props.onSubmit();
    }
  };

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props.roleFormData.id ? 'Update Role' : 'Add Role'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-12'>
            <Form.Group controlId='roleName'>
              <h4>Title*</h4>
              <Form.Control
                type='text'
                placeholder='Enter Title'
                value={props.roleFormData.name}
                onChange={e => props.setRoleFormData({ name: e.target.value })}
                isInvalid={!!error.title}
              />
              <Form.Control.Feedback type='invalid'>
                {error.title}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className='col-md-12 mt-2'>
            <Form.Group controlId='roleDescription'>
              <h4>Description*</h4>
              <Form.Control
                as='textarea'
                placeholder='Description'
                value={props.roleFormData.description}
                onChange={e =>
                  props.setRoleFormData({ description: e.target.value })
                }
                isInvalid={!!error.description}
                style={{ height: '100px' }} // Adjust as needed
              />
              <Form.Control.Feedback type='invalid'>
                {error.description}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};
