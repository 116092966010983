import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast } from '../../constants/toaster'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Box, Link, Pagination } from '@mui/material'
import Utils from '../../constants/Utils'
import ShowLongText from '../../custom/ShowLongText'

export const AbuseReportsList = props => {
  const { formatDate } = Utils();
  const dispatch = useDispatch()
  const userData = useSelector(state => state?.userData)
  const [list, setList] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })

  const getReportAbuseList = async () => {
    try {
      // dispatch(uplodateLoading(true))

      let path = urls.abuseReportList
      const payload = {
        sort: 'createdAt',
        order: true,
        page: pagerecord?.pageNumber - 1,
        limit: pagerecord?.pageSize,
        typeId: props?.show?.typeId
      }
      // const payload = {
      //   sort: "createdAt",
      //   order: true,
      //   page: 0,
      //   limit: 10,
      //   typeId: "a8c73e78-93dc-45df-afef-5444add9baaa"
      // }
      let res = await ApiCaller.post(payload, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        // dispatch(uplodateLoading(false))
        setList(response?.data)
        setTotalItems(response?.data?.totalItems)
      } else {
        // dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      // dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleChange = (event, value) => {
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
  }

  useEffect(() => {
    console.log("showAbuseReportDetails", props?.show)
    getReportAbuseList()
  }, [pagerecord])

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          Abuse/Report Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='table-responsive'>
          <table className='table align-items-center table-flush'>
            <thead className='thead-light'>
              <tr>
                <th scope='col'>S/No.</th>
                <th scope='col'>User Name</th>
                <th scope='col'>User ID</th>
                <th scope='col'>Date</th>
                <th scope='col'>Reason For Report</th>
                <th scope='col'>User Profile(User Name)</th>
              </tr>
            </thead>
            <tbody>
              {list?.items.map((val, ind) => (
                <tr>
                  <td>{ind + 1}</td>
                  <td>{val?.userName}</td>
                  <td>{val?.userId}</td>
                  <td>{formatDate(val?.reportDate)}</td>
                  <td>
                    <ShowLongText text={val?.reason} header='Reason' />
                  </td>
                  <td>
                    <Link href={`/user-manager/${val?.profileLink}`}>
                      View profile
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      {list?.items && list?.items.length > 0 ? (
        <div className='card-footer py-4'>
          <Box display='flex' justifyContent='right' alignItems='center'>
            <span>Total record: {totalItems}</span>
            <Pagination
              className='custom-pagination-class'
              count={pagerecord?.totalPages}
              page={pagerecord?.pageNumber}
              onChange={handleChange}
              color='secondary'
            />
          </Box>
        </div>
      ) : (
        <></>
      )}
    </Modal>
  )
}
