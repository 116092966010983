import Swal from 'sweetalert2'
import Switch from '@mui/material/Switch'
import Dropdown from 'react-bootstrap/Dropdown'
import NoDataImg from '../../../assets/img/no-data.gif'
import Button from '@mui/material/Button'
import dayjs, { Dayjs } from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useCallback, useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import Utils from '../../constants/Utils'
import { Box, Pagination } from '@mui/material'
import { event } from 'jquery'
import { PayStackMfaOTPModal } from '../../custom/PayStackMfaOTPModal'
import { PayStackAvailableBalanceModal } from '../../custom/PayStackAvailableBalanceModal'
import InsufficientPermission from '../../custom/InSufficientPermission'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const WithdrawList = props => {
  const { formatDate } = Utils();
  const [callAPi, setCallAPi] = useState('')
  const [verificationType, setVerificationType] = useState('')
  const [balanceData, setBalanceData] = useState([])
  const [showMfaOtpVerifyModal, setShowMfaOtpVerifyModal] = useState(false)
  const [approveTransactionCode, setApproveTransactionCode] = useState('')
  const [showAvailableBalance, setShowAvailableBalance] = useState(false)
  const [page, setPage] = useState(1)
  const [statusList, setStatusList] = useState([
    {
      label: "All",
      value: "all"
    },
    {
      label: "Approved",
      value: "approved"
    },
    {
      label: "Declined",
      value: "decline"
    },
    {
      label: "Processing",
      value: "processing"
    },
    {
      label: "Pending",
      value: "pending"
    }
  ])
  const [statusDropdown, setStatusDropdown] = useState({
    label: "All",
    value: "All"
  })
  const [value, setValue] = useState(dayjs())
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [startDate, setStartDate] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [endDate, setEndDate] = useState(null)
  const dispatch = useDispatch()
  const [datalist, setDatalist] = useState([])
  const [defaultWidgetOption, setDefaultWidgetOption] = useState(null)
  const [widgetKeyValueData, setWidgetKeyValueData] = useState([])
  const [showAddFilter, setShowAddFilter] = useState(false)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const [globalSettingData, setGlobalSettingData] = useState({})
  const userData = useSelector(state => state?.userData)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
    setCallAPi(uuidv4())
  }
  const [filterFormData, setFilterFormData] = useReducer(
    (filterFormData, newItem) => {
      return { ...filterFormData, ...newItem }
    },
    {
      id: '',
      title: '',
      duration: '',
      widgetId: '',
      description: '',
      thumbnailUrl: '',
      filterUrl: ''
    }
  )

  const [mfaEnable, setMfaEnable] = useState(true)


  const handleFilterClick = useCallback(() => {
    setPage(1)
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  }, [searchText, statusDropdown, startDate, endDate])

  const handleResetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setSearchText('')
    setStatusDropdown({
      label: "All",
      value: "All"
    })
    setPage(1)
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  }


  const handleApproveTransaction = async (data, status) => {

    Swal.fire({
      title: "Enter Reason",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: status ? "Approve" : "Decline",
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        // alert()
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          dispatch(uplodateLoading(true))
          const payoutResponse = JSON.parse(data?.response);
          console.log(payoutResponse)
          let postJson = {
            payoutId: payoutResponse?.id,
            approve: status,
            reason: result?.value,
            userId: ""
          }
          let path =
            urls.approveDeclineTransaction
          let res = await ApiCaller.post(postJson, path, userData?.access_token)
          const response = await res.response.json()
          dispatch(uplodateLoading(false))
          if (response.status == 200) {
            console.log(response?.data)
            showToast(response?.message)
            getWithdrawList()
          } else if (response.status == 121) {
            setVerificationType('TRANSACTION')
            setShowMfaOtpVerifyModal(true)
            setApproveTransactionCode(response?.data)
          } else {
            dispatch(uplodateLoading(false))
            showDangerToast(response.message)
          }
        } catch (error) {
          dispatch(uplodateLoading(false))
          console.error(error)
          showDangerToast(error.message)
        }
        // Swal.fire({
        //   title: `${result.value.login}'s avatar`,
        //   imageUrl: result.value.avatar_url
        // });
      }
    });
  }


  const handleVerifyMfaOTP = async otp => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.verifyMfaPayStackOtp + '?otp=' + otp
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      dispatch(uplodateLoading(false))
      if (response.status == 200) {
        console.log(response?.data)
        setMfaEnable(false)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const handleVerifyTransactionOTP = async otp => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        transfer_code: approveTransactionCode,
        otp: otp
      }
      let path = urls.finalApproveTransactionVerification
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getWithdrawList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }


  useEffect(() => {
    dispatch(updateTopHeader('Withdraw Manager'))
    getSettingList()
  }, [])

  useEffect(() => {
    if(viewPermission){
      getWithdrawList();
    }
  }, [callAPi]);

  const getSettingList = async () => {
    try {
      dispatch(uplodateLoading(true))

      let path = urls.getGlobalSetting
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        console.log(response?.data)
        setGlobalSettingData(response?.data)
        setMfaEnable(response?.data?.payStackMfaEnable)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const getPayStackAvailableBalance = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.paystackAvailableBalance
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setBalanceData(response?.data)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeFilterStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const userPermisiions = useSelector(state => state?.permission)
  let currentModulePermission;
  let viewPermission = true;
  let editPermission = true;
  let deletePermissionVal = true;
  let activePermission = true;

  if(userPermisiions?.modules.length>0){
    currentModulePermission = userPermisiions.modules.find((element)=>{
      if(element.moduleId==='28'){
         return element
      }
    })?.permissions
     viewPermission = currentModulePermission.find((element)=>{
      if(element.name==='VIEW'){
        return element
      }
    }).isEnable
     editPermission = currentModulePermission.find((element)=>{
      if(element.name==='EDIT'){
        return element
      }
    }).isEnable
    deletePermissionVal = currentModulePermission.find((element)=>{
      if(element.name==='DELETE'){
        return element
      }
    }).isEnable
    activePermission = currentModulePermission.find((element)=>{
      if(element.name==='ACTIVE'){
        return element
      }
    }).isEnable
   }

  const getWithdrawList = async () => {
    let startDateObject
    let endDateObject
    if (startDate?.toDate()) {
      let newDate = new Date(startDate?.toDate())
      newDate.setDate(newDate.getDate() + 1)
      startDateObject = newDate
    }
    if (endDate?.toDate()) {
      let newDate = new Date(endDate?.toDate())
      newDate.setDate(newDate.getDate() + 1)
      endDateObject = newDate
    }
    try {
      setApproveTransactionCode('')
      dispatch(uplodateLoading(true))
      let postJson = {
        username: searchText?.trim(),
        page: pagerecord?.pageNumber - 1 || 0,
        limit: pagerecord.pageSize || 10,
        sort: sort_type,
        order: sort_order,
        status: statusDropdown?.value,
        startDate: startDateObject !== null ? startDateObject : '',
        endDate: endDateObject !== null ? endDateObject : ''
      }
      let path = urls.withdrawTransactioNList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        console.log("asdasdasdasdasd", response)
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.items)
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pagerecord?.pageNumber,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <>
        <NavBar />
        <div className='main-content'>
          <NavBarTop />
          <div className='header'>
            <div className='logo'>
              <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
            </div>
          </div>
        {
          viewPermission ? (
            <>
              <div className='container-fluid mt--7'>
            <div className='row'>
              <div className='col text-left'>
                <div className='card shadow'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col'>
                        <h3 className='mb-0'>Withdraw Requests</h3>
                      </div>
                    </div>
                  </div>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col-md-3'>
                        <InputGroup
                          className='mb-2'
                          style={{ borderRadius: '10px' }}
                        >
                          <Form.Control
                            placeholder='Search by Keyword'
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            aria-label='Amount (to the nearest dollar)'
                          />
                        </InputGroup>
                      </div>
                      <div className='col-md-6 mb-3'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker', 'DatePicker']}
                          >
                            <DatePicker
                              disableFuture
                              className='custom-date'
                              label='From Date'
                              value={startDate}
                              onChange={newValue => setStartDate(newValue)}
                              maxDate={endDate}
                            />
                            <DatePicker
                              disableFuture
                              label='To Date'
                              value={endDate}
                              onChange={newValue => setEndDate(newValue)}
                              minDate={startDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='col-md-1 mb-2 '>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              border: '1px solid #cad1d7',
                              boxShadow: 'none',
                              transform: 'none'
                            }}
                            id='dropdown-basic'
                          >
                            {statusDropdown?.label}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {statusList?.map((data) => {
                              return <>
                                <Dropdown.Item
                                  onClick={() => setStatusDropdown(data)}
                                >
                                  {data?.label}
                                </Dropdown.Item>
                              </>
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className='col-md-3 mb-2'>
                        <Button
                          variant='contained'
                          className='custom-filter-btn'
                          onClick={handleFilterClick}
                        >
                          Filter
                        </Button>
                        <Button
                          variant='contained'
                          className='ml-2 custom-filter-btn'
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                      {/* <div className='col-md-2 d-flex'>
                        <h4 style={{ marginTop: '8px' }}>MFA Require</h4>
                        <Switch
                          checked={mfaEnable}
                          onChange={handleChangePaystackOTPRequire}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </div> */}
                      {/* <div className='col-md-1 d-flex'>
                        <a
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            setShowAvailableBalance(!showAvailableBalance)
                          }
                        >
                          View balance
                        </a>
                      </div> */}
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {datalist && datalist?.length > 0 ? (
                      <>
                        <table className='table align-items-center table-flush'>
                          <thead className='thead-light'>
                            <tr>
                              <th scope='col'>S/No.</th>
                              <th scope='col'>User Name</th>
                              <th scope='col'>Email</th>
                              <th scope='col'>Mobile Number</th>
                              <th scope='col'>Requested Amount</th>
                              <th scope='col'>Status</th>
                              <th scope='col'>Created At</th>
                            {
                              editPermission &&   <th scope='col' >Action</th>
                            }
                            </tr>
                          </thead>
                          <tbody>
                            {datalist &&
                              datalist?.map((data, i) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{data?.userName}</td>
                                      <td>{data?.email}</td>
                                      <td>{data?.mobile}</td>
                                      <td>
                                        {(data?.amount / 100).toFixed(2)} INR
                                      </td>
                                      <td>
                                        <span className='badge badge-dot mr-4'>
                                          <i className='bg-warning' />{' '}
                                          {data?.status}
                                        </span>
                                      </td>
                                      <td>
                                        {formatDate(data?.createdDate)}
                                      </td>
                                  {
                                    editPermission && 
                                    <td className='text-right'>
                                    {(data?.status &&
                                      data?.status === 'pending') ||
                                      data?.status === 'REQUESTED' ? (
                                      <div className='dropdown'>
                                        <a
                                          className='btn btn-sm btn-icon-only text-light'
                                          href='#'
                                          role='button'
                                          data-toggle='dropdown'
                                          aria-haspopup='true'
                                          aria-expanded='false'
                                        >
                                          <i className='fas fa-ellipsis-v' />
                                        </a>
                                        <div
                                          className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                          x-placement='bottom-end'
                                          style={{
                                            position: 'absolute',
                                            willChange: 'transform',
                                            top: '0px',
                                            left: '0px',
                                            transform:
                                              'translate3d(32px, 32px, 0px)'
                                          }}
                                        >
                                          <a
                                            className='dropdown-item cursor'
                                            onClick={() =>
                                              handleApproveTransaction(
                                                data,
                                                false
                                              )
                                            }
                                          >
                                            Decline
                                          </a>
                                          <a
                                            className='dropdown-item cursor'
                                            onClick={() =>
                                              handleApproveTransaction(
                                                data,
                                                true
                                              )
                                            }
                                          >
                                            Approve
                                          </a>
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                  }
                                    </tr>
                                  </>
                                )
                              })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <div className='text-center'>
                          <img width={'25%'} src={NoDataImg}></img>
                          <br />
                          <label style={{ fontWeight: 'bold' }}>
                            {LOADING_ENABLE ? (
                              <>Loading Data.....</>
                            ) : (
                              <>No Data Found</>
                            )}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  {datalist.length ? (
                    <>
                      <div className='card-footer py-4'>
                        <Box
                          display='flex'
                          justifyContent='right'
                          alignItems='center'
                        >
                          <span>Total record: {pagerecord?.totalItems}</span>
                          <Pagination
                            className='custom-pagination-class'
                            count={pagerecord?.totalPages}
                            page={page}
                            onChange={handleChange}
                            color='secondary'
                          />
                        </Box>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <PayStackMfaOTPModal
              show={showMfaOtpVerifyModal}
              type={verificationType}
              onHide={() => setShowMfaOtpVerifyModal(!showMfaOtpVerifyModal)}
              onVerify={otp => handleVerifyMfaOTP(otp)}
              onVerifyTransaction={otp => handleVerifyTransactionOTP(otp)}
            />
            <PayStackAvailableBalanceModal
              show={showAvailableBalance}
              datalist={balanceData}
              onHide={() => setShowAvailableBalance(!showAvailableBalance)}
            />
            {/* <Footer /> */}
          </div>
            </>
          ) : (<InsufficientPermission/>)
        }
        </div>
      </>
    </>
  )
}
