import Switch from '@mui/material/Switch'
import Pagination from '@mui/material/Pagination'
import Box from "@mui/material/Box";
import NoDataImg from '../../../assets/img/no-data.gif'
import { useState } from 'react'
import { showDangerToast, showToast } from '../../constants/toaster'
import Utils from '../../constants/Utils'
import urls from '../../constants/apiurl'
import { useDispatch, useSelector } from 'react-redux'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'
import ApiCaller from '../../constants/ApiCaller'
import { ViewAds } from './ViewAds';

const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const MainAdsTable = props => {
  const { formatDate } = Utils();
  const [showViewAds, setShowViewAds] = useState(false)
  const [viewAdsData, setViewAdsData] = useState({})
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const userData = useSelector(state => state?.userData)
  const dispatch = useDispatch()
  const [showEditBead, setShowEditBead] = useState(false)
  const [userId, setUserId] = useState()
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const [showViewUserDetails, setShowViewUserDetails] = useState(false)
  const handleEditBeads = item => {
    setShowEditBead(true)
  }

  const displayName = (label) => {
    return <>
      {label ? label + ',' : ''}
    </>
  }

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeAdsStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        props?.getAdsList();
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const submitEditBeads = () => {
    setShowEditBead(false)
    showToast('Beads Update Successfully.')
  }

  return (
    <>
      <div className='table-responsive'>
        {props?.datalist?.length ? (
          <>
            <table className='table align-items-center table-flush'>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>S/No.</th>
                  <th scope='col'>Type</th>
                  <th scope='col'>Category</th>
                  <th scope='col'>Total Target</th>
                  <th scope='col'>Pending Target</th>
                  <th scope='col'>Start Time</th>
                  <th scope='col'>End Time</th>
                  <th scope='col'>Created At</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {props?.datalist?.map((item, i) => {
                  return (
                    <>
                      <tr>
                        <td>{i + 1}</td>
                        <td>{item?.contentType}</td>
                        <td>{item?.targetType ? item?.targetType : '-'}</td>
                        <td>{item?.targets ? item?.targets : '-'}</td>
                        <td>{item?.pendingTargets ? item?.pendingTargets : '-'}</td>
                        {/* <td>{displayName(item?.cityName)}  {displayName(item?.districtName)} {displayName(item?.stateName)} {item?.countryName}</td> */}
                        <td>{formatDate(item?.startDate)}</td>
                        <td className='text-center'>{item?.endDate ? formatDate(item?.endDate) : '-'}</td>
                        <td>{formatDate(item?.createdAt)}</td>
                        <td>
                          {props?.admin ? (
                            <Switch
                              {...label}
                              checked={item?.active}
                              onChange={e => handleStatusChange(e, item?.id)}
                              size='small'
                            />
                          ) : props?.viewPermission ? (
                            <Switch
                              {...label}
                              checked={item?.active}
                              disabled={!props.activePermission}
                              onChange={e => handleStatusChange(e, item?.id)}
                              size='small'
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td className='text-right'>
                          <div className='dropdown'>
                            <a
                              className='btn btn-sm btn-icon-only text-light'
                              href='#'
                              role='button'
                              data-toggle='dropdown'
                              aria-haspopup='true'
                              aria-expanded='false'
                            >
                              <i className='fas fa-ellipsis-v' />
                            </a>
                            <div
                              className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                              x-placement='bottom-end'
                              style={{
                                position: 'absolute',
                                willChange: 'transform',
                                top: '0px',
                                left: '0px',
                                transform: 'translate3d(32px, 32px, 0px)'
                              }}
                            >
                              <a
                                className='dropdown-item cursor'
                                onClick={() => {
                                  setViewAdsData(item)
                                  setShowViewAds(!showViewAds)
                                }}
                              >
                                View
                              </a>
                             {
                              props.editPermission && 
                              <a
                              className='dropdown-item cursor'
                              onClick={() => props?.handleEditAds(item)}
                            >
                              Edit
                            </a>
                             }
                             {props.deletePermissionVal && <a
                                className='dropdown-item'
                                onClick={e => props?.handleDeleteAds(item)}
                              >
                                Delete
                              </a>}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </>
        ) : (
          <>
            <div className='text-center'>
              <img width={'25%'} src={NoDataImg}></img>
              <br />
              <label style={{ fontWeight: 'bold' }}>
                {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
              </label>
            </div>
          </>
        )}
      </div>
      {props?.datalist && props?.datalist.length > 0 ? (
        <div className='card-footer py-4'>

          <Box display="flex" justifyContent="right" alignItems='center'>
            <span>Total record: {props?.pagerecord?.totalItems}</span>
            <Pagination
              className='custom-pagination-class'
              count={props?.pagerecord?.totalPages}
              page={props?.page}
              onChange={props?.handleChange}
              color='secondary'
            />
          </Box>
        </div>
      ) : (
        <></>
      )}
      <ViewAds
        show={showViewAds}
        data={viewAdsData}
        onHide={() => setShowViewAds(false)}
      />
    </>
  )
}
