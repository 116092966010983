import { useEffect, useState } from "react"
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { showDangerToast } from '../../constants/toaster'
import { useDispatch, useSelector } from "react-redux"
import { uplodateLoading } from "../../../redux/features/common/commonSlice"
import urls from "../../constants/apiurl"
import ApiCaller from "../../constants/ApiCaller"
import { Dropdown, InputGroup } from "react-bootstrap"

export const AddCity = (props) => {
    const [countryDropdownText, setCountryDropdownText] = useState({
        id: "",
        lable: "Select Country"
    });
    const [stateDropdownText, setStateDropdownText] = useState({
        key: "",
        value: "Select State"
    });
    const [stateCodeList, setStateCodeList] = useState([{
        key: "",
        value: "Select State"
    }])
    const userData = useSelector((state) => state?.userData);
    const [isValidName, setIsValidName] = useState(true);
    const [isValidParentId, setIsValidParentId] = useState(true);
    const dispatch = useDispatch()
    const handleSubmit = () => {
        if (props?.cityFormData?.name === '' || props?.cityFormData?.parentId === '') {
            showDangerToast('Please fill all required fields.');
        } else {
            props?.onSubmit()
        }
    };


    useEffect(() => {
        if (props?.cityFormData?.countryId) {
            for (const data of props?.countryCodeList) {
                if (data?.id === props?.cityFormData?.countryId) {
                    setCountryDropdownText({
                        id: data?.id,
                        lable: data?.name
                    })
                }
            }
        }
        if (props?.cityFormData?.stateId) {
            for (const data of props?.stateCodeList) {
                if (data?.key === props?.cityFormData?.stateId) {
                    setStateDropdownText(data)
                }
            }
        }
        if (props?.show) {
            if ((props?.cityFormData?.stateId === '' || props?.cityFormData?.stateId === null || props?.cityFormData?.stateId === undefined) && (props?.cityFormData?.countryId === null || props?.cityFormData?.countryId === '' || props?.cityFormData?.countryId === undefined)) {
                setCountryDropdownText({
                    id: "",
                    lable: "Select Country"
                })
                setStateDropdownText({
                    key: "",
                    value: "Select State"
                })
            }
        }
    }, [props?.show])

    const getStateCodeListByCountryId = async (countryId) => {
        if (countryId) {
            try {
                dispatch(uplodateLoading(true))
                let path = urls.stateCodeListByCountryId + "/" + countryId
                let res = await ApiCaller.get({}, path, userData?.access_token)
                const response = await res.response.json()
                if (response.status == 200) {
                    dispatch(uplodateLoading(false))
                    let tempArray = [{
                        key: "",
                        value: "Select State"
                    }]
                    tempArray.push(...response?.data)
                    setStateCodeList(tempArray)
                } else {
                    dispatch(uplodateLoading(false))
                    showDangerToast(response.message)
                }
            } catch (error) {
                dispatch(uplodateLoading(false))
                console.error(error)
                showDangerToast(error.message)
            }
        } else {
            setStateCodeList([{
                key: "",
                value: "Select State"
            }])
        }
    }

    const handleInputChange = (e, inputType) => {
        const value = e.target.value;
        // Simple validation: check if the input is not empty
        const isValidInput = value.trim() !== '';
        // Update the state based on validation result
        if (inputType === 'name') {
            setIsValidName(isValidInput);
        } else if (inputType === 'parentId') {
            setIsValidParentId(isValidInput);
        }
        props.setCityFormData({
            ...props?.cityFormData,
            name: value
        })
    };

    return (
        <Modal
            {...props}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-vcenter'>
                    {props?.cityFormData?.id ? 'Update City' : 'Add City'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-md-6'>
                        <Form.Group controlId='stateNameInput'>
                            <h4>Name*</h4>
                            <Form.Control
                                type='text'
                                placeholder='Enter Name'
                                value={props.cityFormData.name}
                                onChange={e => handleInputChange(e, 'name')}
                            />
                            {props?.cityFormData?.name === '' ? (
                                <label className='custom-error-label'>Name is required*</label>
                            ) : (
                                !isValidName && (
                                    <label className='custom-error-label'>
                                        Name is required.
                                    </label>
                                )
                            )}
                        </Form.Group>
                    </div>
                    <div className='col-md-3'>
                        <Form.Group controlId='parentIdInput'>
                            <h4>Country*</h4>
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant='success'
                                    style={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        border: '1px solid #cad1d7',
                                        boxShadow: 'none',
                                        transform: 'none'
                                    }}
                                    id='dropdown-basic'
                                >
                                    {countryDropdownText?.lable}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {props?.countryCodeList?.map((item, i) => {
                                        return <><Dropdown.Item eventKey={item?.id}
                                            onClick={() => {
                                                setCountryDropdownText({
                                                    id: item?.id,
                                                    lable: item?.name
                                                })
                                                props.setCityFormData({
                                                    ...props?.cityFormData,
                                                    'parentId': item?.id,
                                                    'isState': false
                                                })
                                                getStateCodeListByCountryId(item?.id)
                                                setStateDropdownText({
                                                    key: "",
                                                    value: "Select State"
                                                })
                                            }
                                            }
                                        >
                                            {item?.name}
                                        </Dropdown.Item></>
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </div>
                    <div className='col-md-3'>
                        <Form.Group controlId='parentIdInput'>
                            <h4>State*</h4>
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant='success'
                                    style={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        border: '1px solid #cad1d7',
                                        boxShadow: 'none',
                                        transform: 'none'
                                    }}
                                    id='dropdown-basic'
                                >
                                    {stateDropdownText?.value}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {stateCodeList?.map((item, i) => {
                                        return <><Dropdown.Item eventKey={item?.key}
                                            onClick={() => {
                                                setStateDropdownText(item)
                                                props.setCityFormData({
                                                    ...props?.cityFormData,
                                                    'parentId': item?.key,
                                                    'isState': true
                                                })
                                            }
                                            }
                                        >
                                            {item?.value}
                                        </Dropdown.Item></>
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='outline-secondary' onClick={props.onHide}>Cancel</Button>
                <Button variant='primary' onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </Modal>
    );


}