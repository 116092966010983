import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { showDangerToast } from '../../constants/toaster'
import { useState } from 'react'
import { faL } from '@fortawesome/free-solid-svg-icons'
export const AddTag = props => {
  const [isChangeStarted,setisChangeStarted] = useState(false);
  const handleSubmit = () => {
    if (props?.tagFormData?.name) {
      props.onSubmit()
      setisChangeStarted(false)
    } else {
      showDangerToast('Category Name is required')
    }
  }
  const handleTagName = (e) => {
    let value = e.target.value.replace(/\s{2,}/g, ' ').trim();
    setisChangeStarted(true);
    props.setTagFormData({
      ...props?.tagFormData,
      name: value
    });
  };
  

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.tagFormData?.id ? 'Update Category' : 'Add Category'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          value={props.tagFormData.name}
          onChange={e => handleTagName(e)}
          placeholder='Enter Category Name'
          maxLength={20}
          aria-label='Small'
          aria-describedby='inputGroup-sizing-sm'
        />
        {props?.tagFormData?.name ? (
          <></>
        ) : (
          <>
           {isChangeStarted && <label className='custom-error-label'>Category Name is required*</label> }
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={()=>{
          setisChangeStarted(false);
          props.onHide();
        }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
