import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useEffect, useReducer, useState } from 'react'
import { showDangerToast } from '../../constants/toaster'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
export const CountryAdsConfiguration = (props) => {
  const dispatch = useDispatch()
  const [object, setObject] = useState([]);
  const [allTypeOfAds, setAllTypeOfAds] = useState([])

  const userData = useSelector((state) => state?.userData);
  const [globalMatrixData, setGlobalMatrixData] = useState({})
  useEffect(() => {
    if (props?.show) {
      getConfigGLobalAdsMatrix()
    }
  }, [props?.show])


  const getAllTypeOfMatrix = async (updateObject) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.getAllTypeOfMatrix
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setAllTypeOfAds(response?.data)
        if (updateObject) {
          const initialState = response?.data?.map((data) => ({
            typeId: data.key,
            city: '',
            district: '',
            state: '',
            country: '',
          })) || [];
          setObject(initialState)
        }
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const getConfigGLobalAdsMatrix = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.getConfigGLobalAdsMatrix + "?countryId=" + props?.countryId
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setGlobalMatrixData(response?.data)
        console.log(response?.data)
        // const initialState = response?.data?.map((data) => ({
        //   typeId: data.key,
        //   city: '',
        //   district: '',
        //   state: '',
        //   country: ''
        // })) || [];
        if (response?.data?.configData?.length > 0) {
          setObject(response?.data?.configData)
          getAllTypeOfMatrix(false)
        } else {
          getAllTypeOfMatrix(true)
        }
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleInputChange = (index, field, value) => {
    // Allow only numbers and decimal points
    const regex = /^\d*\.?\d*$/;
    if (regex.test(value)) {
      const newObject = [...object];
      newObject[index][field] = value;
      setObject(newObject);
    }
  };

  const handleSubmitConfig = async () => {
    let postJson = {
      countryId: props?.countryId,
      configData: object
    }
    try {
      dispatch(uplodateLoading(true))
      let path = urls.configGlobalAdsMatrix
      let res = await ApiCaller.post(
        postJson,
        path,
        userData?.access_token
      )
      const response = await res.response.json()
      console.log('res', response)
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        props?.onHide()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }

    console.log(object)
  }


  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          Global Matrix Update
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{width:'100%',overflow:'auto'}}>
        <table>
          <thead>
            <tr>
              <th className='custom-matrix-th'></th>
              {allTypeOfAds?.map((data) => (
                <th className='custom-matrix-th' key={data.value}>{data?.value}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='custom-matrix-td'>Country</td>
              {object.map((data, index) => (
                <td className='custom-matrix-td' key={index}>
                  <input
                    style={{ maxWidth: '100px' }}
                    value={data.country}
                    maxLength={8}
                    onChange={(e) => handleInputChange(index, 'country', e.target.value)}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td className='custom-matrix-td'>State</td>
              {object.map((data, index) => (
                <td className='custom-matrix-td' key={index}>
                  <input
                    style={{ maxWidth: '100px' }}
                    value={data.state}
                    maxLength={8}
                    onChange={(e) => handleInputChange(index, 'state', e.target.value)}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td className='custom-matrix-td'>District</td>
              {object.map((data, index) => (
                <td className='custom-matrix-td' key={index}>
                  <input
                    style={{ maxWidth: '100px' }}
                    value={data.district}
                    maxLength={8}
                    onChange={(e) => handleInputChange(index, 'district', e.target.value)}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td className='custom-matrix-td'>City</td>
              {object.map((data, index) => (
                <td className='custom-matrix-td' key={index}>
                  <input
                    style={{ maxWidth: '100px' }}
                    value={data.city}
                    maxLength={8}
                    onChange={(e) => handleInputChange(index, 'city', e.target.value)}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmitConfig()}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
