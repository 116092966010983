import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import $, { post } from 'jquery'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { saveAs } from 'file-saver' // For downloading files
import JSZip from 'jszip' // Library for working with zip files
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useCallback, useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import DropBox from '../../custom/DropBox'
import AWS from 'aws-sdk'
import ShowImage from '../../custom/ShowImage'
import { showDangerToast, showToast } from '../../constants/toaster'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { useDispatch, useSelector } from 'react-redux'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'
import { pull } from 'lodash'
import { s3 } from '../../constants/awsConfig'
const imageFileTypes = ['image/png', 'image/gif', 'image/jpeg']
const otherFileTypes = ['.arscene', '.videofx']

export const EditMeisheAssets = props => {
  const [assetData, setAssetData] = useState({})
  const dispatch = useDispatch()
  const userData = useSelector(state => state?.userData)
  const [bannerFile, setBannerFile] = useState(null)

  useEffect(() => {
    console.log('=====', props?.data)
    if (props?.data) {
      setAssetData(props?.data)
    }
  }, [props])

  const uploadFile = async assetFile => {
    if (!assetFile) return;

    const params = {
      Bucket: 'supa-dev-bucket', // bucket name
      Key: `uploads/${assetFile.name}`,
      Body: assetFile,
      ContentType: assetFile.type,
      // ACL: 'public-read', // Adjust according to your needs
    };
  
    try {
      const data = await s3.upload(params).promise();
      console.log('Upload Success', data.Location);
      const url = data.Location.replace(
        "https://supa-dev-bucket.s3.af-south-1.amazonaws.com/", 
        "https://dhwdwg726vpm7.cloudfront.net/"
      );
      console.log(url, 'url');
      return url
    } catch (error) {
      console.error('Error uploading file:', error);
      return ''
    }
  }

  const handleOnFileBannerSelect = async event => {
    const file = event.target.files[0]
    if (file) {
      setBannerFile(file)
    } else {
      showDangerToast('Please select a image file.')
    }
  }

  const callAPI = async postData => {
    // call API HERE
    try {
      let path = urls.addMeisheAssets
      var resBlog = await ApiCaller.post(postData, path, userData?.access_token)
      const responseBlog = await resBlog.response.json()
      if (responseBlog.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(responseBlog?.message)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(responseBlog.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleSubmit = async () => {
    let bannerImage
    if (bannerFile) {
      dispatch(uplodateLoading(true))
      bannerImage = await uploadFile(bannerFile)
    } else {
      bannerImage = assetData?.coverUrl
    }
    let postData = {
      id: assetData?.id,
      name: assetData?.displayName,
      cover: bannerImage
    }
    dispatch(uplodateLoading(true))
    try {
      let path = urls.updateMeisheAssets
      var resBlog = await ApiCaller.put(postData, path, userData?.access_token)
      const responseBlog = await resBlog.response.json()
      if (responseBlog.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(responseBlog?.message)
        props.onSubmit()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(responseBlog.message)
      }
      dispatch(uplodateLoading(false))
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          Update Meishe Asset
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-6'>
            <h4>Display Name</h4>
            <Form.Control
              value={assetData?.displayName}
              onChange={e =>
                setAssetData({
                  ...assetData,
                  displayName: e.target.value
                })
              }
              placeholder='Display Name'
              aria-label='Small'
              aria-describedby='inputGroup-sizing-sm'
            />
            {!assetData?.displayName ? (
              <label className='custom-error-label'>Name is required*</label>
            ) : (
              <></>
            )}
          </div>
          <div className='col-md-6'>
            <h4>Display Banner</h4>
            <input
              type='file'
              accept='image/png, image/gif, image/jpeg'
              onChange={handleOnFileBannerSelect}
            />
            {!bannerFile && !assetData?.coverUrl ? (
              <label className='custom-error-label'>Banner is required*</label>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Update</Button>
      </Modal.Footer>
    </Modal>
  )
}
