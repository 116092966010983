import Switch from "@mui/material/Switch";
import Swal from 'sweetalert2';
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { v4 as uuidv4 } from 'uuid';
import Dropdown from "react-bootstrap/Dropdown";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import NavBar from "../../structure/NavBar";
import Form from "react-bootstrap/Form";
import SearchIcon from "@mui/icons-material/Search";
import InputGroup from "react-bootstrap/InputGroup";
import { NavBarTop } from "../../structure/NavBarTop";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useCallback, useEffect, useState } from "react";
import NoDataImg from "../../../assets/img/no-data.gif";
import {
  updateTopHeader,
  uplodateLoading,
} from "../../../redux/features/common/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import urls from "../../constants/apiurl";
import ApiCaller from "../../constants/ApiCaller";
import { showDangerToast } from "../../constants/toaster";
import Utils from "../../constants/Utils";
import InsufficientPermission from "../../custom/InSufficientPermission";

const label = { inputProps: { "aria-label": "Size switch demo" } };
export const InfluencerList = (props) => {
  const { formatDate } = Utils();
  const [callAPi, setCallAPi] = useState('')
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [statusDropdownText, setStatusDropdownText] = useState({
    id: null,
    label: "Select Status"
  });
  const [statusDropdownList, setStatusDropdownList] = useState([
    {
      id: null,
      label: "Select Status"
    }, {
      id: "PENDING",
      label: "Pending"
    }, {
      id: "APPROVED",
      label: "Approved"
    },
    {
      id: "REJECTED",
      label: "Rejected"
    }
  ]);
  const [showAddTag, setShowAddTag] = useState(false);
  const [enableSequenceEdit, setEnableSequenceEdit] = useState(false);
  const [value, setValue] = useState(dayjs());
  const dispatch = useDispatch();
  const [datalist, setDatalist] = useState([]);
  const [sort_type, setSortType] = useState("createdAt");
  const [sort_order, setSortOrder] = useState(false);
  const userData = useSelector((state) => state?.userData);
  const [page, setPage] = useState(1);

  const [edit, setEdit] = useState(false);
  const [active, setActive] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const handleChange = (event, value) => {
    setPage(value);
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10,
    });
    setCallAPi(uuidv4())
  };
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
  });


  const handleFilterClick = useCallback(() => {
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  }, [searchText, statusDropdownText, startDate, endDate]);

  const handleResetFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setStatusDropdownText({
      id: null,
      label: "Select Status"
    });
    setSearchText("");
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  };

  const userPermisiions = useSelector(state => state?.permission)
  let currentModulePermission;
  let viewPermission = true;

  if(userPermisiions?.modules.length>0){
    currentModulePermission = userPermisiions.modules.find((element)=>{
      if(element.moduleId==='5'){
         return element
      }
    })?.permissions
     viewPermission = currentModulePermission.find((element)=>{
      if(element.name==='VIEW'){
        return element
      }
    }).isEnable
   }

  const approveOrRejectRequest = async (item, status) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.influencerRequestAcceptOrReject + '?userId=' + item?.userUuid + "&status=" + status
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setCallAPi(uuidv4())
        Swal.fire(status === 'APPROVED' ? 'Approved!' : 'Declined', '', 'success')
        getInfluencerList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleApproveOrDeclineRequest = (item, status) => {
    Swal.fire({
      title: 'Are you sure?',
      text: status === 'APPROVED' ? 'You want to Approve' : 'You want to Decline',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then(async result => {
      if (result.isConfirmed) {
        approveOrRejectRequest(item, status)
      }
    })
  };

  useEffect(() => {
    dispatch(updateTopHeader("Influencer Manager"));
  }, []);

  useEffect(() => {
    if(viewPermission){
      getInfluencerList()
    }
  }, [callAPi])

  const getInfluencerList = async () => {
    try {

      let startDateObject;
      let endDateObject;
      if (startDate?.toDate()) {
        let newDate = new Date(startDate?.toDate());
        newDate.setDate(newDate.getDate() + 1);
        startDateObject = newDate;
      }
      if (endDate?.toDate()) {
        let newDate = new Date(endDate?.toDate());
        newDate.setDate(newDate.getDate() + 1);
        endDateObject = newDate;
      }

      dispatch(uplodateLoading(true));
      let postJson = {
        searchText: searchText?.trim(),
        pageNumber: pagerecord?.pageNumber - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        statusType:
          statusDropdownText?.id,
        asc: sort_order,
        startDate:
          startDateObject !== null
            ? startDateObject
            : '',
        endDate:
          endDateObject !== null
            ? endDateObject
            : '',
      };
      let path = urls.influencerRequestList;
      let res = await ApiCaller.post(postJson, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        setDatalist(response?.data?.items);
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pagerecord?.pageNumber,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  return (
    <>
      <>
        <NavBar />
        <div className="main-content">
          <NavBarTop />
          <div className="header">
            <div className="logo">
              <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8"></div>
            </div>
          </div>
    {
      viewPermission ? (
        <>
              <div className="container-fluid mt--7">
            <div className="row">
              <div className="col text-left">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <div className="row align-items-center text-left">
                      <div className="col">
                        <h3 className="mb-0">Influencer Manager</h3>
                      </div>
                    </div>
                  </div>
                  <div className="card-header border-0">
                    <div className="row align-items-center text-left">
                      <div className="col-md-3">
                        <InputGroup
                          className="mb-2"
                          style={{ borderRadius: "10px" }}
                        >
                          <Form.Control
                            placeholder="Search by UserName"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            aria-label="Amount (to the nearest dollar)"
                          />
                        </InputGroup>
                      </div>
                      <div className="col-md-2 mb-2 " style={{ marginRight: "30px" }}>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            style={{
                              backgroundColor: "white",
                              color: "black",
                              border: "1px solid #cad1d7",
                              boxShadow: "none",
                              transform: "none",
                            }}
                            id="dropdown-basic"
                          >
                            {statusDropdownText?.label}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {statusDropdownList?.map((data, i) => {
                              return <>
                                <Dropdown.Item
                                  onClick={() =>
                                    setStatusDropdownText(data)
                                  }
                                >
                                  {data?.label}
                                </Dropdown.Item>
                              </>
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div
                        className="col-md-6 mb-3"
                        style={{ marginLeft: "10px" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["DatePicker", "DatePicker"]}
                          >
                            <DatePicker
                              disableFuture
                              className="custom-date"
                              label="From Date"
                              value={startDate}
                              onChange={(newValue) => setStartDate(newValue)}
                              maxDate={endDate}
                            />
                            <DatePicker
                              disableFuture
                              label="To Date"
                              value={endDate}
                              onChange={(newValue) => setEndDate(newValue)}
                              minDate={startDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className="col-md-4 mb-2">
                        <Button
                          variant="contained"
                          className="custom-filter-btn"
                          onClick={handleFilterClick}
                        >
                          Filter
                        </Button>
                        <Button
                          variant="contained"
                          className="ml-2 custom-filter-btn"
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {datalist && datalist?.length > 0 ? (
                      <>
                        <table className="table align-items-center table-flush">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">S/No.</th>
                              <th scope="col">Profile</th>
                              <th scope="col">User ID</th>
                              <th scope="col">UserName</th>
                              <th scope="col">Email</th>
                              <th scope="col">Mobile</th>
                              <th scope="col">Followers</th>
                              <th scope="col">No. Of Years Spend(Month)</th>
                              <th scope="col">Status</th>
                              <th scope="col">Date of Creation</th>
                              <th scope="col" />
                            </tr>
                          </thead>
                          <tbody>
                            {datalist &&
                              datalist?.map((data, i) => {
                                const startIndex = (pagerecord.pageNumber - 1) * pagerecord.pageSize;
                                return (
                                  <>
                                    <tr key={i}>
                                      <td>{startIndex + i + 1}</td>
                                      <td><img src={data?.profileIconLink} style={{ width: '50px', borderRadius: '30px' }} /></td>
                                      <td>{data?.userId ? data?.userId.toString().padStart(8, '0') : ''}</td>
                                      <td>{data?.userName}</td>
                                      <td>{data?.email}</td>
                                      <td>{data?.mobile}</td>
                                      <td>{data?.totalFollowers}</td>
                                      <td>{data?.monthsCount}</td>
                                      <td>{data?.status}</td>
                                      <td>
                                        {formatDate(data?.createdDate)}
                                      </td>
                                      {data?.status === 'PENDING' ? <>
                                        <td className="text-right">
                                          <div className="dropdown">
                                            <a
                                              className="btn btn-sm btn-icon-only text-light"
                                              href="#"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <i className="fas fa-ellipsis-v" />
                                            </a>
                                            <div
                                              className="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                                              x-placement="bottom-end"
                                              style={{
                                                position: "absolute",
                                                willChange: "transform",
                                                top: "0px",
                                                left: "0px",
                                                transform:
                                                  "translate3d(32px, 32px, 0px)",
                                              }}
                                            >
                                              {userData.admin ? (
                                                <a
                                                  className="dropdown-item cursor"
                                                  onClick={() =>
                                                    handleApproveOrDeclineRequest(data, 'APPROVED')
                                                  }
                                                >
                                                  Approve
                                                </a>
                                              ) : edit ? (
                                                <a
                                                  className="dropdown-item cursor"
                                                  onClick={() =>
                                                    handleApproveOrDeclineRequest(data, 'APPROVED')
                                                  }
                                                >
                                                  Approve
                                                </a>
                                              ) : (
                                                <></>
                                              )}
                                              {userData.admin ? (
                                                <a
                                                  className='dropdown-item cursor'
                                                  onClick={() =>
                                                    handleApproveOrDeclineRequest(data, 'REJECTED')
                                                  }
                                                >
                                                  Decline
                                                </a>
                                              ) : deletePermission ? (
                                                <a
                                                  className='dropdown-item cursor'
                                                  onClick={() =>
                                                    handleApproveOrDeclineRequest(data, 'REJECTED')
                                                  }
                                                >
                                                  Decline
                                                </a>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                      </> : <></>}

                                    </tr>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <div className="text-center">
                          <img width={"25%"} src={NoDataImg}></img>
                          <br />
                          <label style={{ fontWeight: 'bold' }}>
                            {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  {datalist && datalist.length > 0 ? (
                    <>
                      <div className="card-footer py-4">
                        <Box display="flex" justifyContent="right" alignItems='center'>
                          <span>Total record: {pagerecord?.totalItems}</span>
                          <Pagination
                            className="custom-pagination-class"
                            count={pagerecord?.totalPages}
                            page={page}
                            onChange={handleChange}
                            color="secondary"
                          />
                        </Box>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (<InsufficientPermission/>)
    }
        </div>
      </>
    </>
  );
};
