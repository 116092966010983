import { useDispatch, useSelector } from 'react-redux'
import * as React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { v4 as uuidv4 } from 'uuid';
import NavBar from '../../structure/NavBar'
import { NavBarTop } from '../../structure/NavBarTop'
import AddIcon from '@mui/icons-material/Add'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { useEffect, useState } from 'react'
import { NormalUserFilters } from './NormalUserFilters'
import { PaidUserFilters } from './PaidUserFilters'
import { NormalUserTable } from './NormalUserTable'
import { PaidUserTable } from './PaidUserTable'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast } from '../../constants/toaster'
import InsufficientPermission from '../../custom/InSufficientPermission'

function a11yProps (index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

export const UserList = props => {
  const [callAPiCallBack,setCallAPiCallBack] = useState('')
  const [tabValue, setTabValue] = React.useState(0)
  const [paidSearchText, setPaidSearchText] = useState('')
  const [paidFilterClick, setPaidFilterClick] = useState()
  const [filterData, setFilterData] = useState({
    searchText: '',
    accountStatus: '',
    startDate: null,
    endDate: null,
    subscription: '',
    kycStatus: ''
  })

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateTopHeader('User Manager'))
  })

  // const handleFilterClick = () => {
  //   setPaidFilterClick(new Date())
  // }


   // Handle filter click
   const handleFilterClick = React.useCallback(() => {
    setCallAPiCallBack(uuidv4())
  }, [filterData]);


  const handleResetFilter = () => {
    setFilterData({
      searchText: '',
      accountStatus: '',
      startDate: null,
      endDate: null,
      subscription: '',
      kycStatus: ''
    })
    setCallAPiCallBack(uuidv4())
  }

  const userPermisiions = useSelector(state => state?.permission)
  let currentModulePermission;
  let viewPermission = true;

  if(userPermisiions?.modules.length>0){
    currentModulePermission = userPermisiions.modules.find((element)=>{
      if(element.moduleId==='4'){
         return element
      }
    })?.permissions
     viewPermission = currentModulePermission.find((element)=>{
      if(element.name==='VIEW'){
        return element
      }
    }).isEnable
   }

  
  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div className='header'>
          <div className='logo'>
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
          </div>
        </div>
      {
        viewPermission ? (
          <>
            <div className='container-fluid mt--7'>
          <div className='row'>
            <div className='col text-left'>
              <div className='card shadow'>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col'>
                      <h3 className='mb-0'>User List</h3>
                    </div>
                  </div>
                </div>
                {/* {tabValue === 0 ? <NormalUserFilters handleFilterClick={handleFilterClick} handleResetFilter={handleResetFilter} setFilterData={setFilterData} filterData={filterData} /> : <PaidUserFilters handleFilterClick={handleFilterClick} setPaidSearchText={handleSetPaidSearchTxt} paidSearchText={paidSearchText} handleResetFilter={handleResetFilter} setFilterData={setFilterData} filterData={filterData} setPaidFilterClick={setPaidFilterClick} />} */}
                <NormalUserFilters
                  handleFilterClick={handleFilterClick}
                  handleResetFilter={handleResetFilter}
                  setFilterData={setFilterData}
                  filterData={filterData}
                />
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    {/* <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                      <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        indicatorColor='secondary'
                        textColor='inherit'
                        variant='fullWidth'
                        aria-label='full width tabs example'
                      >
                        <Tab label='Normal User' {...a11yProps(0)} />
                        <Tab label='Booked User Name' {...a11yProps(1)} />
                      </Tabs>
                    </Box> */}
                  </div>
                </div>
                <NormalUserTable
                  filterData={filterData}
                  callAPiCallBack={callAPiCallBack}
                />
                {/* {tabValue === 0 ? <NormalUserTable filterData={filterData}  paidFilterClick={paidFilterClick} /> : <PaidUserTable paidFilterClick={paidFilterClick} paidSearchText={paidSearchText} filterData={filterData} />} */}
              </div>
            </div>
          </div>
        </div>
          </>
        ) : (<InsufficientPermission/>)
      }
      </div>
    </>
  )
}
