import { NavLink, useNavigate } from 'react-router-dom'
import { AuthData } from '../../auth/AuthWrapper'
import { useEffect, useReducer, useState } from 'react'
import Logo from '../../assets/img/circo_color_logo2.png'
import useSession from 'react-session-hook'
import $, { cssNumber } from 'jquery'
import urls from '../constants/apiurl'
import ApiCaller from '../constants/ApiCaller'
import { showDangerToast, showToast } from '../constants/toaster'
import { useDispatch, useSelector } from 'react-redux'
import commonSlice, {
  updatePermission,
  updateUserData,
  uplodateLoading
} from '../../redux/features/common/commonSlice'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Swal from 'sweetalert2'
export const LogoutComponent = () => {
  const { logout } = AuthData()
  const navigate = useNavigate()
  useEffect(async () => {
    await logout()
    navigate('/login')
    Swal.fire('Logout!', 'You have been logged out.', 'success')
  }, [])
  return <></>
}
