import Switch from '@mui/material/Switch'
import Dropdown from 'react-bootstrap/Dropdown'
import NoDataImg from '../../../assets/img/no-data.gif'
import Button from '@mui/material/Button'
import dayjs, { Dayjs } from 'dayjs'
import { v4 as uuidv4 } from 'uuid';
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useCallback, useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { ViewBeadsDetails } from './ViewBeadsDetails'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast } from '../../constants/toaster'
import Utils from '../../constants/Utils'
import Pagination from '@mui/material/Pagination'
import { Box } from '@mui/material'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const BeadsList = props => {
  const { formatDate } = Utils();
  const userData = useSelector(state => state?.userData)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [page, setPage] = useState(1)
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [edit, setEdit] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const [datalist, setDatalist] = useState([])
  const [callAPi, setCallAPi] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const [showViewBeadsDetails, setShowViewBeadsDetails] = useState(false)
  const [viewDetailsData, setViewDetailsData] = useState({})
  const [statusDropdownText, setStatusDropdownText] = useState('Profile')
  const [value, setValue] = useState(dayjs())
  const handleFilterClick = useCallback(() => {
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  }, [searchText, startDate, endDate]);

  const handleResetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setStatusDropdownText('Select Status')
    setSearchText('')
    setPage(1);
    setPagerecord({
      ...pagerecord,
      pageNumber: 1
    })
    setCallAPi(uuidv4())
  }
  const dispatch = useDispatch()
  const [showAddFontStyle, setShowAddFontStyle] = useState(false)
  const [fontStyleFormData, setFontStyleFormData] = useReducer(
    (fontStyleFormData, newItem) => {
      return { ...fontStyleFormData, ...newItem }
    },
    {
      id: '',
      assetName: '',
      type: '',
      description: '',
      coverImageUrl: '',
      remotePackageUrl: ''
    }
  )

  const userPermisiions = useSelector(state => state?.permission)
  let currentModulePermission;
  let viewPermission = true;

  if(userPermisiions?.modules.length>0){
    currentModulePermission = userPermisiions.modules.find((element)=>{
      if(element.moduleId==='8'){
         return element
      }
    })?.permissions
     viewPermission = currentModulePermission.find((element)=>{
      if(element.name==='VIEW'){
        return element
      }
    }).isEnable
   }


  const handleOnSubmitFilterModel = () => { }
  const handleAddFilter = () => {
    setFontStyleFormData({})
    setShowAddFontStyle(true)
  }
  const handleEditFilter = item => {
    alert()
  }

  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
    setCallAPi(uuidv4())
  }

  useEffect(() => {
    dispatch(updateTopHeader('Chips Purchase Manager'))
  })

  useEffect(() => {
    if(viewPermission){
      getBeadsList();
    }
  }, [callAPi]);

  const getBeadsList = async () => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchTxt: searchText?.trim(),
        page: pagerecord?.pageNumber - 1 || 0,
        limit: pagerecord.pageSize || 10,
        transactionType: 'BOUGHT',
        sort: sort_type,
        active:
          statusDropdownText === 'Active'
            ? true
            : statusDropdownText === 'In Active'
              ? false
              : null,
        order: sort_order,
        startDate:
          startDate && startDate !== null ? startDate.toDate() : '',
        endDate: endDate && endDate !== null ? endDate.toDate() : ''
      }
      let path = urls.purchaseBeadsList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.items)
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pagerecord?.pageNumber,
          pageSize: 10
        })
        // setDatalist(response?.data?.response?.items)
        // response?.data?.permissionList.forEach((module, moduleIndex) => {
        //   if (module.permissionName === 'EDIT') {
        //     setEdit(module.isEnable)
        //   } else if (module.permissionName === 'ACTIVE') {
        //     setActive(module.isEnable)
        //   } else if (module.permissionName === 'DELETE') {
        //     setDeletePermission(module.isEnable)
        //   } else if (module.permissionName === 'VIEW') {
        //     setView(module.isEnable)
        //   }
        // })
        // setPagerecord({
        //   totalItems: response?.data?.response?.totalItems,
        //   totalPages: response?.data?.response?.totalPages,
        //   pageNumber: pageNo,
        //   pageSize: 10
        // })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <>
        <NavBar />
        <div className='main-content'>
          <NavBarTop />
          <div className='header'>
            <div className='logo'>
              <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
            </div>
          </div>
          <div className='container-fluid mt--7'>
            <div className='row'>
              <div className='col text-left'>
                <div className='card shadow'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col'>
                        <h3 className='mb-0'>Chips Purchase Manager</h3>
                      </div>
                    </div>
                  </div>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col-md-3'>
                        <InputGroup
                          className='mb-2'
                          style={{ borderRadius: '10px' }}
                        >
                          <Form.Control
                            placeholder='Search by User ID or User Name'
                            aria-label='Amount (to the nearest dollar)'
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div
                        className='col-md-6 mb-3'
                        style={{ marginLeft: '20px' }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker', 'DatePicker']}
                          >
                            <DatePicker
                              className='custom-date'
                              label='From Date'
                              value={startDate}
                              onChange={newValue => setStartDate(newValue)}
                              maxDate={endDate}
                            />
                            <DatePicker
                              label='To Date'
                              value={endDate}
                              onChange={newValue => setEndDate(newValue)}
                              minDate={startDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='col-md-3 mb-2'>
                        <Button
                          variant='contained'
                          className='custom-filter-btn'
                          onClick={handleFilterClick}
                        >
                          Filter
                        </Button>
                        <Button
                          variant='contained'
                          className='ml-2 custom-filter-btn'
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {datalist && datalist?.length > 0 ? (
                      <>
                        <table className='table align-items-center table-flush'>
                          <thead className='thead-light'>
                            <tr>
                              <th scope='col'>S/No.</th>
                              <th scope='col'>User ID</th>
                              <th scope='col'>User Name</th>
                              <th scope='col'>Chips Count</th>
                              <th scope='col'>Taken Time</th>
                              <th scope='col'>Price</th>
                              <th scope='col' >Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {datalist &&
                              datalist?.map((data, i) => {
                                const startIndex = (pagerecord.pageNumber - 1) * pagerecord.pageSize;
                                return (
                                  <>
                                    <tr key={i}>
                                      <td>{startIndex + i + 1}</td>
                                      <td>{data?.userId}</td>
                                      <td>{data?.userName}</td>
                                      <td>{data?.beads}</td>
                                      <td>
                                        {formatDate(data?.createdAt)}
                                      </td>
                                      <td>{data?.amount} INR</td>
                                      <td className='text-right'>
                                        <div className='dropdown'>
                                          <a
                                            className='btn btn-sm btn-icon-only text-light'
                                            role='button'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                          >
                                            <i className='fas fa-ellipsis-v' />
                                          </a>
                                          <div
                                            className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                            x-placement='bottom-end'
                                            style={{
                                              position: 'absolute',
                                              willChange: 'transform',
                                              top: '0px',
                                              left: '0px',
                                              transform:
                                                'translate3d(32px, 32px, 0px)'
                                            }}
                                          >
                                            <a
                                              className='dropdown-item cursor'
                                              onClick={() => {
                                                setShowViewBeadsDetails(true)
                                                setViewDetailsData(data)
                                              }}
                                            >
                                              View Details
                                            </a>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <div className='text-center'>
                          <img width={'25%'} src={NoDataImg}></img>
                          <br />
                          <label style={{ fontWeight: 'bold' }}>
                            {LOADING_ENABLE ? (
                              <>Loading Data.....</>
                            ) : (
                              <>No Data Found</>
                            )}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  {datalist && datalist.length > 0 ? (
                    <>
                      <div className='card-footer py-4'>
                        <Box
                          display='flex'
                          justifyContent='right'
                          alignItems='center'
                        >
                          <span>Total record: {pagerecord?.totalItems}</span>
                          <Pagination
                            className='custom-pagination-class'
                            count={pagerecord?.totalPages}
                            page={page}
                            onChange={handleChange}
                            color='secondary'
                          />
                        </Box>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ViewBeadsDetails
          show={showViewBeadsDetails}
          data={viewDetailsData}
          onHide={() => setShowViewBeadsDetails(false)}
        />
      </>
    </>
  )
}
