import Switch from '@mui/material/Switch'
import NoDataImg from '../../../assets/img/no-data.gif'
import { EditBeads } from './EditBeads'
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box'
import { Fragment, useEffect, useState } from 'react'
import { showDangerToast, showToast } from '../../constants/toaster'
import { ViewUser } from './ViewUser'
import { useDispatch, useSelector } from 'react-redux'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import Pagination from '@mui/material/Pagination'
import Utils from '../../constants/Utils'
import { Link } from 'react-router-dom'

const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const NormalUserTable = props => {
  const { formatDate } = Utils();
  const [callAPi, setCallAPi] = useState('')
  const [showEditBead, setShowEditBead] = useState(false)
  const [userId, setUserId] = useState()
  const [userBalance, setUserBalance] = useState(0)
  const [showViewUserDetails, setShowViewUserDetails] = useState(false)
  const LOADING_ENABLE = useSelector(state => state?.loading)

  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const userData = useSelector(state => state?.userData)
  const [datalist, setDatalist] = useState([])
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })

  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)

  const userPermisiions = useSelector(state => state?.permission)
  let currentModulePermission;
  let viewPermission = true;
  let editPermission = true;
  let deletePermissionVal = true;
  let activePermission = true;

  if(userPermisiions?.modules.length>0){
    currentModulePermission = userPermisiions.modules.find((element)=>{
      if(element.moduleId==='4'){
         return element
      }
    })?.permissions
     viewPermission = currentModulePermission.find((element)=>{
      if(element.name==='VIEW'){
        return element
      }
    }).isEnable
     editPermission = currentModulePermission.find((element)=>{
      if(element.name==='EDIT'){
        return element
      }
    }).isEnable
    deletePermissionVal = currentModulePermission.find((element)=>{
      if(element.name==='DELETE'){
        return element
      }
    }).isEnable
    activePermission = currentModulePermission.find((element)=>{
      if(element.name==='ACTIVE'){
        return element
      }
    }).isEnable
   }
  //  console.log(currentModulePermission,'userPermisiions,rolelist,user',editPermission)

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.userActiveInActive + "/" + id;
      let res = await ApiCaller.put({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getUserList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  };
  const handleCelebrityChange = async val => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.userCelebrityStatusChange + '/' + val?.id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      console.log('raj')
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getUserList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
    setCallAPi(uuidv4())
  }

  const submitEditBeads = () => {
    setShowEditBead(false)
    showToast('Beads Update Successfully.')
    getUserList()
  }

  useEffect(() => {
    setPage(1)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: 1,
      pageSize: 10
    })
    setCallAPi(uuidv4())
  }, [props?.callAPiCallBack])

  useEffect(() => {
    getUserList()
  }, [callAPi])


  const getUserList = async () => {
    try {
      dispatch(uplodateLoading(true))
      let startDateObject;
      let endDateObject;
      if (props?.filterData?.startDate?.toDate()) {
        // Create a new Date object based on startDate
        let newDate = new Date(props?.filterData?.startDate?.toDate());

        // Add one day
        newDate.setDate(newDate.getDate() + 1);
        startDateObject = newDate;
      }
      if (props?.filterData?.endDate?.toDate()) {
        // Create a new Date object based on startDate
        let newDate = new Date(props?.filterData?.endDate?.toDate());

        // Add one day
        newDate.setDate(newDate.getDate() + 1);
        endDateObject = newDate;
      }


      let postJson = {
        searchText: props?.filterData?.searchText?.trim(),
        active:
          props?.filterData?.accountStatus === 'Active'
            ? true
            : props?.filterData?.accountStatus === 'In Active'
              ? false
              : '',
        subscription: props?.filterData?.subscription,
        kycStatus: props?.filterData?.kycStatus,
        pageNumber: pagerecord?.pageNumber - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        paid: false,
        startDate:
          startDateObject !== null
            ? startDateObject
            : '',
        endDate:
          endDateObject !== null
            ? endDateObject
            : '',
      }
      let path = urls.userList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.response?.items)
        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === 'EDIT') {
            setEdit(module.isEnable)
          } else if (module.permissionName === 'ACTIVE') {
            setActive(module.isEnable)
          } else if (module.permissionName === 'DELETE') {
            setDeletePermission(module.isEnable)
          } else if (module.permissionName === 'VIEW') {
            setView(module.isEnable)
          }
        })
        setPagerecord({
          totalItems: response?.data?.response?.totalItems,
          totalPages: response?.data?.response?.totalPages,
          pageNumber: pagerecord?.pageNumber,
          pageSize: 10
        })


      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <div className='table-responsive'>
        {datalist?.length ? (
          <>
            <table className='table align-items-center table-flush'>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>User ID</th>
                  <th scope='col'>User Name</th>
                  <th scope='col'>Mobile No.</th>
                  <th scope='col'>Registered Date</th>
                  <th scope='col'>Total Chips</th>
                  <th scope='col'>Total Followers</th>
                  <th scope='col'>Total Likes</th>
                  <th scope='col'>Total Videos</th>
                  <th scope='col'>Total Subscribers</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Badge</th>
                  <th scope='col'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {datalist?.map((data, i) => {
                  return (
                    <Fragment key={i}>
                      <tr>
                        <td>{data?.userId ? data?.userId.toString().padStart(8, '0') : ''}</td>
                        <td>{data?.userName}</td>
                        <td>{data?.contactNumber}</td>
                        <td>{formatDate(data?.createdAt)}</td>
                        <td>{data?.balance?.toFixed(2)}</td>
                        <td>{data?.followers}</td>
                        <td>{data?.likes}</td>
                        <td>{data?.videos}</td>
                        <td>{data?.subscribers}</td>
                        <td>
                          {userData.admin ? (
                            <Switch
                              {...label}
                              checked={data?.active}
                              onChange={(e) =>{
                                handleStatusChange(e, data?.id)
                              }
                              }
                              size='small'
                            />
                          ) : viewPermission ? (
                            <Switch
                              {...label}
                              disabled={!activePermission}
                              checked={data?.active}
                              onChange={(e) =>{
                                if(activePermission){
                                  handleStatusChange(e, data?.id)
                                }
                              }
                              }
                              size='small'
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          <Switch
                            {...label}
                            checked={data?.badged}
                            disabled={!editPermission}
                            onChange={e => {
                              if(editPermission){
                              handleCelebrityChange(data)
                            }
                            }}
                            size='small'
                          />
                        </td>
                        <td className='text-right'>
                          <div className='dropdown'>
                            <a
                              className='btn btn-sm btn-icon-only text-light'
                              href='#'
                              role='button'
                              data-toggle='dropdown'
                              aria-haspopup='true'
                              aria-expanded='false'
                            >
                              <i className='fas fa-ellipsis-v' />
                            </a>
                            <div
                              className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                              x-placement='bottom-end'
                              style={{
                                position: 'absolute',
                                willChange: 'transform',
                                top: '0px',
                                left: '0px',
                                transform: 'translate3d(32px, 32px, 0px)'
                              }}
                            >
                              {
                                editPermission && 
                                <a
                                className='dropdown-item cursor'
                                onClick={() => {
                                  setShowEditBead(true)
                                  setUserBalance(data?.balance)
                                  setUserId(data?.userId)
                                }}
                              >
                                Edit
                              </a>
                              }
                              <Link
                                className='dropdown-item'
                                to={`/user-manager/${data?.id}`}
                              // onClick={e => setShowViewUserDetails(true)}
                              >
                                View
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  )
                })}
              </tbody>
            </table>
          </>
        ) : (
          <>
            <div className='text-center'>
              <img width={'25%'} src={NoDataImg}></img>
              <br />
              <label style={{ fontWeight: 'bold' }}>
                {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
              </label>
            </div>
          </>
        )}
        <EditBeads
          show={showEditBead}
          userId={userId}
          balance={userBalance}
          onSubmit={() => submitEditBeads()}
          onHide={() => setShowEditBead(false)}
        />
        <ViewUser
          show={showViewUserDetails}
          userId={userId}
          onHide={() => setShowViewUserDetails(false)}
        />
      </div>
      {datalist && datalist.length > 0 ? (
        <div className='card-footer py-4'>
          <Box display='flex' justifyContent='right' alignItems='center'>
            <span>Total record: {pagerecord?.totalItems}</span>
            <Pagination
              className='custom-pagination-class'
              count={pagerecord?.totalPages}
              page={page}
              onChange={handleChange}
              color='secondary'
            />
          </Box>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
