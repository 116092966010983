// awsS3Config.js
import AWS from 'aws-sdk';

AWS.config.update({
  region: 'ap-south-1',
});

const s3 = new AWS.S3({
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'ap-south-1:04f41317-b6ef-4fb7-9527-57c6b779e2fb',
  }),
});

export { s3 };
