import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { useEffect, useState } from 'react'

const getColor = props => {
  if (props.isDragAccept) {
    return '#00e676' // Green if accepted
  }
  if (props.isDragReject) {
    return '#ff1744' // Red if rejected
  }
  if (props.isFocused) {
    return '#2196f3' // Blue if focused
  }
  return '#eeeeee' // Default border color
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 27.5px;
  border-width: 2px;
  border-radius: 10px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: black;
  font-weight: bold;
  font-size: 1.4rem;
  outline: none;
  transition: border 0.24s ease-in-out;
`

function DropSound ({
  onDrop,
  acceptType,
  isImage,
  thumbanailControl,
  setThumbanailControl,
  setThumbnailFile,
  clickToRemove,
  soundFile,
  setSoundFile,
  soundUrlFormData
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    isDragAccept,
    isFocused,
    isDragReject
  } = useDropzone({
    multiple: false,
    accept: {
      "audio/mpeg": [".mp3"]
    },
    onDrop,
    noClick: true,
    noKeyboard: true,
    onDropRejected: (rejectedFiles) => {
      setErrorMessage(`File ${rejectedFiles[0].file.name} is not an accepted format.`);
    }
  })

  let [url, setUrl] = useState("");

  const removeSelectedFile = () => {
    setSoundFile(null)
    clickToRemove()
    setErrorMessage("Please upload sound")
  }

  useEffect(() => {
    try {
      if (soundFile && soundFile instanceof File) {
        const TempUrl = URL.createObjectURL(soundFile);
        setUrl(TempUrl);
      } else {
        setUrl(soundFile);
      }
    } catch (error) {
      console.error('Error creating object URL:', error);
    }

    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [soundFile]);

  return (
    <>
      <section className='dropbox'>
        <Container
          className='dropbox'
          {...getRootProps({ isDragAccept, isFocused, isDragReject })}
        >
          {soundFile ? (
            <>
              <p>Sound File: {soundFile?.name}</p>
              <audio src={url} controls />
              <button
                type='button'
                className='btn'
                onClick={removeSelectedFile}
              >
                Click to remove
              </button>
            </>
          ) : (
            <>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select</p>
              <button type='button' className='btn' onClick={open}>
                Click to select file
              </button>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </>
          )}
        </Container>
      </section>
    </>
  )
}

export default DropSound;
