import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import AWS from 'aws-sdk'
import { useCallback, useEffect, useRef, useState } from 'react'
import { AsyncPaginate } from "react-select-async-paginate";
import urls from '../../constants/apiurl';
import ApiCaller from '../../constants/ApiCaller';
import { showDangerToast, showToast } from '../../constants/toaster';
import { useDispatch, useSelector } from 'react-redux'
import { uplodateLoading } from '../../../redux/features/common/commonSlice'
import { s3 } from '../../constants/awsConfig'
export const AddPromoVideo = props => {
  const [videoFile, setVideoFile] = useState(null); // State to store the selected video file
  const dispatch = useDispatch()
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [categoryDropdown, setCategoryDropdown] = useState(null);
  const [languageDropdown, setLanguageDropdown] = useState(null);
  const userData = useSelector((state) => state?.userData);
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  });
  const optionPageNumberRef = useRef(pagerecord.pageNumber);
  const languagePageNumberRef = useRef(pagerecord.pageNumber);
  useEffect(() => {
    if (props?.promoVideoFormData?.categoryId) {
      setCategoryDropdown({
        label: props?.promoVideoFormData?.categoryName,
        value: props?.promoVideoFormData?.categoryId
      })
    }
    if (props?.promoVideoFormData?.languageId) {
      setLanguageDropdown({
        label: props?.promoVideoFormData?.languageName,
        value: props?.promoVideoFormData?.languageId
      })
    }
  }, [props?.promoVideoFormData])

  // Handle file change event
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('video/')) {
      setVideoFile(file); // Set the selected video file
    } else {
      alert('Please select a valid video file.');
    }
  };

  const uploadFile = async assetFile => {
    // S3 Bucket Name
    const S3_BUCKET = 'supa-dev-bucket'

    // S3 Region
    const REGION = 'ap-south-1'

    // S3 Credentials
    AWS.config.update({
      accessKeyId: 'AKIAQLSIVLYXUCEHSXOK',
      secretAccessKey: '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'
    })
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION
    })
    // Files Parameters
    const fileName = 'video-promo/' + assetFile?.name
    console.log(fileName)
    const params = {
      Bucket: S3_BUCKET,
      Key: fileName,
      Body: assetFile
    }

    // Uploading file to s3

    var upload = await s3
      .putObject(params)
      .on('httpUploadProgress', evt => {
        console.log(
          'Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%'
        )
        setUploadPercentage(parseInt((evt.loaded * 100) / evt.total))
      })
      .promise()
    return 'https://dhwdwg726vpm7.cloudfront.net/' + fileName
  }

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined);
      }, ms);
    });

  const wrappedLoadOptions = useCallback(function (...args) {
    return loadOptionsTest(...args);
  }, []);

  const loadOptionsTest = async (
    search,
    prevOptions
  ) => {
    console.log("1")
    await sleep(1000);
    try {
      let postJson = {
        searchText: search?.trim(),
        pageNumber: search?.trim() ? 0 : optionPageNumberRef.current - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: "createdAt",
        asc: false
      };

      let path = urls.tagInterestList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        console.log(response?.data?.response?.items)
        let tempArray = []
        response?.data?.response?.items.forEach(element => {
          tempArray.push({
            value: element?.id,
            label: element?.name
          })
        });
        setPagerecord({
          ...pagerecord,
          pageNumber: pagerecord?.pageNumber + 1
        })
        optionPageNumberRef.current = optionPageNumberRef.current + 1;
        const hasMore = response?.data?.response?.totalPages > 0 && response?.data?.response?.totalPages > pagerecord?.pageNumber;
        return {
          options: tempArray,
          hasMore
        };

      } else {
        showDangerToast(response.message)
      }
    } catch (error) {
      console.error(error)
      showDangerToast(error.message)
    }

  };


  const langualgeSleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined);
      }, ms);
    });

  const languageWrappedLoadOptions = useCallback(function (...args) {
    return loadLanguageOptions(...args);
  }, []);

  const loadLanguageOptions = async (
    search,
    prevOptions
  ) => {
    console.log("1")
    await langualgeSleep(1000);
    try {
      let postJson = {
        searchText: search?.trim(),
        pageNumber: search?.trim() ? 0 : languagePageNumberRef.current - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: "createdAt",
        asc: false
      };

      let path = urls.languageList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        console.log(response?.data?.response?.items)
        let tempArray = []
        response?.data?.response?.items.forEach(element => {
          tempArray.push({
            value: element?.id,
            label: element?.name
          })
        });
        setPagerecord({
          ...pagerecord,
          pageNumber: pagerecord?.pageNumber + 1
        });
        languagePageNumberRef.current = languagePageNumberRef.current + 1;
        const hasMore = response?.data?.response?.totalPages > 0 && response?.data?.response?.totalPages > pagerecord?.pageNumber;
        return {
          options: tempArray,
          hasMore
        };

      } else {
        showDangerToast(response.message)
      }
    } catch (error) {
      console.error(error)
      showDangerToast(error.message)
    }

  };




  const handleSubmit = async () => {
    if (!props?.promoVideoFormData?.id) {
      if (!videoFile) {
        showDangerToast('Please select a video file to upload.');
        return;
      }
    }
    let videoDuration;
    if (videoFile) {
      const videoElement = document.createElement('video');

      // Create an object URL for the uploaded video file
      const videoURL = URL.createObjectURL(videoFile);
      videoElement.src = videoURL;

      // Listen for the loadedmetadata event, which indicates the video's metadata (like duration) is available
      videoElement.addEventListener('loadedmetadata', async () => {
        videoDuration = videoElement.duration;
        if (videoDuration && videoDuration > 0 && videoDuration <= 45) {
          dispatch(uplodateLoading(true))
          console.log(videoDuration)
          const videoUrl = await uploadFile(videoFile)
          let postData = {
            categoryId: categoryDropdown?.value,
            languageId: languageDropdown?.value,
            link: videoUrl,
            fileName: videoFile?.name,
            id: props?.promoVideoFormData?.id
          }
          dispatch(uplodateLoading(true))
          try {
            let path = urls.addPromoVideo
            var resBlog = await ApiCaller.post(postData, path, userData?.access_token)
            const responseBlog = await resBlog.response.json()
            if (responseBlog.status == 200) {
              dispatch(uplodateLoading(false))
              showToast(responseBlog?.message)
              props?.onSubmit()
            } else {
              dispatch(uplodateLoading(false))
              showDangerToast(responseBlog.message)
            }
          } catch (error) {
            dispatch(uplodateLoading(false))
            console.error(error)
            showDangerToast(error.message)
          }
        } else {
          showDangerToast("Video Duration should be 1s - 45s only.")
        }
      });
    }

  }

  return (
    <>
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title id='contained-modal-title-vcenter'>
            {props?.promoVideoFormData?.id ? 'Update Promo' : 'Add Promo'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-md-6'>
              <AsyncPaginate
                debounceTimeout={300}
                value={categoryDropdown}
                placeholder="Select Category" // Custom placeholder text
                // loadOptions={loadOptions}
                loadOptions={wrappedLoadOptions}
                onChange={(e) => {
                  setCategoryDropdown(e)
                  console.log(e)
                  // setFilterData((pre) => ({ ...pre, searchText: e?.label }))
                }}
              />
            </div>
            <div className='col-md-6'>
              <AsyncPaginate
                debounceTimeout={300}
                value={languageDropdown}
                placeholder="Select Language" // Custom placeholder text
                // loadOptions={loadOptions}
                loadOptions={languageWrappedLoadOptions}
                onChange={(e) => {
                  setLanguageDropdown(e)
                  console.log(e)
                  // setFilterData((pre) => ({ ...pre, searchText: e?.label }))
                }}
              />
            </div>
          </div>

          <div className='row mt-2'>
            <div className='col-md-12'>
              <input type="file" accept="video/*" onChange={handleFileChange} /> {/* Input field for video files */}
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              {uploadPercentage > 0 && (
                <div style={{ marginTop: "20px" }}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#ddd",
                      height: "30px",
                      borderRadius: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        width: `${uploadPercentage}%`,
                        backgroundColor: "#4caf50",
                        height: "100%",
                        color: "white",
                        textAlign: "center",
                        lineHeight: "30px",
                      }}
                    >
                      {uploadPercentage}%
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outlined' onClick={props.onHide}>
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
