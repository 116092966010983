import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ReplyIcon from '@mui/icons-material/Reply';
import SaveIcon from '@mui/icons-material/Save';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const RolePermissions = ({
  submitPermissionControl,
  setViewPermissionControl,
  setPermissionSubmitData,
  permissionSubmitData,
  rolePermissionData
}) => {

  const [permissionLabel, setPermissionLabel] = useState([])

  useEffect(() => {
    let tempArray = []
    rolePermissionData?.modules?.[0]?.permissions?.map((pLable) => {
      tempArray.push(pLable?.name)
    })
    setPermissionLabel(tempArray)
  }, [])

  const handlePermissionClick = (e, item) => {
    const isEnabled = e.target.checked;
    const roleModulePermissionId = item?.permissionId;
    const index = permissionSubmitData.findIndex(
      obj => obj.roleModulePermissionId === roleModulePermissionId
    );
    if (index !== -1) {
      permissionSubmitData[index].isEnabled = isEnabled;
    } else {
      let data = {
        isEnabled,
        roleModulePermissionId
      };
      setPermissionSubmitData([...permissionSubmitData, data]);
    }
  };

  return (
    <>
      <div className='container-fluid mt--7'>
        <div className='row'>
          <div className='col text-left'>
            <div className='card shadow'>
              <div className='card-header border-0'>
                <div className='row align-items-center text-left'>
                  <div
                    className='col text-left'
                    style={{ width: 'max-content', maxWidth: 'max-content' }}// Apply dynamic width here
                  >
                    <a
                      onClick={() => setViewPermissionControl(false)}
                      className='btn btn-sm btn-primary text-white'
                    >
                      <ReplyIcon />
                      Cancel
                    </a>
                  </div>
                  <div className='col'>
                    <h3 className='mb-0 text-center'>Permission Control</h3>
                  </div>
                  <div
                    className='col text-right'
                    style={{ width: 'max-content', maxWidth: 'max-content' }}
                  >
                    <a
                      onClick={submitPermissionControl}
                      className='btn btn-sm btn-primary text-white'
                    >
                      <SaveIcon />
                      Submit
                    </a>
                  </div>
                </div>
              </div>
              <div className='table-responsive'>
                <table className='table align-items-center table-flush'>
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Manager</th>
                      {permissionLabel?.map((dataLabel) => {
                        return <th scope='col'>{dataLabel}</th>
                      })}

                    </tr>
                  </thead>
                  <tbody>
                    {rolePermissionData?.modules?.map((data, i) => {
                      return (
                        <tr key={i}>
                          <th>{data?.name}</th>
                          {data?.permissions?.map((per, index) => (
                            <td key={index}>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="tooltip-right">{per.name}</Tooltip>}

                              >
                                <Checkbox
                                  {...label}
                                  onChange={e => handlePermissionClick(e, per)}
                                  defaultChecked={per?.isEnable}
                                />
                              </OverlayTrigger>
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
